//* React imports
import React from "react";

//* Style imports
import { Button, TextField } from "@mui/material";

//* Utils imports
import { ErrorAlert } from "utils/utilities";
import { maskTelefone } from "utils/masks";
import { DeleteOutline } from "@mui/icons-material";

function OfficeHourData({ formData, setFormData }) {
  function handleDay(day) {
    let weekday = { ...formData.weekday };
    weekday[day] = !weekday[day];
    setFormData({ ...formData, weekday });
  }

  return (
    <div>
      <div className="form-group-row mt-5">
        <TextField
          label="Celular"
          variant="outlined"
          fullWidth
          required
          error={formData.phone.error}
          helperText={formData.phone.msgError}
          value={formData.phone.value}
          onChange={(e) => {
            setFormData({
              ...formData,
              phone: { value: maskTelefone(e.target.value), error: false },
            });
          }}
        />
      </div>
      <div className="form-group-row mt-5">
        <label className="col-form-label">Dias de funcionamento</label>
        <div className="col-sm-12">
          <div
            className="d-flex justify-content-evenly align-items-center"
            id="dayselector"
          >
            {Object.keys(formData.weekday).map((item, id) => (
              <>
                {formData.weekday[item] == false && (
                  <div key={id} onClick={() => handleDay(item)}>
                    {item.substring(0, 1).toUpperCase()}
                  </div>
                )}
                {formData.weekday[item] == true && (
                  <div
                    className="selectedDay"
                    key={id}
                    onClick={() => handleDay(item)}
                  >
                    {item.substring(0, 1).toUpperCase()}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="form-group-row col-5 mt-5">
            <TextField
              label="Hora Início"
              type={"time"}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              required
              error={false}
              helperText={""}
              value={formData.startTime}
              onChange={(e) =>
                setFormData({ ...formData, startTime: e.target.value })
              }
            />
          </div>
          <div className="form-group-row col-5 mt-5">
            <TextField
              label="Hora Fim"
              type={"time"}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              required
              error={false}
              helperText={""}
              value={formData.endTime}
              onChange={(e) =>
                setFormData({ ...formData, endTime: e.target.value })
              }
            />
          </div>
        </div>
        <div className="d-grid gap-2 d-flex justify-content-end mt-3">
          <Button
            variant="contained"
            disableElevation
            size="large"
            color="info"
            onClick={() => {
              if (formData.startTime == "" || formData.endTime == "") {
                ErrorAlert("Preencha os campos corretamente");
                return;
              }
              if (formData.startTime >= formData.endTime) {
                ErrorAlert("Horários Conflitantes");
                return;
              }
              if (
                formData.weekday.dom == false &&
                formData.weekday.seg == false &&
                formData.weekday.ter == false &&
                formData.weekday.qua == false &&
                formData.weekday.qui == false &&
                formData.weekday.sex == false &&
                formData.weekday.sab == false
              ) {
                ErrorAlert("Escolha pelo menos um dia da semana");
              } else {
                let openingHours = [...formData.openingHours];
                let obj = {
                  weekday: Object.values(formData.weekday)
                    .map((item, id) => {
                      if (item == true) {
                        return Object.keys(formData.weekday)[id];
                      }
                    })
                    .filter((item, id) => item != null && item != undefined),
                  startTime: formData.startTime,
                  endTime: formData.endTime,
                };

                let requiredMsg = "";
                for (let i = 0; i < formData.openingHours.length; i++) {
                  for (
                    let j = 0;
                    j < formData.openingHours[i].weekday.length;
                    j++
                  ) {
                    Object.values(obj.weekday).map((item2, id2) => {
                      if (formData.openingHours[i].weekday[j] == item2) {
                        if (
                          (formData.openingHours[i].endTime >= obj.startTime &&
                            obj.startTime >=
                              formData.openingHours[i].startTime) ||
                          (formData.openingHours[i].endTime >= obj.endTime &&
                            obj.endTime >=
                              formData.openingHours[i].startTime) ||
                          (formData.openingHours[i].endTime <= obj.endTime &&
                            obj.startTime <= formData.openingHours[i].startTime)
                        ) {
                          requiredMsg = "Horários conflitantes";
                        }
                      }
                    });
                  }
                }
                if (requiredMsg === "") {
                  openingHours.push(obj);
                  setFormData({
                    ...formData,
                    openingHours,
                    startTime: "",
                    endTime: "",
                  });
                } else {
                  ErrorAlert("Horários conflitantes");
                }
              }
            }}
          >
            <span>Inserir</span>
          </Button>
        </div>
        <div>
          {formData.openingHours.length > 0 && (
            <div className="mt-3">
              <ul className="list-group text-capitalize">
                <li className="list-group-item bg-primary text-light">
                  <b>Dias & Horários de Funcionamento</b>
                </li>
                {formData.openingHours.map((item, id) => (
                  <li
                    key={id}
                    className="list-group-item d-flex justify-content-between"
                  >
                    <p className="pt-3">
                      <b>
                        {item.weekday.map(
                          (item2, id2) => (id2 == 0 ? "" : " - ") + item2
                        )}
                      </b>
                      : {item.startTime} - {item.endTime}
                    </p>
                    <button
                      onClick={() => {
                        let openingHours = [...formData.openingHours];
                        openingHours = openingHours.filter(
                          (item2, id2) => id2 != id
                        );
                        setFormData({ ...formData, openingHours });
                      }}
                      className="btn btn-danger btn-outline btn-circle me-1 bg-light"
                      style={{ border: "0px solid red" }}
                    >
                      <DeleteOutline color="error" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* FORMATO DE TABELA COMENTADO NO ARQUIVO 'OFFICE HOUR DATA - ESTOQUE' */}
        </div>
      </div>
    </div>
  );
}

export default OfficeHourData;
