//*Imports
import React from "react";

//* Welcome import
import welcome from "../../../assets/static/welcome.svg";

function Home() {
  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <img
        src={welcome}
        alt="Bem vindo(a)"
        style={{
          height: "80vh",
          objectFit: "contain",
          borderBottom: "3px solid #00C2B3" 
        }}
      />
    </div>
  );
}

export default Home;
