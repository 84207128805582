import Echo from "laravel-echo";
import Pusher from 'pusher-js'

let echo = null;

export const wsConnection = () => {
  if (echo !== null) {
    try {
      DCWebSocket();
    } catch (err) {
      console.log(err);
    }
  }
  console.log('teste')
  // window.Pusher = Pusher;
  echo = new Echo({
    broadcaster: "pusher",
    key: "2baa23c626fb460b87e1",
    cluster: "sa1",
    wsHost: "blueenopenwebsocket.enterscience.com.br",
    host: "blueenopenwebsocket.enterscience.com.br:443",

    wsPort: 443,
    transports: ["websocket"],
    enabledTransports: ["ws"],
    scheme: 'https',
    forceTLS: true,
    disableStats: false,
    encrypted: true,
  });

  return echo;
};

export const DCWebSocket = () => {
  echo.disconnect();
};

export const notifications = (echo, user, callback) => {
  echo.channel(`user.${user.id}`).listen(".Notification", function (e) {
    callback(e);
  });
};
