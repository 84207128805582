import React, { useEffect, useState } from "react";
import moment from "moment";

import { BsFillCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { URL } from "variables";
import {
  Box,
  Divider,
  IconButton,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { DeleteOutline, MoreHorizOutlined } from "@mui/icons-material";
import Swal from "sweetalert2";
import { ErrorAlert, SuccessAlert } from "utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { notificationCounter } from "redux/AppActions";

function Notification(props) {
  let history = useNavigate();
  const dispatch = useDispatch();
  const notificationsCount = useSelector(
    (state) => state.AppReducer.notifications
  );

  function openContent(id) {
    fetch(`${URL}api/auth/update_notification_status/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(async (responseLog) => {
      let resp = await responseLog.json();
      if (resp.status) {
        dispatch(notificationCounter(notificationsCount - 1));
      }
    });

    history(props.item.content.path);
  }

  function deleteNotification(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Essa ação é irreversível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, deletar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${URL}api/auth/delete_notification/${id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${props.reducer.token}`,
          },
        })
          .then(async (responseLog) => {
            let resp = await responseLog.json();
            if (!resp.visualized) {
              dispatch(notificationCounter(notificationsCount - 1));
            }

            let notificationContent = [...props.state.notificationContent];
            notificationContent = notificationContent.filter(
              (item2, id2) => item2.id != id
            );
            props.setState({ ...props.state, notificationContent });

            SuccessAlert("Deletado com sucesso!");
          })
          .catch((err) => {
            ErrorAlert("Ops! Algo deu errado.");
            console.log(err);
          });
      }
    });
  }

  return (
    <Box>
      <ListItemButton className="d-flex align-items-center row py-2 my-1">
        {/* <span
          className="m-2"
          style={{ border: "1px solid red", width: "8vh", height: "8vh" }}
        ></span> */}
        <div className="mt-2 col-11" onClick={() => openContent(props.item.id)}>
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">{props.item.content.title}</h5>
            <span className="text-muted small">
              {moment(props.item.created_at).format("DD/MM/YYYY")}
              {!props.item.visualized && (
                <BsFillCircleFill size={10} className="mx-2" color="#f8333c" />
              )}
            </span>
          </div>
          <div
            style={{
              fontSize: "1.75vh",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "5em",
            }}
          >
            <strong>{props.item.sender}</strong>: {props.item.content.message}
          </div>
        </div>
        <div className="col-1 mt-3 d-flex align-items-center justify-content-center">
          <Tooltip title="Deletar notificação" placement="right">
            <IconButton
              size="small"
              color="error"
              style={{
                marginRight: "1vh",
              }}
              onClick={() => deleteNotification(props.item.id)}
            >
              <DeleteOutline fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </ListItemButton>
    </Box>
  );
}

export default Notification;
