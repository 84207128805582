import React from 'react'
import { URL } from 'variables'
import { formatedDate, maskDate, maskMoneyV2 } from 'utils/masks'
import { get, post } from 'hooks/requests'
import { renderMessage } from 'utils/alerts'
import AddIcon from '@mui/icons-material/Add'
import { DeleteOutline } from '@mui/icons-material'
import { months, timeUnits, years } from 'utils/options'
import { useParams, useNavigate } from 'react-router-dom'
import { MdClose, MdInfo, MdPlaylistAdd } from 'react-icons/md'
import PresetButtons from 'components/common/Form/PresetButtons'
import LabeledDivider from 'components/common/Form/LabeledDivider'
import InputFileUpload from 'components/common/Form/InputFileUpload'
import { Checkbox, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import useForm from 'components/common/Form/useForm'

const AddCompliance = ({ editData, editSelectOptions }) => {
  const history = useNavigate()

  // Function to get the correct data according to the url
  const getOwner = (owner) => {
    switch (owner) {
      case 'destinations':
        return { label: 'Destino', id: 'destiny_id' }
      case 'pontos_coleta':
        return { label: 'Ponto de coleta', id: 'collect_point_id' }
      case 'transportadoras':
        return { label: 'Transportadora', id: 'carrier_id' }
      case 'veiculos':
        return { label: 'Veículo', id: 'vehicle_id' }
      case 'motoristas':
        return { label: 'Motorista', id: 'driver_id' }
      default:
        history('/pbo/dashboard/destinations/compliance')
        return { label: '', id: '' }
    }
  }

  let { owner } = useParams()
  if (!owner) {
    owner = 'destinations'
  }


  const [timeUnit, setTimeUnit] = React.useState('day')
  const [contentType, setContentType] = React.useState('')

  const [documentType, setDocumentType] = React.useState(editData?.type ?? '')
  const [contentList, setContentList] = React.useState(editData?.contents ?? [])
  const [selectOptions, setSelectOptions] = React.useState(editSelectOptions ?? [])

  const [notificationList, setNotificationList] = React.useState([])

  const [loading, setLoading] = React.useState(editData ? false : true)
  const [loadingSave, setLoadingSave] = React.useState(false)
  const [monthlyNotification, setMonthlyNotification] = React.useState(true)
  const [notificationError, setNotificationError] = React.useState(false)

  const { form, setForm, handleChange, handleBlur, errors, setField, resetError, setErrors, isValid } = useForm({
    name: editData?.name ?? '',
    responsible: editData?.responsible ?? '',
    documentType: editData?.type ?? '',
    // ownerId: editData ? editData[getOwner(owner).id] : '',
    file: '',
    content: '',
    notification: '',
    monthlyStartDate: 1,
    expirationDate: formatedDate(new Date()),
    annualStartDate: new Date().getFullYear(),
  })

  const contentTypeOptions = ['Quantidade', 'Volume', 'Saldo Financeiro']
  const documentTypeOptions = ['Licença', 'Autorização', 'Certificado', 'Transporte', 'Contrato', 'Requisito Legal', 'Outros']

  React.useEffect(() => {
    if (!editData) {
      getData()
    }
  }, [])
  // Getting data
  const getData = async () => {
    const response = await get(`${URL}api/get_data/${owner}`)
    setLoading(false)

    if (response.httpCode !== 200) {
      history('/pbo/dashboard/destinations/compliance')
      return
    }

    setSelectOptions(response.data)
    console.log('response', response)
  }


  const handleNotificationChange = ({ target }) => {
    const { value, name } = target

    // Controlling notification in case of percentage of total
    if ((value >= 0 && value <= 100) || contentType === 'Validade') {
      // Saving string for normal inputs and file for file type input
      resetError(name)
      setField(name, value)
    } else {
      setNotificationError(true)
    }
  }



  const handleAddContent = (e) => {
    e.preventDefault()

    let { content, expirationDate, notification, monthlyStartDate, annualStartDate } = form
    let newErrors = { ...errors }
    let contentLabel = content
    let notificationLabel = ''
    let dateLabel = ''
    let unity = ''

    const dataValidation = { content, expirationDate, notification, monthlyStartDate, annualStartDate }
    Object.entries(dataValidation).forEach(([key, value]) => {
      console.log("key - value", key, value)
      if (contentType === 'Validade') {
        if (!value && key !== 'content' && key !== 'notification') newErrors[key] = 'Campo em branco'
        if (!value && key === 'notification' && notificationList.length === 0) newErrors[key] = 'Campo em branco'
        return
      }

      if (monthlyNotification) {
        if (!value && key !== 'expirationDate' && key !== 'annualStartDate') newErrors[key] = 'Campo em branco'
      } else {
        if (!value && key !== 'expirationDate' && key !== 'monthlyStartDate') newErrors[key] = 'Campo em branco'
      }
    })

    if (Object.values(newErrors).some(item => item !== null)) {
      setErrors(newErrors)
      return
    }


    if (contentType === 'Validade') {
      const [year, month, day] = expirationDate.split("-");
      dateLabel = `Válido até ${day}/${month}/${year}`
    }

    if (contentType === 'Quantidade') unity = ' KG'
    if (contentType === 'Volume') unity = ' LT'
    if (contentType === 'Validade') {
      // If the accumulator is still empty, we don't add a comma before the label, otherwise we add a comma before the label
      notificationLabel = notificationList.reduce((accumulator, item) => {
        if (accumulator === '') return item.label
        else return accumulator + ', ' + item.label
      }, '')
    } else {
      notificationLabel = `${notification}% da capacidade máxima`
    }

    contentLabel = contentType === 'Saldo Financeiro' ? maskMoneyV2(content) : content

    if (monthlyNotification && contentType !== 'Validade') {
      dateLabel = `Inicio em ${months.find(item => item.value === Number(monthlyStartDate))?.name}`
    }
    else if (!monthlyNotification && contentType !== 'Validade') {
      dateLabel = `Inicio em ${annualStartDate}`
    }

    const newContent = {
      type: contentType,
      content,
      content_label: contentLabel,
      expiration_date: expirationDate,
      date_label: dateLabel,
      notification,
      notification_label: notificationLabel,
      monthly_start_date: monthlyStartDate,
      annual_start_date: annualStartDate,
      unity,
      content_notifications: notificationList
    }

    setContentType('')
    setContentList([...contentList, newContent])

    setForm({
      ...form,
      content: '',
      notification: '',
      monthlyStartDate: 1,
      expirationDate: formatedDate(new Date()),
      annualStartDate: new Date().getFullYear(),
    })
  }

  const handleAddNotification = () => {
    if (!form.notification) {
      resetError('notification')
      return
    }

    const newNotification = {
      type: timeUnit,
      notify_on: form.notification,
      label: `${form.notification} ${timeUnits.find(unit => unit.value === timeUnit).label} antes`
    }

    setField('notification', '')
    resetError('notification')
    setNotificationList([...notificationList, newNotification])
  }

  const handleSave = async () => {
    const validate = isValid(['content', 'notification', 'annualStartDate', 'monthlyStartDate', editData && 'file'])

    if (!validate) {
      return
    }

    setLoadingSave(true)

    const formData = new FormData()
    formData.append('type', form.documentType)
    formData.append('name', form.name)
    formData.append('responsible', form.responsible)

    if (form.file) {
      formData.append('document', form.file)
    }

    if (editData) {
      formData.append('compliance_id', editData.id)
    }
    // Getting the correct module id according to the url
    // formData.append(getOwner(owner).id, form.ownerId)

    contentList.forEach(item => {
      formData.append('compliance_contents[]', JSON.stringify(item))
    })

    let response = ''

    if (editData) {
      response = await post(`${URL}api/update_compliance`, formData)
      setLoadingSave(false)
    } else {
      response = await post(`${URL}api/store_compliance`, formData)
      setLoadingSave(false)
    }

    if (response.httpCode !== 200) {
      renderMessage('error', response.error)
      return
    }
    history('/pbo/dashboard/destinations/compliance')
  }

  const renderContent = () => {
    let span = ''
    let Content = null
    let Notification = null

    // Set the span and Content based on the contentType
    switch (contentType) {
      case 'Quantidade':
        span = 'KG'
        break
      case 'Volume':
        span = 'LT'
        break
      case 'Saldo Financeiro':
        Content = (
          <TextField className='mt-3' label="Valor" name='content' variant="outlined" fullWidth required error={Boolean(errors?.content)} helperText={errors?.content} value={maskMoneyV2(form.content)} onBlur={handleBlur} onChange={({ target }) => {
            // Divide by 100 to account for cents
            setField(target.name, target.value.replace(/\D/g, '') / 100)
            resetError(target.name)
          }} />
        )
        break
      case 'Validade':
        Content = (
          <TextField className='mt-3' type="date" label="Validade" name='expirationDate' variant="outlined" fullWidth required error={Boolean(errors?.expirationDate)} helperText={errors?.expirationDate} value={form.expirationDate} onChange={handleChange} onBlur={handleBlur} />
        )
        break
    }

    // Render based on the contentType and monthlyNotification values
    if (span) {
      Content = (
        <TextField
          label={contentType}
          name="content"
          variant="outlined"
          className='mt-3'
          fullWidth
          required
          error={Boolean(errors?.content)}
          helperText={errors?.content}
          value={form.content}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            endAdornment: <InputAdornment position="end">{span == 'KG' ? 'peso' : 'volume'}</InputAdornment>,
          }}
        />
      )
    }

    // Saving notification input on component for avoid repetition
    Notification = (
      <div className="col-12">
        <TextField
          label='Notificações'
          name="notification"
          variant="outlined"
          className='mt-3'
          fullWidth
          required
          error={Boolean(errors.notification)}
          helperText={errors.notification}
          value={form.notification}
          onChange={handleNotificationChange}
          onBlur={handleBlur}
          placeholder={contentType !== 'Validade'
            ? 'Notificar à X % do total'
            : `Notificar à X ${timeUnits.find(item => item.value === timeUnit)?.label.toLowerCase()} do vencimento`}
        />
        {/* <span className="input-group-text">{contentType !== 'Validade' ? '%' : 'Dias'}</span> */}
        <div className="input-group mt-3">
          {contentType === 'Validade' &&
            <>
              <FormControl sx={{ width: 'calc(100% - 46px)' }}>
                <Select labelId="timeUnit" name="timeUnit" value={timeUnit} onChange={({ target }) => setTimeUnit(target.value)}>
                  {timeUnits.filter(item => item.value !== 'minute' && item.value !== 'hour').map(item => (
                    <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <button className="btn btn-primary" onClick={handleAddNotification}><MdPlaylistAdd size={20} /></button>
            </>
          }
        </div>

        <div className="d-flex justify-content-between my-3">

          <div className='col-sm-4 col-12 ms-2 flex-column'>
            {notificationList.map(item => (
              <>
                <div key={item.type} className="d-flex justify-content-between align-items-center">
                  <span>{item.label}</span>

                  <IconButton onClick={() =>
                    setNotificationList(notificationList.filter(notification => notification !== item))
                  }>
                    <MdClose size={20} />
                  </IconButton>
                </div>
                {notificationError && contentType !== 'Validade' &&
                  <span className='small error position-absolute'>O valor deve estar entre 0 e 100</span>
                }
              </>
            ))}
          </div>

          <div className="ms-auto">
            <Fab color="primary" aria-label="add" onClick={handleAddContent}>
              <AddIcon />
            </Fab>
          </div>
        </div>
      </div >
    )

    // Common part for contentType and contentType !== 'Validade'
    if (contentType && contentType !== 'Validade') {
      return (
        <div className='row'>
          <div className="col-12 mb-3">
            <LabeledDivider label='Valor Monitorado' />
          </div>
          <div className="col-lg-6">
            {Content}
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 d-flex justify-content-lg-end align-items-center ">
                <FormGroup>
                  <FormControlLabel required control={
                    <Checkbox checked={monthlyNotification} defaultChecked onChange={() => { setMonthlyNotification(true); resetError('annualStartDate') }} />
                  } label="Controle Mensal" />
                  <FormControlLabel required control={
                    <Checkbox checked={!monthlyNotification} onChange={() => { setMonthlyNotification(false); resetError('monthlyStartDate') }} />
                  } label="Controle Anual" />
                </FormGroup>
              </div>

              <div className="col-lg-6">
                {monthlyNotification &&
                  <FormControl fullWidth className='mt-3'>
                    <InputLabel id="monthlyNotification">Mês de início</InputLabel>
                    <Select labelId="monthlyNotification" label='Mês de início' name="monthlyStartDate" value={form.monthlyStartDate} onChange={handleChange}>
                      {months.map(item => (
                        <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem>
                      ))
                      }
                    </Select>
                  </FormControl>
                }
                {!monthlyNotification &&
                  <FormControl fullWidth className='mt-3'>
                    <InputLabel id="annualStartDate">Ano de ínicio</InputLabel>
                    <Select labelId="annualStartDate" label='Ano de ínicio' name="annualStartDate" value={form.annualStartDate} onChange={handleChange}>
                      {years.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                      ))
                      }
                    </Select>
                  </FormControl>
                }
              </div>
            </div>
          </div>

          {Notification}
        </div>
      )
    }

    // Common part for contentType === 'Validade'
    if (contentType === 'Validade') {
      return (
        <div className="row">
          <div className="col-12 mb-3">
            <LabeledDivider label='Valor Monitorado' />
          </div>
          <div className="col-12">
            {Content}
          </div>

          {Notification}
        </div>
      )
    }
  }

  return (
    <div className="mx-3">
      <h1 className="card-title text-body">
        <b>{editData ? 'Editar' : 'Criar'} documentação</b>
      </h1>
      <span className="text-body">Gerencie a sua documentação complience.</span>
      {!loading ?
        <form onSubmit={(e) => { e.preventDefault(); handleSave() }}>
          <div className="mt-3">
            <InputFileUpload label={form.file.name ?? 'Inserir documento'} type='pdf' name='file' color={errors.file ? 'error' : 'primary'} handleChange={handleChange} />
          </div>

          <div className="form-group-row mt-3">
            <TextField label="Nome" name='name' variant="outlined" fullWidth required error={Boolean(errors.name)} helperText={errors?.name} value={form.name} onChange={handleChange} onBlur={handleBlur} />
          </div>

          <div className="form-group-row mt-3">
            <TextField label="Orgão responsável" name='responsible' variant="outlined" fullWidth required error={Boolean(errors.responsible)} helperText={errors?.responsible} value={form.responsible} onChange={handleChange} onBlur={handleBlur} />
          </div>

          {/* <FormControl fullWidth className='mt-3' error={Boolean(errors.ownerId)}>
            <InputLabel id="owner">{getOwner(owner).label}</InputLabel>
            <Select labelId="owner" name='ownerId' label={getOwner(owner).label} value={form.ownerId} onChange={handleChange} onBlur={handleBlur}>
              {selectOptions.map(item => (
                <MenuItem key={item.id} value={item.id}>{item.key}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors?.ownerId}</FormHelperText>
          </FormControl> */}

          <FormControl fullWidth className='mt-3' error={Boolean(errors.documentType)}>
            <InputLabel id="documentType">Tipo de documento</InputLabel>
            <Select labelId="documentType" name='documentType' label='Tipo de documento' value={form.documentType} onChange={handleChange} onBlur={handleBlur}>
              <MenuItem value=''>Selecione um tipo de documento</MenuItem>

              {owner === 'motoristas' &&
                <>
                  <MenuItem value={'Habilitação'}>{'Habilitação'}</MenuItem>
                  <MenuItem value={'Requisito Legal'}>{'Requisito Legal'}</MenuItem>
                  <MenuItem value={'Certificado'}>{'Certificado'}</MenuItem>
                  <MenuItem value={'Outros'}>{'Outros'}</MenuItem>
                </>
              }

              {owner === 'veiculos' &&
                <>
                  <MenuItem value={'Autorização'}>{'Autorização'}</MenuItem>
                  <MenuItem value={'Transporte'}>{'Transporte'}</MenuItem>
                  <MenuItem value={'Requisito Legal'}>{'Requisito Legal'}</MenuItem>
                  <MenuItem value={'Outros'}>{'Outros'}</MenuItem>
                </>
              }

              {owner !== 'motoristas' && owner !== 'veiculos' && documentTypeOptions.map(item => (
                <MenuItem key={item} value={item}>{item}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors?.documentType}</FormHelperText>
          </FormControl>

          <FormControl fullWidth className='mt-3'>
            <InputLabel id="contentType">Novo valor monitorado</InputLabel>
            <Select labelId="contentType" value={contentType} label='Novo valor monitorado' onChange={({ target }) => { setContentType(target.value) }}>
              <MenuItem value=''>N/A</MenuItem>
              {!contentList.some(content => content.type === 'Validade') &&
                <MenuItem value={'Validade'}>Validade</MenuItem>}

              {owner !== 'motoristas' && owner !== 'veiculos' && contentTypeOptions.map(item => {
                if (!contentList.some(content => content.type === item)) {
                  return (
                    <MenuItem key={item} value={item}>{item}</MenuItem>
                  )
                }
              })}
            </Select>
          </FormControl>

          {renderContent()}

          <div className="table-responsive text-center mt-3">
            <table id="tech-companies-1" className="table table-hover mt-2" >
              <thead className='thead-blueen'>
                <tr>
                  <th scope='col'>Tipo</th>
                  <th scope='col'>Quantidade</th>
                  <th scope='col'>Data</th>
                  <th scope='col'>Notificar em</th>
                  <th data-priority="3">Ações</th>
                </tr>
              </thead>
              <tbody className='tbody-blueen'>
                {contentList.length === 0 && <tr>
                  <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum valor registrado</td>
                </tr>}
                {contentList.map((item, index) => (
                  <tr key={index}>
                    <td className="align-middle">{item.type}</td>
                    <td className="align-middle">{item.content ? item.content_label + item.unity : 'N/A'}</td>
                    <td className="align-middle">{item.date_label}</td>
                    <td className="align-middle">{item.notification_label} {item.type !== 'Validade' &&
                      <Tooltip
                        arrow
                        title={item.type !== 'Saldo Financeiro'
                          ? (item.content * (item.notification / 100) + item.unity)
                          : maskMoneyV2(item.content * (item.notification / 100))}
                        placement="top">
                        <IconButton>
                          <MdInfo color='#333' size={18} />
                        </IconButton>
                      </Tooltip>}
                    </td>
                    <td className="align-middle">
                      <Tooltip title="Excluir">
                        <IconButton onClick={() => setContentList(contentList.filter(content => content !== item))}>
                          <DeleteOutline />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <PresetButtons handleSubmit={handleSave} loadingSave={loadingSave} backPath={`/pbo/dashboard/${owner}/compliance`} />
        </form>
        :
        <div className='d-flex justify-content-center p-5'>
          <div className="spinner-border text-primary" role="status"></div>
        </div>}
    </div >
  )
}

export default AddCompliance