import { Tooltip } from "@mui/material";
import { useState } from "react";

function Safety() {
  const [tooltipText, setTooltipText] = useState("Clique para copiar o texto");

  const handleCopy = () => {
    const textToCopy = document.getElementById("text-to-copy").innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setTooltipText("Copiado com sucesso");

      setTimeout(() => {
        setTooltipText("Clique para copiar o texto");
      }, 2000);
    });
  };

  return (
    <div className="container mt-3">
      <header>
        <h1 className="card-title text-body">
          <b>Política de Privacidade</b>
        </h1>
        <p className="text-body">
          Esta Política de Privacidade descreve como a Blueen coleta, usa,
          armazena e compartilha informações de seus usuários. Ao utilizar o
          aplicativo Blueen, você concorda com as práticas descritas nesta
          política.
        </p>
      </header>
      <main className="my-3 mb-3">
        <h5 className="card-title text-body">
          <b>Coleta de Informações</b>
        </h5>
        <p className="text-body">
          A Blueen coleta informações que você fornece voluntariamente, como
          nome, endereço de e-mail e informações de contato. Além disso, podemos
          coletar informações técnicas, como endereços de IP, tipo de navegador,
          sistema operacional, provedor de serviços de internet (ISP) e
          informações de localização.
        </p>
        <h5 className="card-title text-body">
          <b>Uso de Informações</b>
        </h5>
        <p className="text-body">
          As informações coletadas pela Blueen são usadas para fornecer e
          melhorar o aplicativo, além de permitir a gestão de resíduos sólidos
          com logística reversa. Podemos usar as informações para personalizar
          sua experiência, enviar comunicações de marketing, analisar tendências
          e para fins estatísticos.
        </p>
        <h5 className="card-title text-body">
          <b>Armazenamento de Informações</b>
        </h5>
        <p className="text-body">
          A Blueen armazena as informações dos usuários em servidores seguros e
          protegidos. As informações do usuário serão armazenadas pelo tempo
          necessário para fornecer o serviço solicitado, ou conforme exigido por
          lei.
        </p>
        <h5 className="card-title text-body">
          <b>Compartilhamento de Informações</b>
        </h5>
        <p className="text-body">
          A Blueen não compartilha informações pessoais de seus usuários com
          terceiros, exceto quando exigido por lei. Podemos compartilhar
          informações anônimas ou agregadas com nossos parceiros comerciais ou
          para fins de pesquisa.
        </p>
        <h5 className="card-title text-body">
          <b>Segurança</b>
        </h5>
        <p className="text-body">
          A Blueen se compromete a proteger as informações pessoais de seus
          usuários e adota medidas de segurança para evitar o acesso não
          autorizado, uso indevido ou alteração de informações pessoais. No
          entanto, não podemos garantir a segurança absoluta das informações, já
          que nenhum método de transmissão pela Internet ou armazenamento
          eletrônico é 100% seguro.
        </p>
        <h5 className="card-title text-body">
          <b>Alterações nesta Política</b>
        </h5>
        <p className="text-body">
          A Blueen pode atualizar esta Política de Privacidade periodicamente.
          Se fizermos alterações significativas, notificaremos os usuários por
          e-mail ou por meio de um aviso no aplicativo.
        </p>
        <h5 className="card-title text-body">
          <b>Contato</b>
        </h5>
        <p className="text-body">
          Se você tiver alguma dúvida ou preocupação em relação a esta Política
          de Privacidade, entre em contato conosco pelo e-mail:
          <Tooltip title={tooltipText} arrow>
            <span
              id="text-to-copy"
              style={{
                color: "#3C0096",
                cursor: "pointer",
              }}
              onClick={handleCopy}
            >
              contato@blueen.app
            </span>
          </Tooltip>
        </p>
      </main>
    </div>
  );
}

export default Safety;
