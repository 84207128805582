//* React imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//* Style Imports
import { Button } from "@mui/material";

//* Utils imports
import { login } from "redux/AppActions";
import { URL } from "variables";
import { ErrorAlert, SuccessAlert } from "utils/utilities";
import home_bg from "assets/static/landing/home_bg.png";

//* Components imports
import UserData from "./Steps/UserData";
import ChangePassword from "./Steps/ChangePassword";
import logo_white from "../../../assets/static/logo/green_white.png";

function AltLogin() {
  const dispatch = useDispatch();
  let history = useNavigate();

  const selector = useSelector((state) => state.AppReducer.token);
  const [state, setState] = useState({
    loading: false,
    loadingSave: false,
    page: 0,
  });
  const [loginData, setLoginData] = useState({
    email: { value: "", error: false, msg: "" },
    password: { value: "", error: false, msg: "" },
    viewPassword: false,
  });

  const [passwordData, setPasswordData] = useState({
    newPassword: { value: "", error: false, msg: "" },
    newPasswordConfirmation: { value: "", error: false, msg: "" },

    viewPassword: false,
    viewPasswordConfirmed: false,
  });

  function renderDescription() {
    if (state.page == 0) {
      return "Seja bem vindo(a). Por favor, faça o login em sua conta.";
    }
    if (state.page == 1) {
      return "Por segurança e conforto, redefina sua senha no sistema Blueen Open.";
    }
  }

  function renderPage() {
    if (state.page === 0) {
      return <UserData formData={loginData} setFormData={setLoginData} />;
    }
    if (state.page === 1) {
      return (
        <ChangePassword formData={passwordData} setFormData={setPasswordData} />
      );
    }
  }

  function handleSubmit() {
    if (state.page == 0) {
      setState({ ...state, loadingSave: true });
      fetch(`${URL}api/auth/login_invited`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: loginData.email.value,
          password: loginData.password.value,
        }),
      })
        .then(async (responseLog) => {
          try {
            let resp = await responseLog.json();
            console.log(resp);
            if (resp.errors != undefined && resp.errors.length != 0) {
              let errors = Object.values(resp.errors);
              let erro = "";
              for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1) erro += errors[i] + "\n";
                else erro += errors[i];
              }
              console.log(erro);

              setState({ ...state, loading: false, loadingSave: false });
              ErrorAlert(erro);
            } else {
              let change_password = resp.user.change_password;
              if (change_password == true) {
                setState({ ...state, loadingSave: false, page: 1 });
              } else {
                setState({ ...state, loadingSave: false, loading: true });

                localStorage.setItem("token", resp.access_token);
                localStorage.setItem("user", JSON.stringify(resp.user));
                dispatch(login({ token: resp.access_token, user: resp.user }));

                setTimeout(() => {
                  history("/pbo");
                }, 1500);
              }
            }
          } catch (err) {
            console.log(err);
            setState({ ...state, loading: false, loadingSave: false });
            ErrorAlert("Erro ao pegar resposta do servidor");
          }
        })
        .catch((err) => {
          console.log(err);
          setState({ ...state, loading: false, loadingSave: false });
          ErrorAlert(
            "Erro ao pegar resposta do servidor. Você está conectado a internet?"
          );
        });
      return;
    }
    if (state.page == 1) {
      setState({ ...state, loadingSave: true });
      fetch(`${URL}api/auth/update_password`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: loginData.email.value,
          password: passwordData.newPassword.value,
          password_confirmation: passwordData.newPasswordConfirmation.value,
        }),
      })
        .then(async (responseLog) => {
          try {
            let resp = await responseLog.json();
            console.log(resp);
            if (resp.errors != undefined && resp.errors.length != 0) {
              let errors = Object.values(resp.errors);
              let erro = "";
              for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1) erro += errors[i] + "\n";
                else erro += errors[i];
              }
              console.log(erro);

              setState({ ...state, loading: false, loadingSave: false });
              ErrorAlert(erro);
            } else {
              SuccessAlert("Senha alterada com sucesso. Redirecionando...");

              fetch(`${URL}api/auth/login`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: loginData.email.value,
                  password: passwordData.newPassword.value,
                }),
              })
                .then(async (responseLog) => {
                  try {
                    setState({ ...state, loadingSave: false, loading: true });
                    let resp = await responseLog.json();

                    localStorage.setItem("token", resp.access_token);
                    localStorage.setItem("user", JSON.stringify(resp.user));
                    dispatch(
                      login({ token: resp.access_token, user: resp.user })
                    );

                    setTimeout(() => {
                      history("/pbo/home");
                    }, 1500);
                  } catch (err) {
                    console.log(err);
                    setState({ ...state, loadingSave: false });
                    ErrorAlert("Erro ao pegar resposta do servidor");
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setState({ ...state, loading: false, loadingSave: false });
                  ErrorAlert(
                    "Erro ao pegar resposta do servidor. Você está conectado a internet?"
                  );
                });
            }
          } catch (err) {
            console.log(err);
            setState({ ...state, loading: false, loadingSave: false });
            ErrorAlert("Erro ao pegar resposta do servidor");
          }
        })
        .catch((err) => {
          console.log(err);
          setState({ ...state, loading: false, loadingSave: false });
          ErrorAlert(
            "Erro ao pegar resposta do servidor. Você está conectado a internet?"
          );
        });
      return;
    }
  }

  return (
    <div>
      {state.loading && (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div
          className="d-block h-100"
          style={{
            overflow: "hidden",
            padding: 0,
            margin: 0,
            background: `url(${home_bg}) no-repeat`,
            backgroundPosition: "center center",
            backgroundSize: `cover`,
          }}
        >
          <div className="d-flex flex-row vh-100">
            <main
              style={{
                width: "100%",
              }}
            >
              <div className="d-flex align-items-center justify-content-center vh-100">
                <div
                  // className="card"
                  style={{
                    backgroundColor: "rgba(247, 247, 255, 0.6)",
                    backgroundImage:
                      "linear-gradient(to bottom right, rgba(255,255,255,0.3), rgba(255,255,255,0.1))",
                    backdropFilter: "blur(10px)",
                    boxShadow: "10px 10px 10px rgba(30,30,30,0.4)",
                  }}
                >
                  <a className="bg-primary d-flex justify-content-center p-2">
                    <img
                      src={logo_white}
                      alt="Logotipo da Blueen Open"
                      style={{
                        width: "8rem",
                        filter: "inherit",
                      }}
                    />
                  </a>
                  <div className="form-horizontal p-5">
                    <div>
                      <h1 className="card-title text-body">
                        <b>Login</b>
                      </h1>
                      <p className="card-subtitle mb-4">
                        {renderDescription()}
                      </p>
                    </div>
                    {renderPage()}
                    <div className="mt-4">
                      <Button
                        variant="contained"
                        disableElevation
                        size="large"
                        color="primary"
                        className="col-12"
                        disabled={state.loadingSave}
                        onClick={() => handleSubmit()}
                      >
                        {state.loadingSave && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {!state.loadingSave && <span>Entrar</span>}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </div>
  );
}

export default AltLogin;
