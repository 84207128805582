//* React Imports
import React, { useEffect, useState } from "react";

//* Style Imports
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";

//* Utils Imports
import {
  DeleteOutline,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { ErrorAlert, SuccessAlert, WarningAlert } from "utils/utilities";
import validator from "validator";
import { useSelector } from "react-redux";
import { URL } from "variables";
import { BsCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import EnterpriseData from "screen/Auth/SignUp/Steps/EnterpriseData";
import { maskTelefone } from "utils/masks";

function MyEnterprise() {
  const [state, setState] = useState({
    loading: false,
    disabled: false,
    loadingSave: false,
    enterprise_type: "",
    relationship_status: "",
    relationship_org: "",

    imageHover: false,
  });

  const [formData, setFormData] = useState({
    companyName: { value: "", error: false, msgError: "" },
    commercialName: { value: "", error: false, msgError: "" },
    image: "",
    imgPath: "",
  });

  //* Enterprise data
  const [enterpriseData, setEnterpriseData] = useState({
    cnpj: { value: "", error: false, msgError: "" },
    cep: { value: "", error: false, msgError: "" },
    city: { value: "", error: false, msgError: "", disabled: false },
    state: { value: "", error: false, msgError: "", disabled: false },
    street: { value: "", error: false, msgError: "", disabled: false },
    nbhd: { value: "", error: false, msgError: "", disabled: false },
    number: { value: "", error: false, msgError: "" },
    comp: { value: "", error: false, msgError: "" },
    // phone: { value: "", error: false, msgError: "" },
  });

  function handleStatus() {
    if (state.relationship_status === "Ativo") {
      return <BsCircleFill className="mx-1" color="#73B054" size={8} />;
    }
    if (state.relationship_status === "Inativo") {
      return <BsCircleFill className="mx-1" color="#dc3545" size={8} />;
    }
    if (state.relationship_status === "Pendente") {
      return <BsCircleFill className="mx-1" color="#ffc107" size={8} />;
    }
  }

  const user = useSelector((store) => store.AppReducer.user);
  let history = useNavigate();

  useEffect(() => {
    setState({ ...state, loading: true });
    let token = localStorage.getItem("token");

    fetch(`${URL}api/get_enterprise`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        enterprise_id: user.enterprise_id,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setState({ ...state, loading: false });
          } else {
            if (resp.found === false) {
              history("/pbo/sign-up/enterprise");
              return;
            }
            setState({
              ...state,
              loading: false,
              enterprise_type: resp.user.enterprise_type,
              relationship_status: resp.user.relationship_status,
              relationship_org: resp.user.relationship_org,
            });

            setFormData({
              ...formData,
              companyName: { value: resp.user.company_name || "" },
              commercialName: { value: resp.user.commercial_name || "" },
              imgPath: resp.user.image ? URL + resp.user.image : "",
            });
            setEnterpriseData({
              ...enterpriseData,
              cnpj: { value: resp.user.cnpj || "" },
              cep: { value: resp.user.cep || "" },
              city: { value: resp.user.city || "" },
              state: { value: resp.user.state || "" },
              street: { value: resp.user.street || "" },
              nbhd: { value: resp.user.nbhd || "" },
              number: { value: resp.user.number || "" },
              comp: { value: resp.user.comp || "" },
              // phone: { value: resp.user.phone || "" },
            });
          }
        } catch (err) {
          setState({ ...state, loading: true });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setState({ ...state, loading: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }, []);

  const handleImageChange = (event) => {
    let selectedFile = event.target.files[0];
    let reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setState({
          ...state,
          imageHover: false,
        });
        setFormData({
          ...formData,
          image: selectedFile,
          imgPath: reader.result,
        });
      }
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  function updateEnterprise() {
    let hasError = false;
    let destinationVariables = ["companyName", "commercialName"];
    let data = { ...formData };
    destinationVariables.forEach((item) => {
      if (!data[item].value) {
        data[item].error = true;
        data[item].msgError = "Campo em Branco";
        hasError = true;
      }
      setFormData(data);
    });

    let enterpriseVariables = [
      "cnpj",
      "cep",
      "city",
      "state",
      "number",
      "street",
      "nbhd",
      // "phone",
    ];
    let enterpriseValidation = { ...enterpriseData };
    enterpriseVariables.forEach((item) => {
      if (!enterpriseValidation[item].value) {
        enterpriseValidation[item].error = true;
        enterpriseValidation[item].msgError = "Campo em Branco";
        hasError = true;
      }
      setEnterpriseData(enterpriseValidation);
    });
    if (hasError) return;

    // if (enterpriseData.phone.value.length < 15) {
    //   setEnterpriseData({
    //     ...enterpriseData,
    //     phone: {
    //       value: enterpriseData.phone.value,
    //       error: true,
    //       msgError: "Preencha o campo corretamente",
    //     },
    //   });
    //   return;
    // }
    if (enterpriseData.cnpj.value.length < 18) {
      setEnterpriseData({
        ...enterpriseData,
        cnpj: {
          value: enterpriseData.cnpj.value,
          error: true,
          msgError: "Preencha o campo corretamente",
        },
      });
      return;
    }
    if (enterpriseData.cep.value.length < 8) {
      setEnterpriseData({
        ...enterpriseData,
        cep: {
          value: enterpriseData.cep.value,
          error: true,
          msgError: "Preencha o campo corretamente",
        },
      });
      return;
    }

    setState({ ...state, loadingSave: true });
    let token = localStorage.getItem("token");

    let form = new FormData();
    form.append("image", formData.image);
    form.append("enterprise_id", user.enterprise_id);
    form.append("enterprise_type", state.enterprise_type);
    form.append("company_name", formData.companyName.value);
    form.append("commercial_name", formData.commercialName.value);

    form.append("cnpj", enterpriseData.cnpj.value);
    // form.append("phone", enterpriseData.phone.value);

    form.append("cep", enterpriseData.cep.value);
    form.append("city", enterpriseData.city.value);
    form.append("state", enterpriseData.state.value);
    form.append("street", enterpriseData.street.value);
    form.append("number", enterpriseData.number.value);
    form.append("nbhd", enterpriseData.nbhd.value);
    form.append("comp", enterpriseData.comp.value);

    console.log(form);

    fetch(`${URL}api/update_enterprise`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: form,
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setState({ ...state, loadingSave: false });
          } else {
            SuccessAlert("Empresa atualizada com sucesso");
            setState({
              ...state,
              loadingSave: false,
            });
          }
        } catch (err) {
          setState({ ...state, loadingSave: true });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setState({ ...state, loadingSave: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div
          style={{
            width: "50%",
            margin: "0 auto",
          }}
        >
          <header>
            <h1 className="card-title text-body">
              <b>Editar Empresa</b>
            </h1>
            <span className="text-body">Edite detalhes da sua empresa.</span>
          </header>
          <hr />
          <main className="mt-4">
            <div className="row">
              <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
                <div
                  className="mt-4"
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid rgba(60, 0, 150, 0.8)",
                    borderRadius: "50%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                  onMouseEnter={() => setState({ ...state, imageHover: true })}
                  onMouseLeave={() => setState({ ...state, imageHover: false })}
                >
                  <label
                    htmlFor="profile-image-upload"
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      textAlign: "center",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      opacity: state.imageHover ? "0.75" : "1",
                      transition: "opacity 0.3s ease",
                    }}
                  >
                    <input
                      type="file"
                      id="profile-image-upload"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    {formData.imgPath ? (
                      <img
                        src={formData.imgPath}
                        alt="Foto de Perfil"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          display: "block",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                          background: "#f2f2f2",
                          color: "#888",
                          fontSize: "14px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      >
                        Selecionar Imagem
                      </span>
                    )}
                  </label>
                </div>
                {formData.imgPath && (
                  <div
                    style={{
                      marginBottom: "10vh",
                      borderRadius: "50%",
                      border: "1px solid rgba(248, 51, 60, 0.5)",
                      backgroundColor: "rgba(247, 247, 255, 1)",
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                    }}
                  >
                    <Tooltip title="Remover imagem">
                      <IconButton
                        size="small"
                        color="warning"
                        onClick={() => {
                          setState({
                            ...state,
                            imageHover: false,
                          });
                          setFormData({
                            ...formData,
                            image: "",
                            imgPath: "",
                          });
                        }}
                      >
                        <DeleteOutline
                          style={{
                            color: "#f8333c",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-8">
                <div className="form-group-row mt-4">
                  <TextField
                    label="Razão Social"
                    variant="outlined"
                    required
                    fullWidth
                    value={formData.companyName.value}
                    error={formData.companyName.error}
                    helperText={formData.companyName.msgError}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        companyName: {
                          value: e.target.value,
                          error: false,
                        },
                      });
                    }}
                  />
                </div>
                <div className="form-group-row mt-4">
                  <TextField
                    label="Nome Fantasia"
                    variant="outlined"
                    required
                    fullWidth
                    value={formData.commercialName.value}
                    error={formData.commercialName.error}
                    helperText={formData.commercialName.msgError}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        commercialName: {
                          value: e.target.value,
                          error: false,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {/* <div className="form-group-row mt-4">
                <TextField
                  label="Telefone"
                  variant="outlined"
                  required
                  fullWidth
                  value={enterpriseData.phone.value}
                  error={enterpriseData.phone.error}
                  helperText={enterpriseData.phone.msgError}
                  onChange={(e) => {
                    setEnterpriseData({
                      ...enterpriseData,
                      phone: { value: maskTelefone(e.target.value) },
                    });
                  }}
                />
              </div> */}
              <hr className="my-3" />
              <EnterpriseData
                formData={enterpriseData}
                setFormData={setEnterpriseData}
              />
            </div>
            <hr className="my-3" />
            <h4>
              <strong>Informações Adicionais</strong>
            </h4>
            <div id="situation" className="mt-4">
              <div className="form-group row">
                <div className="col-12">
                  <label className="col-4 col-form-label fw-bold">
                    Tipo de Empresa:
                  </label>
                  {state.enterprise_type}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <label className="col-4 col-form-label fw-bold">
                    Vínculo:
                  </label>
                  {state.relationship_org}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <label className="col-4 col-form-label fw-bold">
                    Status:
                  </label>
                  {state.relationship_status} {handleStatus()}
                </div>
              </div>
            </div>
            <hr className="mt-3" />
            <Button
              variant="contained"
              disableElevation
              size="large"
              color="primary"
              className="col-12 mb-5"
              disabled={state.loadingSave}
              onClick={() => updateEnterprise()}
            >
              {state.loadingSave && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {!state.loadingSave && <span>Salvar</span>}
            </Button>
          </main>
        </div>
      )}
    </div>
  );
}

export default MyEnterprise;
