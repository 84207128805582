import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { logout, notificationCounter } from "redux/AppActions";

import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import notificationSound from "../../assets/sounds/notificacao_teste.mp3";

import {
  AddCircle,
  AdminPanelSettings,
  HelpOutline,
  HomeOutlined,
  InfoOutlined,
  LockOutlined,
  LogoutOutlined,
  NotificationsNoneOutlined,
  PersonOutline,
  SearchOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { URL } from "variables";
import { DCWebSocket, notifications, wsConnection } from "utils/websocket";

const dropdownItemStyle = {
  cursor: "pointer",
  alignItems: "center",
  display: "flex",
  height: "5vh",
  fontSize: "2.25vh",
};

const PrivateNavbar = () => {
  const reducer = useSelector((state) => state.AppReducer);

  useEffect(() => {
    fetch(`${URL}api/count_notifications`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${reducer.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: reducer.user.id,
      }),
    }).then(async (responseLog) => {
      let resp = await responseLog.json();
      dispatch(notificationCounter(resp.count));
    });

    let audio = new Audio(notificationSound);
    console.log('chegou aqui')
    //* Websocket connection + realtime notifications wherever the user is
    let echo = wsConnection();
    notifications(echo, reducer.user, (e) => {
      dispatch(notificationCounter(e.count));
      audio.play();
    });
  }, []);

  const [menu, setMenu] = useState(false);

  const dispatch = useDispatch();
  const token = useSelector((store) => store.AppReducer.token);

  const toggle = useCallback(() => setMenu(!menu), [menu]);
  let history = useNavigate();

  const logout_ = useCallback(() => {
    try {
      DCWebSocket();
    } catch (err) {
      console.log(err);
    }
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch(logout()); // Dispatch logout action

    history("/");
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center p-3 position-fixed w-100"
      style={{
        boxShadow: "-8px 12px 21px -13px rgba(23,15,17,0.5)",
        zIndex: 100,
      }}
    >
      <div className="col-12 d-flex justify-content-evenly">
        <Link to={"/pbo/home"}>
          <IconButton size="small" color="inherit">
            <HomeOutlined
              style={{
                color: "#3C0096",
              }}
              fontSize="large"
            />
          </IconButton>
        </Link>

        <Link to={"/pbo/search"}>
          <IconButton size="small" color="inherit">
            <SearchOutlined
              style={{
                color: "#3C0096",
              }}
              fontSize="large"
            />
          </IconButton>
        </Link>

        <Link to={"/pbo/dashboard"}>
          <IconButton size="small" color="inherit">
            <AddCircle
              style={{
                color: "#3C0096",
              }}
              fontSize="large"
            />
          </IconButton>
        </Link>
        <Link to={"/pbo/notifications"}>
          <IconButton size="small" color="inherit">
            <Badge
              badgeContent={reducer.notifications}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              max={20}
              color="error"
            >
              <NotificationsNoneOutlined
                style={{
                  color: "#3C0096",
                }}
                fontSize="large"
              />
            </Badge>
          </IconButton>
        </Link>

        <Dropdown isOpen={menu} toggle={toggle}>
          <div>
            <DropdownToggle tag="button">
              <IconButton size="small" color="inherit">
                <PersonOutline
                  style={{
                    color: "#3C0096",
                  }}
                  fontSize="large"
                />
              </IconButton>
            </DropdownToggle>
            <DropdownMenu end>
              <h4 className="card-title text-center m-3">
                {reducer.user.name?.toUpperCase()}
              </h4>
              <hr className="m-2" />

              <DropdownItem
                style={dropdownItemStyle}
                onClick={() => history("/pbo/user/profile")}
              >
                <PersonOutline style={{ marginRight: 8 }} />
                Meu Perfil
              </DropdownItem>

              <DropdownItem
                style={dropdownItemStyle}
                onClick={() => history("/pbo/user/enterprise")}
              >
                <AdminPanelSettings style={{ marginRight: 8 }} />
                Dados da Empresa
              </DropdownItem>

              {/* <DropdownItem
                style={dropdownItemStyle}
                onClick={() => history("/pbo/user/settings")}
              >
                <SettingsOutlined style={{ marginRight: 8 }} />
                Configurações
              </DropdownItem> */}

              <DropdownItem
                style={dropdownItemStyle}
                onClick={() => history("/pbo/user/tool-informations")}
              >
                <InfoOutlined style={{ marginRight: 8 }} />
                Informações da Ferramenta
              </DropdownItem>

              <DropdownItem
                style={dropdownItemStyle}
                onClick={() => history("/pbo/user/policy-privacy")}
              >
                <LockOutlined style={{ marginRight: 8 }} />
                Política de Privacidade
              </DropdownItem>

              <DropdownItem
                style={dropdownItemStyle}
                onClick={() => history("/pbo/user/settings")}
              >
                <SettingsOutlined style={{ marginRight: 8 }} />
                Configurações
              </DropdownItem>

              <DropdownItem
                style={dropdownItemStyle}
                onClick={() => history("/pbo/user/help-me")}
              >
                <HelpOutline style={{ marginRight: 8 }} />
                Ajuda
              </DropdownItem>

              <DropdownItem tag="a" style={dropdownItemStyle} onClick={logout_}>
                <LogoutOutlined style={{ marginRight: 8 }} />
                Sair
              </DropdownItem>
            </DropdownMenu>
          </div>
        </Dropdown>
      </div>
    </nav>
  );
};

export default PrivateNavbar;

// {state.notificationContent.some(
//   (item) => item.visualized === false
// ) && (
//   <span
//     className="mt-3"
//     style={{
//       borderRadius: "50%",
//       padding: "0.4vh",
//       backgroundColor: "#f8333c",
//     }}
//   ></span>
// )}
