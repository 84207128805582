//* React imports
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

//* Components imports
import DashboardRoutes from "./DashboardRoutes";
import GeneralForm from "screen/Auth/SignUp/UserTypesForms/GeneralForm";
import HelpMe from "screen/Private/Profile/HelpMe";
import Home from "screen/Private/Home/Home";
import MyProfile from "screen/Private/Profile/MyProfile";
import NotificationsPage from "screen/Private/Notifications/NotificationsPage/NotificationPage";
import PrivateNavbar from "screen/Private/PrivateNavbar";
import Settings from "screen/Private/Profile/Settings";
import ToolInfos from "screen/Private/Profile/ToolInfos";
import Safety from "screen/Private/Profile/Safety";
import PolicyPrivacy from "components/institucional/PolicyPrivacy/PolicyPrivacy";
import UnderConstruction from "components/common/UnderConstruction/UnderConstruction";
import MyEnterprise from "screen/Private/Profile/MyEnterprise";

const PrivateRoutes = () => {
  const token = useSelector((state) => state.AppReducer.token);

  //* Protect Route
  const PrivateRoute = () => {
    const token = useSelector((state) => state.AppReducer.token);
    return token != null ? ( //if user is logged
      <Home /> //go to homepage
    ) : (
      <Navigate to="/login" />
    ); //else go to login
  };

  return (
    <>
      <PrivateNavbar />

      {/* to keep the navbar at the top, you must use position: fixed */}
      {/* but this behavior is similar to the absolute position  */}
      {/* so I had to add a ghost component to occupy the navbar space */}
      <div style={{ height: "6.4rem" }} />

      <Routes>
        <Route path={"/home"} element={<PrivateRoute />} />
        <Route path={"/search"} element={<UnderConstruction />} />

        <Route path={"/user/profile"} element={<MyProfile />} />
        <Route path={"/user/enterprise"} element={<MyEnterprise />} />
        <Route path={"/user/settings"} element={<Settings />} />
        <Route path={"/user/tool-informations"} element={<ToolInfos />} />
        <Route path={"/user/policy-privacy"} element={<Safety />} />
        <Route path={"/user/help-me"} element={<HelpMe />} />

        <Route path={"/notifications"} element={<NotificationsPage />} />

        {/* Rotas provisórias para preenchimento do formulário de cada 'Tipo de Empresa' */}

        {/* dash routes */}
        <Route path="/dashboard/*" element={<DashboardRoutes />} />

        <Route path={"/sign-up/enterprise"} element={<GeneralForm />} />
      </Routes>
    </>
  );
};

export default PrivateRoutes;
