//* React imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//* Style imports
import logo_white from "../../../../assets/static/logo/green_white.png";

//* Utils imports
import { URL } from "variables";
import { ErrorAlert } from "utils/utilities";

//* Components imports
import AccessDenied from "components/common/AccessDeniedPage/AccessDenied";
import DestinoForm from "./Destino/DestinoForm";
import QuickSignup from "../QuickSignup/QuickSignup";

function GeneralForm() {
  const [state, setState] = useState({
    userType: "",
    foundUser: false,
    loading: false,
  });

  const user = useSelector((store) => store.AppReducer.user);
  let history = useNavigate();

  useEffect(() => {
    setState({ ...state, loading: true });
    let token = localStorage.getItem("token");

    fetch(`${URL}api/get_enterprise`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        enterprise_id: user.enterprise_id,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setState({ loading: false });
          } else {
            let foundUser = resp.found;
            console.log(resp);
            setState({
              ...state,
              loading: false,
              userType: resp.enterprise_type,
            });
          }
        } catch (err) {
          setState({ loading: true });
          ErrorAlert("Erro ao pegar resposta do servidor");
          setTimeout(() => {
            history("/pbo/dashboard");
          }, 1500);
        }
      })
      .catch((err) => {
        setState({ loading: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
        setTimeout(() => {
          history("/pbo/dashboard");
        }, 1500);
      });
  }, []);

  return (
    <div className="col-12 d-block">
      <div
        className="container"
        style={{
          minHeight: "75vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="col-10 col-md-8 col-xl-6">
          <div className="card" style={{ margin: 0, minHeight: '80vh' }}>
            <div className="card-header bg-primary">
              <div className="d-flex justify-content-center">
                <div className="logo">
                  <img
                    style={{
                      objectFit: "contain",
                    }}
                    height="60"
                    src={logo_white}
                    alt="Logotipo da Blueen Open"
                  />
                </div>
              </div>
            </div>
            {state.loading && (
              <div
                style={{
                  height: "30vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="loading" role="status"></div>
              </div>
            )}
            {!state.loading && (
              <div className="card-body my-3">
                <div className="col-10" style={{ margin: "0 auto" }}>
                  {state.userType === null && <QuickSignup />}
                  {state.userType !== null && (
                    <>
                      {state.userType.type === "Destino" && <DestinoForm />}
                      {state.userType.type !== "Destino" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "45vh",
                          }}
                        >
                          <AccessDenied aboutMsg="Funções indisponíveis no momento. Entre em contato com sua organização para mais informações." />
                        </div>
                      )}
                      {/* {state.userType.type === "Estoque" && <EstoqueForm />}
                    {state.userType.type === "Transportadora" && <TransportadoraForm />}
                    {state.userType.type === "Motorista Independente" && (
                      <MotoristaIndForm />
                    )} */}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralForm;
