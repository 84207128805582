//* React imports
import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

//* Components imports
import Footer from "components/specific/LandingPage/Footer";
import ForgotPassword from "screen/Auth/ForgotPassword/ForgotPassword";
import HomePage from "screen/LandingPage";
import Login from "../screen/Auth/Login";
import Navbar from "components/specific/LandingPage/Navbar";
import UnderConstruction from "components/common/UnderConstruction/UnderConstruction";
import PolicyPrivacy from "components/institucional/PolicyPrivacy/PolicyPrivacy";

const PublicRoutes = () => {
  const token = useSelector((state) => state.AppReducer.token);

  //* LoginRoute
  const LoginRoute = () => {
    const user = useSelector((state) => state.AppReducer.token);
    console.log(user);
    return user == null ? <Login /> : <Navigate to="/pbo/home" />;
  };

  // this structure is to get the scroll position
  // a lot of components are using this so dont delete it :)
  const [on_top, setOnTop] = useState(true);
  const [force_on_top, setForceOnTop] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      if (force_on_top) {
        setOnTop(false);
        return;
      }

      if (window.pageYOffset < 50) {
        setOnTop(true);
      } else {
        setOnTop(false);
      }
    };

    return () => (window.onscroll = null);
  });

  return (
    <>
      <Navbar {...{ on_top, setOnTop, setForceOnTop }} />

      <Routes>
        <Route path={"/*"} element={<HomePage {...{ on_top }} />} />
        <Route path={"/privacy-policy"} element={<PolicyPrivacy />} />
        <Route path={"/terms-of-use"} element={<UnderConstruction />} />

        <Route path="/login" element={<LoginRoute />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* <Route path="/signup" element={<SignUp />} /> */}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <Footer />
    </>
  );
};

export default PublicRoutes;
