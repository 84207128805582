//* React imports
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//* Style imports
import { Button, TextField } from "@mui/material";

//* Utils imports
import { ErrorAlert, SuccessAlert } from "utils/utilities";
import validator from "validator";

//* Components imports
import EnterpriseData from "screen/Auth/SignUp/Steps/EnterpriseData";
import { URL } from "variables";

function CarrierModal(props) {
  const user = useSelector((store) => store.AppReducer.user);
  const params = useParams();
  let collect_id = params.id;

  const [state, setState] = useState({
    loadingSave: false,
  });

  const [formData, setFormData] = useState({
    companyName: { value: "", error: false, msgError: "" },
    commercialName: { value: "", error: false, msgError: "" },
    responsibleName: { value: "", error: false, msgError: "" },
    responsibleEmail: { value: "", error: false, msgError: "" },
  });

  //* Enterprise data
  const [enterpriseData, setEnterpriseData] = useState({
    cnpj: { value: "", error: false, msgError: "" },
    cep: { value: "", error: false, msgError: "" },
    city: { value: "", error: false, msgError: "", disabled: false },
    state: { value: "", error: false, msgError: "", disabled: false },
    street: { value: "", error: false, msgError: "", disabled: false },
    nbhd: { value: "", error: false, msgError: "", disabled: false },
    number: { value: "", error: false, msgError: "" },
    comp: { value: "", error: false, msgError: "" },
    phone: { value: "", error: false, msgError: "" },
  });

  function handleSubmit() {
    let variables = ["companyName", "responsibleName", "responsibleEmail"];
    let data = { ...formData };
    variables.forEach((item) => {
      if (data[item].value == "") {
        data[item].error = true;
        data[item].msgError = "Campo obrigatório";
      }
      setFormData(data);
      return;
    });
    if (enterpriseData.cnpj.value == "") {
      setEnterpriseData({
        ...enterpriseData,
        cnpj: {
          value: enterpriseData.cnpj.value,
          error: true,
          msgError: "Campo obrigatório",
        },
      });
      return;
    }
    if (enterpriseData.cnpj.value.length !== 18) {
      setEnterpriseData({
        ...enterpriseData,
        cnpj: {
          value: enterpriseData.cnpj.value,
          error: true,
          msgError: "Formato incorreto",
        },
      });
      return;
    }
    if (
      enterpriseData.cep.value !== "" &&
      enterpriseData.cep.value.length < 8
    ) {
      setEnterpriseData({
        ...enterpriseData,
        cep: {
          value: enterpriseData.cep.value,
          error: true,
          msgError: "Formato incorreto",
        },
      });
      return;
    }

    if (
      formData.responsibleEmail.value !== "" &&
      !validator.isEmail(formData.responsibleEmail.value)
    ) {
      setFormData({
        ...formData,
        responsibleEmail: {
          value: formData.responsibleEmail.value,
          error: true,
          msgError: "Formato incorreto",
        },
      });
      return;
    }

    let token = localStorage.getItem("token");
    setState({ ...state, loadingSave: true });
    fetch(`${URL}api/quick_store_carrier`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        //Creating the user
        name: formData.responsibleName.value,
        email: formData.responsibleEmail.value,

        //Creating the enterprise
        cnpj: enterpriseData.cnpj.value,
        cep: enterpriseData.cep.value,
        city: enterpriseData.city.value,
        state: enterpriseData.state.value,
        street: enterpriseData.street.value,
        number: enterpriseData.number.value,
        nbhd: enterpriseData.nbhd.value,
        comp: enterpriseData.comp.value,
        phone: enterpriseData.phone.value,

        //Creating the carrier
        company_name: formData.companyName.value,
        commercial_name: formData.commercialName.value,

        //Id destination
        enterprise_destination_id: user.enterprise_id,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let error = resp.errors.error;

            setState({ ...state, loadingSave: false });
            if (error === "emailerror") {
              setFormData({
                ...formData,
                responsibleEmail: {
                  value: formData.responsibleEmail.value,
                  error: true,
                  msgError: "Este e-mail já está em uso",
                },
              });
              return;
            }
            if (error === "cnpjerror") {
              setEnterpriseData({
                ...enterpriseData,
                cnpj: {
                  value: enterpriseData.cnpj.value,
                  error: true,
                  msgError: "Já existe uma empresa com esse CNPJ",
                },
              });
              return;
            }
          } else {
            setState({ ...state, loadingSave: false });
            SuccessAlert("Transportadora cadastrada com sucesso!");
            props.updateState("carriers", resp.data, "carrierId", resp.data.id);
            props.close();
          }
        } catch (err) {
          console.log(err);
          setState({ ...state, loadingSave: false });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loadingSave: false });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <>
      <header>
        <p className="text-center">Crie rapidamente uma transportadora</p>
      </header>
      <main>
        <div className="form-group-row mt-5">
          <TextField
            label="Razão Social"
            variant="outlined"
            fullWidth
            required
            error={formData.companyName.error}
            helperText={formData.companyName.msgError}
            value={formData.companyName.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                companyName: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          />
        </div>
        <div className="form-group-row mt-3">
          <TextField
            label="Nome Fantasia"
            variant="outlined"
            fullWidth
            error={formData.commercialName.error}
            helperText={formData.commercialName.msgError}
            value={formData.commercialName.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                commercialName: { value: e.target.value, error: false },
              });
            }}
          />
        </div>
        <div className="form-group-row mt-3">
          <TextField
            label="Telefone"
            variant="outlined"
            fullWidth
            error={enterpriseData.phone.error}
            helperText={enterpriseData.phone.msgError}
            value={enterpriseData.phone.value}
            onChange={(e) => {
              setEnterpriseData({
                ...enterpriseData,
                phone: { value: e.target.value, error: false },
              });
            }}
          />
        </div>
        <div className="form-group mt-3 row">
          <div className=" col-sm-12">
            <div className="row">
              <div className="col-md-6 col-12">
                <TextField
                  label="Nome Responsável"
                  variant="outlined"
                  fullWidth
                  required
                  error={formData.responsibleName.error}
                  helperText={formData.responsibleName.msgError}
                  value={formData.responsibleName.value}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      responsibleName: { value: e.target.value, error: false },
                    });
                  }}
                />
              </div>
              <div className="col-md-6 col-12 mt-md-0 mt-3">
                <TextField
                  label="E-mail Responsável"
                  variant="outlined"
                  fullWidth
                  required
                  error={formData.responsibleEmail.error}
                  helperText={formData.responsibleEmail.msgError}
                  value={formData.responsibleEmail.value}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      responsibleEmail: { value: e.target.value, error: false },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <EnterpriseData
          formData={enterpriseData}
          setFormData={setEnterpriseData}
        />
      </main>
      <footer>
        <div className="mt-4">
          <Button
            variant="contained"
            disableElevation
            size="large"
            color="info"
            className="col-12"
            disabled={state.loadingSave}
            onClick={() => handleSubmit()}
          >
            {state.loadingSave && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {!state.loadingSave && <span>Salvar</span>}
          </Button>
        </div>
      </footer>
    </>
  );
}

export default CarrierModal;
