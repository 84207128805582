//* React imports
import React from "react";

//* Style imports
import { TextField } from "@mui/material";

//* Utils imports
import { NumericFormat, PatternFormat } from "react-number-format";
import { maskCep, maskCnpj } from "utils/masks";

function EnterpriseData({ formData, setFormData }) {
  //* Find the CEP and fills some fields
  const searchCep = async (value) => {
    let formData2 = { ...formData };
    formData2.cep.value = value;
    formData2.cep.error = false;
    formData2.cep.msgError = "";
    setFormData(formData2);

    if (Array.from(value).length >= 8) {
      validateCep();
    } else if (value == "") {
      clearCEP();
    }
  };

  async function validateCep() {
    const endereco = await fetch(
      `https://viacep.com.br/ws/${formData.cep.value}/json/`
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        clearCEP();
        setFormData({
          ...formData,
          cep: { value: "", error: true, msgError: "CEP inválido" },
        });
      });
    const data = await endereco.json();
    if (data.hasOwnProperty("erro")) {
      clearCEP();
      setFormData({
        ...formData,
        cep: { value: "", error: true, msgError: "CEP não encontrado!" },
      });
    } else {
      setFormData({
        ...formData,
        city: { value: data.localidade, disabled: true, error: false },
        nbhd: { value: data.bairro, error: false },
        street: { value: data.logradouro, error: false },
        state: { value: data.uf, disabled: true, error: false },
      });
    }
  }

  function clearCEP() {
    let formData2 = { ...formData };
    let form = Object.keys(formData);
    const filter = form.filter(
      (item) =>
        item == "cep" ||
        item == "street" ||
        item == "nbhd" ||
        item == "state" ||
        item == "city" ||
        item == "number"
    );
    filter.forEach((item) => {
      formData2[item].value = "";
      formData2[item].disabled = false;
      formData2[item].error = false;
    });
    setFormData(formData2);
  }

  //* All userData is filled in here
  return (
    <div>
      <div className="form-group-row mt-4">
        <TextField
          label="CNPJ"
          variant="outlined"
          fullWidth
          required
          error={formData.cnpj.error}
          helperText={formData.cnpj.msgError}
          value={formData.cnpj.value}
          onChange={(e) => {
            setFormData({
              ...formData,
              cnpj: {
                value: maskCnpj(e.target.value),
                error: false,
                msgError: "",
              },
            });
          }}
        />
      </div>
      <div className="form-group-row mt-3">
        <TextField
          label="CEP"
          variant="outlined"
          fullWidth
          error={formData.cep.error}
          helperText={formData.cep.msgError}
          value={formData.cep.value}
          onChange={(e) => {
            setFormData({
              ...formData,
              cep: {
                value: maskCep(e.target.value),
                error: false,
                msgError: "",
              },
            });
            let text = e.target.value.replace("-", "");
            if (text.length == 8) {
              searchCep(text);
            }
          }}
        />
      </div>
      <div className="form-group mt-3 row">
        <div className=" col-sm-12">
          <div className="row">
            <div className="col-7">
              <TextField
                label="Rua"
                variant="outlined"
                fullWidth
                disabled={formData.street.disabled}
                error={formData.street.error}
                helperText={formData.street.msgError}
                value={formData.street.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    street: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
            <div className="col-5">
              <TextField
                label="Número"
                variant="outlined"
                fullWidth
                disabled={formData.number.disabled}
                error={formData.number.error}
                helperText={formData.number.msgError}
                value={formData.number.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    number: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-group mt-3 row">
        <div className=" col-sm-12">
          <div className="row">
            <div className="col-md-7 col-12">
              <TextField
                label="Bairro"
                variant="outlined"
                fullWidth
                disabled={formData.nbhd.disabled}
                error={formData.nbhd.error}
                helperText={formData.nbhd.msgError}
                value={formData.nbhd.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    nbhd: { value: e.target.value, error: false, msgError: "" },
                  });
                }}
              />
            </div>
            <div className="col-md-5 col-12 mt-md-0 mt-3">
              <TextField
                label="Complemento"
                variant="outlined"
                fullWidth
                disabled={formData.comp.disabled}
                error={formData.comp.error}
                helperText={formData.comp.msgError}
                value={formData.comp.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    comp: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-group mt-3 row">
        <div className=" col-sm-12">
          <div className="row">
            <div className="col-9">
              <TextField
                label="Cidade"
                variant="outlined"
                fullWidth
                disabled={formData.city.disabled}
                error={formData.city.error}
                helperText={formData.city.msgError}
                value={formData.city.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    city: { value: e.target.value, error: false, msgError: "" },
                  });
                }}
              />
            </div>
            <div className="col-3">
              <TextField
                label="Estado"
                variant="outlined"
                fullWidth
                disabled={formData.state.disabled}
                error={formData.state.error}
                helperText={formData.state.msgError}
                value={formData.state.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    state: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnterpriseData;
