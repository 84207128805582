import CustomSection from "components/specific/CustomSection";
import { Container, Box } from "@mui/system";
import { Typography } from "@mui/material";
import styles from "./styles.module.css";
import arrow_white from "assets/static/arrow_white.png";
import time from "assets/static/landing/time.svg";
import transport from "assets/static/landing/transport.svg";
import partners_info from "assets/static/landing/partners_info.svg";
import data from "assets/static/landing/data.svg";
import business from "assets/static/landing/business.svg";
import social from "assets/static/landing/social.svg";

const Services = () => {
  return (
    <CustomSection
      bg_color="var(--background-0)"
      style={{
        overflow: "hidden",
        minHeight: "auto",
        padding: "8rem 0",
      }}
      id="funcionalidades"
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
        }}
      >
        <Typography
          variant="h3"
          component="h3"
          sx={{
            maxWidth: "68rem",
            color: "var(--blue-1)",
            textAlign: "center",
            fontWeight: "bold",
            margin: "0 auto",
            marginBottom: "1.4rem",
          }}
        >
          Gerenciar seus resíduos pode ser mais que uma obrigação e se tornar
          uma oportunidade!
        </Typography>

        <Typography
          variant="p"
          component="p"
          sx={{
            // color: "var(--text-reverse-1)",
            color: "var(--text-main-1)",
            textAlign: "center",
            margin: "0 auto",
            marginBottom: "4rem",
            fontWeight: "normal",
            fontSize: "1.1rem",
            maxWidth: "50rem",
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "40px",
              borderTop: "2px solid #444",
              margin: "30px auto 0",
            },
          }}
        >
          Com a Blueen você tem a ferramenta certa para otimizar seus processos,
          seu tempo e ganhar com mais economia, rastreabilidade, controle e
          inovação.
        </Typography>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4rem 2rem",
          }}
        >
          <ServiceCard
            title="Otimize seu tempo"
            description="Com processos automatizados, descentralizados e integrados aos sistemas dos órgãos
            ambientais (estaduais e federal), você ganha tempo e torna sua rotina mais produtiva e dinâmica"
            icon={time}
          />
          <ServiceCard
            title="Transparência e Rastreabilidade"
            description="Com nossa tecnologia blockchain, é possível acompanhar todo caminho dos seus
            resíduos e garantir a segurança e transparência em todo o processo, desde a coleta até o destino final. "
            icon={transport}
          />
          <ServiceCard
            title="Conexão e Compliance"
            description="Tenha uma visão integrada e completa de todos seus prestadores e parceiros, acompanhando
            o atendimento a requisitos legais de cada um e trazendo uniformidade nas comunicações e relacionamento"
            icon={partners_info}
          />
          <ServiceCard
            title="Dados"
            description="Com nossos relatórios e gráficos gerados automaticamente, fornecemos informações detalhadas sobre os
            resíduos, com análise de dados mais relevantes como tipos, quantidades, custos, receitas e muito mais"
            icon={data}
          />
          <ServiceCard
            title="Business Intelligence"
            description="A Blueen se diferencia por incorporar estratégias inteligentes com aplicação de algoritmos de
            otimitzação de rotas e de inteligência artificial para identificar padrões, tendências e alternativas simbióticas de
            destinação, ajudando as empresas a tomar decisões mais informadas e melhorar sua eficiência."
            icon={business}
          />
          <ServiceCard
            title="Indústria 4.0"
            description="Nossa plataforma possui APIs aptos a receber informações e dados diretos de balanças e dispositivos
            com aplicativo específico e feito para tornar medições mais rápidas, precisas e confiáveis"
            icon={social}
          />
        </div>
      </Container>
    </CustomSection>
  );
};

const ServiceCard = ({ title, description, icon }) => {
  return (
    <Box
      sx={{
        width: "24rem",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
        padding: "3rem 2rem",
        paddingBottom: "2rem",
        backgroundColor: "var(--background-1)",
        borderRadius: "0.8rem",
        position: "relative",
        boxShadow: "0 0 var(--background-2)",
        border: "1px solid var(--background-2)",
        transition: "all 0.3s",
        cursor: "pointer",
        "&:hover": {
          // backgroundColor: "var(--background-0)",
          transform: "translateY(-1rem)",
          boxShadow: "0 1rem var(--background-2)",
        },
      }}
    >
      <img
        src={icon}
        alt="Ícone do cartão"
        style={{
          height: "14rem",
          marginBottom: "1rem",
        }}
      />
      <h3
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: "var(--text-main-2)",
        }}
      >
        {title}
      </h3>
      <p
        style={{
          fontSize: "1rem",
          fontWeight: "normal",
          color: "var(--text-main-1)",
          textAlign: "center",
        }}
      >
        {description}
      </p>

      {/* <button
        className={`Transition-1 ${styles.Button}`}
        style={{
          position: "relative",
        }}
      >
        Ver mais
        <img
          className="Transition-1"
          src={arrow_white}
          alt="Ver mais"
          style={{
            position: "absolute",
            top: "80%",
            left: "10%",
            transform: "translate(-50%, -50%)",
            opacity: "0",
          }}
        />
      </button> */}
    </Box>
  );
};

export default Services;
