//* React imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

//* Style imports
import {
  Alert,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { EditOutlined, Reply } from "@mui/icons-material";

//* Utils imports
import { URL } from "variables";
import { NumericFormat } from "react-number-format";
import { ErrorAlert, SuccessAlert } from "utils/utilities";
import { useSelector } from "react-redux";

function AddVehicle() {
  const [state, setState] = useState({
    loading: true,
    loadingSave: false,
    carriers: [],
    vehicleData: [],
    unitPesoData: [],
    unitVolData: [],
    vehicleTypeData: [],
    vehicleModelData: [],
    avaliableModels: [],
    conservation: [
      "Perfeito estado",
      "Bom",
      "Semi-novo",
      "Problemático",
      "Inutilizável",
    ],
  });

  const [formData, setFormData] = useState({
    licensePlate: { value: "", error: false, msgError: "" },
    conservation: { value: "", error: false, msgError: "" },
    model: { value: "", error: false, msgError: "" },
    type: { value: "", error: false, msgError: "Ex: Caminhão, carro..." },
    anttRegister: { value: "", error: false, msgError: "" },
    description: { value: "", error: false, msgError: "" },
    maxCapacity_peso: { value: "", error: false, msgError: "" },
    maxCapacity_volume: { value: "", error: false, msgError: "" },
    unitPesoName: { value: "", error: false, msgError: "" },
    unitVolumeName: { value: "", error: false, msgError: "" },
    carrier: { value: "", error: false, msgError: "" },
  });

  //Setting variables to fetches
  const token = localStorage.getItem("token");
  const enterprise_id = useSelector(
    (store) => store.AppReducer.user.enterprise_id
  );

  let history = useNavigate();

  useEffect(() => {
    Promise.all([
      fetch(`${URL}api/get_vehicles`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      fetch(`${URL}api/get_units`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      fetch(`${URL}api/get_carriers_list/${enterprise_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    ])
      .then(([resVehicles, resUnits, resCarriers]) =>
        Promise.all([resVehicles.json(), resUnits.json(), resCarriers.json()])
      )
      .then(([vehicles, units, carriers]) => {
        setState({
          ...state,
          loading: false,
          carriers: carriers.carriers,
          vehicleModelData: vehicles.models,
          vehicleTypeData: vehicles.types,
          unitPesoData: units.units_peso,
          unitVolData: units.units_volume,
        });
      });
  }, []);

  function handleVehicleType({ value }) {
    let models = [];
    for (let i = 0; i < state.vehicleModelData.length; i++) {
      if (state.vehicleModelData[i].vehicle_type_id == value) {
        models.push(state.vehicleModelData[i]);
      }
    }
    setState({ ...state, avaliableModels: models });
    setFormData({
      ...formData,
      type: { value: value, error: false, msgError: "" },
    });
  }

  function handleSubmit() {
    let hasError = false;
    let variables = [
      "licensePlate",
      "model",
      "type",
      "maxCapacity_peso",
      "maxCapacity_volume",
      "unitPesoName",
      "unitVolumeName",
      "carrier",
    ];
    let data = { ...formData };
    variables.forEach((item) => {
      if (!data[item].value) {
        data[item].error = true;
        data[item].msgError = "Campo em Branco";
        hasError = true;
      }
      setFormData(data);
    });
    if (hasError) return;

    if (formData.licensePlate.value.length < 7) {
      setFormData({
        ...formData,
        licensePlate: {
          value: formData.licensePlate.value,
          error: true,
          msgError: "O campo possui 7 dígitos",
        },
      });
      return;
    }

    setState({ ...state, loadingSave: true });
    let vehicleData = { ...state.vehicleData };

    vehicleData.license_plate = formData.licensePlate.value;
    vehicleData.antt_register = formData.anttRegister.value;
    vehicleData.conservation = formData.conservation.value;
    vehicleData.description = formData.description.value;
    vehicleData.max_capacity_peso = formData.maxCapacity_peso.value;
    vehicleData.unit_peso_id = formData.unitPesoName.value;
    vehicleData.max_capacity_volume = formData.maxCapacity_volume.value;
    vehicleData.unit_volume_id = formData.unitVolumeName.value;

    vehicleData.vehicle_model_id = formData.model.value;
    vehicleData.carrier_id = formData.carrier.value;

    fetch(`${URL}api/quick_store_carrier_vehicle/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(vehicleData),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          SuccessAlert("Veículo cadastrado com sucesso!");
          history("/pbo/dashboard/vehicles");
        } catch (err) {
          console.log(err);
          setState({ ...state, loadingSave: false });
          ErrorAlert("Erro ao pegar resposta do servidor.");
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loadingSave: false });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div>
          <header>
            <h1 className="card-title text-body">
              <b>Adicionar Veículo</b>
            </h1>
            <span className="text-body">
              Adicione um veículo e o vincule com uma transportadora.
            </span>
          </header>
          <hr />
          <main className="mt-4">
            <div className="form-group-row mt-4">
              <TextField
                label="Placa do Veículo"
                variant="outlined"
                fullWidth
                required
                error={formData.licensePlate.error}
                helperText={formData.licensePlate.msgError}
                value={formData.licensePlate.value}
                inputProps={{ maxLength: 7 }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    licensePlate: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="Tipo do Veículo"
                variant="outlined"
                fullWidth
                select
                required
                error={formData.type.error}
                helperText={formData.type.msgError}
                value={formData.type.value}
                onChange={(e) => {
                  handleVehicleType(e.target);
                }}
              >
                {state.vehicleTypeData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.type}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="Modelo do Veículo"
                variant="outlined"
                fullWidth
                select
                required
                disabled={
                  formData.type.value == "" || formData.type.error == true
                }
                error={formData.model.error}
                helperText={formData.model.msgError}
                value={formData.model.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    model: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              >
                {state.avaliableModels.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.brand} - {item.model}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="Registro ANTT"
                variant="outlined"
                fullWidth
                error={formData.anttRegister.error}
                helperText={formData.anttRegister.msgError}
                value={formData.anttRegister.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    anttRegister: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="Conservação"
                variant="outlined"
                fullWidth
                select
                error={formData.conservation.error}
                helperText={formData.conservation.msgError}
                value={formData.conservation.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    conservation: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              >
                {state.conservation.map((item, id) => (
                  <MenuItem key={id} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="Descrição"
                variant="outlined"
                multiline
                rows={2}
                fullWidth
                error={formData.description.error}
                helperText={formData.description.msgError}
                value={formData.description.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    description: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
            <hr />
            <div className="form-group mt-3 row">
              <div className=" col-sm-12">
                <div className="row">
                  <div className="col-8">
                    <NumericFormat
                      customInput={TextField}
                      valueIsNumericString
                      thousandsGroupStyle="thousand"
                      thousandSeparator="."
                      decimalScale={2}
                      decimalSeparator=","
                      label="Capacidade Máxima (peso)"
                      variant="outlined"
                      fullWidth
                      required
                      error={formData.maxCapacity_peso.error}
                      helperText={formData.maxCapacity_peso.msgError}
                      value={formData.maxCapacity_peso.value}
                      onValueChange={(values, e) => {
                        setFormData({
                          ...formData,
                          maxCapacity_peso: {
                            value: values.value,
                            error: false,
                            msgError: "",
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <TextField
                      label="Unidade (peso)"
                      variant="outlined"
                      fullWidth
                      select
                      required
                      error={formData.unitPesoName.error}
                      helperText={formData.unitPesoName.msgError}
                      value={formData.unitPesoName.value}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          unitPesoName: {
                            value: e.target.value,
                            error: false,
                            msgError: "",
                          },
                        });
                      }}
                    >
                      {state.unitPesoData.map((item, id) => (
                        <MenuItem key={id} value={item.id}>
                          {item.name} - {item.abbreviation}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group mt-3 row">
              <div className=" col-sm-12">
                <div className="row">
                  <div className="col-8">
                    <NumericFormat
                      customInput={TextField}
                      valueIsNumericString
                      thousandsGroupStyle="thousand"
                      thousandSeparator="."
                      decimalScale={2}
                      decimalSeparator=","
                      label="Capacidade Máxima (volume)"
                      variant="outlined"
                      fullWidth
                      required
                      error={formData.maxCapacity_volume.error}
                      helperText={formData.maxCapacity_volume.msgError}
                      value={formData.maxCapacity_volume.value}
                      onValueChange={(values, e) => {
                        setFormData({
                          ...formData,
                          maxCapacity_volume: {
                            value: values.value,
                            error: false,
                            msgError: "",
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <TextField
                      label="Unidade (volume)"
                      variant="outlined"
                      fullWidth
                      select
                      required
                      error={formData.unitVolumeName.error}
                      helperText={formData.unitVolumeName.msgError}
                      value={formData.unitVolumeName.value}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          unitVolumeName: {
                            value: e.target.value,
                            error: false,
                            msgError: "",
                          },
                        });
                      }}
                    >
                      {state.unitVolData.map((item, id) => (
                        <MenuItem key={id} value={item.id}>
                          {item.name} - {item.abbreviation}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <hr className="my-3" />
              </div>
              <div id="addInfo" className="col-12">
                <h4>
                  <strong>Informações Adicionais</strong>
                </h4>
                <div id="situation" className="mt-4">
                  <div className="form-group-row mt-4">
                    <TextField
                      className="form-control"
                      variant="outlined"
                      fullWidth
                      select
                      required
                      label="Transportadora"
                      value={formData.carrier.value}
                      error={formData.carrier.error}
                      helperText={formData.carrier.msgError}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          carrier: { value: e.target.value, error: false },
                        });
                      }}
                    >
                      {state.carriers.map((item, id) => (
                        <MenuItem key={id} value={item.id ?? " "}>
                          {item.company_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex align-items-center justify-content-between my-5">
                <Link to={"/pbo/dashboard/vehicles"}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    color="error"
                    disabled={state.loadingSave}
                    startIcon={<Reply />}
                  >
                    <span>Voltar</span>
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                  disabled={state.loadingSave}
                  onClick={() => handleSubmit()}
                >
                  {state.loadingSave && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!state.loadingSave && <span>Salvar</span>}
                </Button>
              </div>
              <hr />
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default AddVehicle;
