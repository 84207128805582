//* React imports
import React, { useEffect, useState } from "react";

//* Welcome import
import welcome from "../../../assets/static/welcome.svg";

//* Components imports
import UnderConstruction from "components/common/UnderConstruction/UnderConstruction";

function DashboardHome() {
  const [state, setState] = useState({
    loading: false,
  });

  return (
    <div>
      {state.loading && (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && ( 
        <div className="d-flex justify-content-center align-items-center w-100">
          <img
            src={welcome}
            alt="Bem vindo(a)"
            style={{
              height: "80vh",
              objectFit: "contain",
              borderBottom: "3px solid #00C2B3"
            }}
          />
        </div>
      )}
    </div>
  );
}

export default DashboardHome;
