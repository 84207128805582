//* React imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//* Style imports
import { Button, Step, StepLabel, Stepper } from "@mui/material";
import { Reply } from "@mui/icons-material";

//* Utils imports
import { ErrorAlert, SuccessAlert } from "utils/utilities";
import { URL } from "variables";

//* Components imports
import DestinationData from "./Steps/DestinationData";
import OfficeHourData from "./Steps/OfficeHourData";

function DestinoForm() {
  const [state, setState] = useState({
    loading: false,
    loadingSave: false,
    page: 0,
  });

  const user = useSelector((store) => store.AppReducer.user);
  const token = useSelector((store) => store.AppReducer.token);
  let history = useNavigate();

  useEffect(() => {
    setState({ ...state, loading: true });
    fetch(`${URL}api/get_units`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setState({ ...state, loading: false });
          } else {
            setState({ ...state, loading: false });
            setDestinationData({
              ...destinationData,
              unitPesoData: resp.units_peso,
              unitVolData: resp.units_volume,
            });
          }
        } catch (err) {
          setState({ ...state, loading: true });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setState({ ...state, loading: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }, []);

  const [destinationData, setDestinationData] = useState({
    companyName: { value: "", error: false, msgError: "" },
    commercialName: { value: "", error: false, msgError: "" },
    maxCapacity_peso: { value: "", error: false, msgError: "" },
    maxCapacity_volume: { value: "", error: false, msgError: "" },
    unitPeso: { value: "", error: false, msgError: "" },
    unitVolume: { value: "", error: false, msgError: "" },

    unitPesoData: [],
    unitVolData: [],
  });

  const [expedientData, setExpedientData] = useState({
    phone: { value: "", error: false, msgError: "" },
    weekday: {
      dom: false,
      seg: false,
      ter: false,
      qua: false,
      qui: false,
      sex: false,
      sab: false,
    },
    startTime: "",
    endTime: "",

    openingHours: [],
  });

  function renderDescription() {
    if (state.page === 0) {
      return (
        <p className="card-subtitle">
          Informações complementares sobre o destino.
        </p>
      );
    }
    if (state.page === 1) {
      return (
        <p className="card-subtitle">
          Informações sobre o expediente da empresa.
        </p>
      );
    }
  }

  function renderPage() {
    if (state.page === 0) {
      return (
        <DestinationData
          formData={destinationData}
          setFormData={setDestinationData}
        />
      );
    }
    if (state.page === 1) {
      return (
        <OfficeHourData
          formData={expedientData}
          setFormData={setExpedientData}
        />
      );
    }
  }

  function nextStep() {
    if (state.page === 0) {
      let variables = Object.keys(destinationData);
      let data = { ...destinationData };
      variables.forEach((item) => {
        if (data[item].value == "") {
          data[item].error = true;
          data[item].msgError = "Campo em Branco";
        }
        setDestinationData(data);
        return;
      });
      if (
        destinationData.companyName.error !== true &&
        destinationData.commercialName.error !== true &&
        destinationData.maxCapacity_peso.error !== true &&
        destinationData.maxCapacity_volume.error !== true &&
        destinationData.unitPeso.error !== true &&
        destinationData.unitVolume.error !== true
      ) {
        setState({ ...state, page: 1 });
      }
      return;
    }
    if (state.page === 1) {
      let openingHours = [...expedientData.openingHours];
      let expedient = [];
      openingHours.forEach((element) => {
        element.weekday.forEach((weekdays) => {
          let obj = {
            weekday: weekdays,
            start_time: element.startTime,
            end_time: element.endTime,
          };
          expedient.push(obj);
        });
      });

      if (expedientData.phone.value == "") {
        setExpedientData({
          ...expedientData,
          phone: { error: true, msgError: "Campo em branco" },
        });
        return;
      }

      if (expedientData.phone.value.length < 15) {
        setExpedientData({
          ...expedientData,
          phone: { error: true, msgError: "Digite o número corretamente" },
        });
        return;
      }

      if (expedientData.phone.error !== true) {
        setState({ ...state, loadingSave: true });
        fetch(`${URL}api/store_destination`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            company_name: destinationData.companyName.value,
            commercial_name: destinationData.commercialName.value,
            phone: expedientData.phone.value,
            enterprise_id: user.enterprise_id,

            unit_peso_id: destinationData.unitPeso.value,
            unit_volume_id: destinationData.unitVolume.value,
            max_capacity_peso: destinationData.maxCapacity_peso.value,
            max_capacity_volume: destinationData.maxCapacity_volume.value,

            office_hours: expedient,
          }),
        })
          .then(async (responseLog) => {
            try {
              let resp = await responseLog.json();
              if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let erro = "";
                for (let i = 0; i < errors.length; i++) {
                  if (i != errors.length - 1) erro += errors[i] + "\n";
                  else erro += errors[i];
                }
                ErrorAlert(erro);
                setState({ ...state, loadingSave: false });
              } else {
                SuccessAlert(
                  "Destino cadastrado com sucesso. Redirecionando..."
                );
                setState({ ...state, loading: true });
                setTimeout(() => {
                  history("/pbo/dashboard");
                }, 1500);
              }
            } catch (err) {
              ErrorAlert("Erro ao pegar resposta do servidor");
              setState({ ...state, loadingSave: false });
            }
          })
          .catch((err) => {
            console.log(err);
            ErrorAlert(
              "Erro ao pegar resposta do servidor. Você está conectado a internet?"
            );
            setState({ ...state, loadingSave: false });
          });
      }
    }
  }

  function stepBack() {
    if (state.page > 0) {
      setState({ ...state, page: state.page - 1 });
    }
  }

  return (
    <div className="col-12 d-block">
      {state.loading && (
        <div
          style={{
            height: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <header className="mb-4">
          <h2 className="font-18 card-title text-body">Cadastre sua empresa</h2>
          <p className="card-subtitle my-2">{renderDescription()}</p>
        </header>
      )}
      {!state.loading && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="form-horizontal w-100">
            <div className="w-75" style={{ margin: "0 auto" }}>
              <Stepper activeStep={state.page}>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
                <Step>
                  <StepLabel></StepLabel>
                </Step>
              </Stepper>
            </div>
            {renderPage()}

            <div className="mt-5">
              {state.page >= 0 && (
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                  className="col-12"
                  disabled={state.loadingSave || state.page == 2}
                  onClick={() => nextStep()}
                >
                  {state.loadingSave && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!state.loadingSave && state.page < 1 && <span>Avançar</span>}
                  {!state.loadingSave && state.page >= 1 && (
                    <span>Concluir</span>
                  )}
                </Button>
              )}
              {state.page > 0 && (
                <div className="mt-3">
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    color="error"
                    disabled={state.loadingSave}
                    startIcon={<Reply />}
                    onClick={() => stepBack()}
                  >
                    {state.loadingSave && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    {!state.loadingSave && <span>Voltar</span>}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DestinoForm;
