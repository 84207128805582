import React, { useState } from "react";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import CustomSection from "../../components/specific/CustomSection";
import ReactInputMask from "react-input-mask";
import { phoneMaskBrazil } from "hooks/helpers";
import { ErrorAlert, SuccessAlert } from "utils/utilities";
import { post } from "hooks/requests";
import { URL } from "../../variables";

import contact_us_bg from "assets/static/landing/contact_us2.png";

const Contato = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handlePhoneChange = (e) => {
    setPhone(phoneMaskBrazil(e));
  };

  const handleSendContact = async () => {
    if (!name || !email || !phone || !cnpj || !message) {
      ErrorAlert("Preencha todos os campos!");
      return;
    }

    setLoading(true);
    fetch(`${URL}api/auth/contact_us`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        cnpj: cnpj,
        message: message,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setLoading(false);
          } else {
            setName("");
            setEmail("");
            setPhone("");
            setCnpj("");
            setMessage("");
            setLoading(false);
            SuccessAlert("Sua mensagem foi enviada com sucesso!");
          }
        } catch (err) {
          setLoading(false);
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  };

  return (
    <CustomSection
      bg_color="white"
      style={{
        overflow: "hidden",
        position: "relative",
        minHeight: "auto",
      }}
      id="contato"
      bg_image_ghost={contact_us_bg}
      bg_image_ghost_opacity={1}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          minHeight: "100vh",
          gap: "2rem",
          // flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "50%",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            margin: {
              xs: "auto auto 0 auto",
              sm: "auto auto 0 auto",
              md: "auto",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.6rem",
              width: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.6)",
              padding: "2.6rem 2.4rem",
              borderRadius: "0.4rem",
              backdropFilter: "blur(1rem)",
            }}
          >
            <Typography
              variant="h3"
              style={{ fontWeight: "bold", marginBottom: "0" }}
            >
              Entre em contato conosco
            </Typography>
            <Typography
              variant="p"
              style={{ marginBottom: "1.6rem", fontSize: "1.1rem" }}
            >
              Preencha os dados abaixo para entrar em contato e fazer parte da
              Blueen Open.
            </Typography>
            <TextField
              label="Nome completo"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label="E-mail"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Telefone"
              variant="outlined"
              fullWidth
              value={phone}
              inputProps={{ maxLength: 15 }}
              onChange={(e) => handlePhoneChange(e)}
            />
            <ReactInputMask
              mask="99.999.999/9999-99"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
              maskChar=""
            >
              {() => <TextField label="CNPJ" variant="outlined" fullWidth />}
            </ReactInputMask>
            <TextField
              label="Mensagem"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              type="button"
              style={{
                backgroundColor: "#009b8f",
                color: "#fbfbfb",
                fontWeight: "bold",
                borderRadius: "0.4rem",
                cursor: "pointer",
                padding: "0.6rem 1.8rem",
                width: "max-content",
                alignSelf: "flex-end",
              }}
              disabled={loading}
              onClick={handleSendContact}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <span>Enviar</span>
              )}
            </button>
          </div>
        </Box>

        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            maxWidth: "28rem",
            // background: "var(--purple-1)",
            margin: {
              xs: "0 auto auto auto",
              sm: "0 auto auto auto",
              md: "auto",
            },
          }}
        ></Box>
      </Container>
    </CustomSection>
  );
};

export default Contato;
