//* React Imports
import { useState } from "react";

// icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { BubbleChartSharp } from "@mui/icons-material";
import { MdRecycling } from "react-icons/md";

// logo
import logo from "assets/static/logo/green_dark.png";

//* Components Imports
import AsideItem from "./AsideItem";

const Aside = () => {
  const [shrunk, setShrunk] = useState(false);

  const handleShrunk = () => {
    setShrunk(!shrunk);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: shrunk ? "7.2rem" : "26rem",
        backgroundColor: "#FFF",
        borderRight: "1px solid #E0E0E0",
        paddingTop: "1rem",
        transition: "0.3s ease-in-out",
        zIndex: 2
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0 1rem",
        }}
      >
        <img
          src={logo}
          alt="BlueenOpen Logo"
          style={{
            width: shrunk ? "0" : "7.6rem",
            display: "block",
            transition: "0.3s ease-in-out",
          }}
        />
        <ArrowForwardIosIcon
          sx={{
            right: "1rem",
            top: "1rem",
            cursor: "pointer",
            backgroundColor: "transparent",
            padding: "0.5rem",
            borderRadius: "50%",
            fontSize: "2.8rem",
            transform: shrunk ? "scale(1, 1)" : "scale(-1, 1)",
            transition: "0.3s ease-in-out",
            ":hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
          onClick={handleShrunk}
        />
      </div>

      <hr
        style={{
          width: "100%",
          border: "1px solid #E0E0E0",
          margin: 0,
          marginTop: "1rem",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem 0",
          width: "100%",
          gap: "0.4rem",
        }}
      >
        <AsideItem
          icon={MdRecycling}
          title="Solicitações de Coleta"
          {...{ shrunk }}
        >
          <AsideItem
            icon={BubbleChartSharp}
            title="Agendar Coleta"
            href="/pbo/dashboard/schedule-collect"
            {...{ shrunk }}
          />
          <AsideItem
            icon={BubbleChartSharp}
            title="Confirmar Coleta"
            href="/pbo/dashboard/confirmed-collect-solicitations"
            {...{ shrunk }}
          />
          <AsideItem
            icon={BubbleChartSharp}
            title="Solicitações Finalizadas"
            href="/pbo/dashboard/collect-solicitations"
            {...{ shrunk }}
          />
        </AsideItem>
        <AsideItem
          icon={LocalShippingIcon}
          title="Dados de Transporte"
          {...{ shrunk }}
        >
          <AsideItem
            icon={BubbleChartSharp}
            title="Transportadoras"
            href="/pbo/dashboard/carriers"
            {...{ shrunk }}
          />
          <AsideItem
            icon={BubbleChartSharp}
            title="Veículos"
            href="/pbo/dashboard/vehicles"
            {...{ shrunk }}
          />
          <AsideItem
            icon={BubbleChartSharp}
            title="Motoristas"
            href="/pbo/dashboard/drivers"
            {...{ shrunk }}
          />
        </AsideItem>

        <AsideItem
          icon={AssignmentIcon}
          title="Compliance"
          href="/pbo/dashboard/destinations/compliance"
          {...{ shrunk }}
        ></AsideItem>
      </div>
    </div>
  );
};

export default Aside;
