//* React imports
import React from "react";

//* Components imports
import UserTypes from "../UserTypesForms/UserTypes";

//* The user pick one type in there
function UserPicker({ formData, setFormData, state, setState }) {
  return (
    <div>
      <div className="form-group-row m-3">
        <div
          className="mt-3 row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {/* <div className="col-12 col-md-6">
            <UserTypes
              icon={
                "https://cdn-icons-png.flaticon.com/512/1104/1104060.png?w=740&t=st=1675693915~exp=1675694515~hmac=cf6bd4e2c96c9656a738cf0070451a7fa6efc0e06973ec677f791ed7055ebf61"
              }
              description="Estoque"
              formData={formData}
              setFormData={setFormData}
              state={state}
              setState={setState}
            />
          </div> */}
          <div className="col-12 col-md-12">
            <UserTypes
              icon={
                "https://cdn-icons-png.flaticon.com/512/1257/1257385.png?w=740&t=st=1675694128~exp=1675694728~hmac=cda1ca2bc475b22f2aaf3377b150efbcffcf0a6a093a3746209e95fa73f7ed4d"
              }
              description="Destino"
              formData={formData}
              setFormData={setFormData}
              state={state}
              setState={setState}
            />
          </div>
        {/* <div className="col-12 col-md-6">
            <UserTypes
              icon={
                "https://cdn-icons-png.flaticon.com/512/1255/1255767.png?w=740&t=st=1675693986~exp=1675694586~hmac=b8572b589f884047df69cbafa45f5a6de10c9b4c50225d4f99d6947a9626ccb7"
              }
              description="Transportadora"
              formData={formData}
              setFormData={setFormData}
              state={state}
              setState={setState}
            />
          </div>
          <div className="col-12 col-md-6">
            <UserTypes
              icon={
                "https://cdn-icons-png.flaticon.com/512/1202/1202352.png?w=740&t=st=1675694036~exp=1675694636~hmac=bc37fd42e5fa0b78d4a280c62dd876cedddf9c5e0bcf063cbb196e2293feb7f4"
              }
              description="Motorista Independente"
              formData={formData}
              setFormData={setFormData}
              state={state}
              setState={setState}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default UserPicker;
