//* React imports
import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

//* Utils imports
import { ErrorAlert } from "utils/utilities";
import { URL } from "variables";

//* Components imports
import AcceptedCollectSolicitations from "screen/Private/Dashboard/CollectSolicitation/ConfirmCollect/AcceptedCollectSolicitations";
import AccessDenied from "components/common/AccessDeniedPage/AccessDenied";
import Aside from "screen/Private/Dashboard/Aside/Aside";
import ConfirmSchedule from "screen/Private/Dashboard/CollectSolicitation/ConfirmCollect/ConfirmSchedule";
import DashboardHome from "screen/Private/Dashboard/DashboardHome";
import PendentCollectSolicitations from "screen/Private/Dashboard/CollectSolicitation/ScheduleCollect/PendentCollectSolicitations";
import ScheduleCollect from "screen/Private/Dashboard/CollectSolicitation/ScheduleCollect/ScheduleCollect";
import FinishedCollectSolicitations from "screen/Private/Dashboard/CollectSolicitation/FinishedCollect/FinishedCollectSolicitation";
import UnderConstruction from "components/common/UnderConstruction/UnderConstruction";
import ViewFinishedCollect from "screen/Private/Dashboard/CollectSolicitation/FinishedCollect/ViewFinishedCollect";
import CarriersList from "screen/Private/Dashboard/TransportData/CarriersList/CarriersList";
import ViewCarrier from "screen/Private/Dashboard/TransportData/CarriersList/ViewCarrier";
import VehiclesList from "screen/Private/Dashboard/TransportData/VehiclesList/VehiclesList";
import ViewVehicle from "screen/Private/Dashboard/TransportData/VehiclesList/ViewVehicle";
import ViewDriver from "screen/Private/Dashboard/TransportData/DriversList/ViewDriver";
import DriversList from "screen/Private/Dashboard/TransportData/DriversList/DriversList";
import AddDriver from "screen/Private/Dashboard/TransportData/DriversList/AddDriver";
import AddVehicle from "screen/Private/Dashboard/TransportData/VehiclesList/AddVehicle";
import AddCarrier from "screen/Private/Dashboard/TransportData/CarriersList/AddCarrier";
import ComplianceList from "screen/Private/Dashboard/Compliance/ComplianceList";
import ViewCompliance from "screen/Private/Dashboard/Compliance/ViewCompliance";
import EditCompliance from "screen/Private/Dashboard/Compliance/EditCompliance";
import AddCompliance from "screen/Private/Dashboard/Compliance/AddCompliance";

const DashboardRoutes = () => {
  const token = useSelector((state) => state.AppReducer.token);

  const [state, setState] = useState({
    loading: false,
    found: true,
  });

  const user = useSelector((store) => store.AppReducer.user);

  useEffect(() => {
    setState({ ...state, loading: true });
    let token = localStorage.getItem("token");

    fetch(`${URL}api/get_enterprise`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        enterprise_id: user.enterprise_id,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setState({ ...state, loading: false });
          } else {
            let foundUser = resp.found;
            setState({
              ...state,
              loading: false,
            });
            if (foundUser === false) {
              setState({ ...state, found: false });
            }
          }
        } catch (err) {
          setState({ ...state, loading: true });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setState({ ...state, loading: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }, []);

  return (
    <>
      {!state.found && (
        <div>
          <AccessDenied
            aboutMsg={`Para acessar essa página, finalize seu cadastro na aba `}
            redirectMsg={" 'Dados da empresa'"}
            redirectLink={"/pbo/user/enterprise"}
          />
        </div>
      )}
      {state.found && (
        <div style={{ display: "flex" }}>
          <Aside />
          <div style={{ width: "100%", marginTop: "2.5vh" }}>
            <Routes>
              <Route path={""} element={<DashboardHome />} />

              <Route
                path={"schedule-collect"}
                element={<PendentCollectSolicitations />}
              />
              <Route
                path={"schedule-collect/view/:id"}
                element={<ScheduleCollect />}
              />

              <Route
                path={"confirmed-collect-solicitations"}
                element={<AcceptedCollectSolicitations />}
              />
              <Route
                path={"confirmed-collect-solicitations/view/:id"}
                element={<ConfirmSchedule />}
              />

              <Route
                path={"collect-solicitations"}
                element={<FinishedCollectSolicitations />}
              />
              <Route
                path={"collect-solicitations/view/:id"}
                element={<ViewFinishedCollect />}
              />

              <Route path={"carriers"} element={<CarriersList />} />
              <Route path={"carriers/add"} element={<AddCarrier />} />
              <Route path={"carriers/view/:id"} element={<ViewCarrier />} />

              <Route path={"vehicles"} element={<VehiclesList />} />
              <Route path={"vehicles/add"} element={<AddVehicle />} />
              <Route path={"vehicles/view/:id"} element={<ViewVehicle />} />

              <Route path={"drivers"} element={<DriversList />} />
              <Route path={"drivers/add"} element={<AddDriver />} />
              <Route path={"drivers/view/:id"} element={<ViewDriver />} />

              <Route path={":owner/compliance"} element={<ComplianceList />} />
              <Route path={":owner/compliance/create"} element={<AddCompliance />} />
              <Route path={":owner/compliance/view/:id"} element={<ViewCompliance />} />
              <Route path={":owner/compliance/edit/:id"} element={<EditCompliance />} />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardRoutes;
