//* React imports
import React from "react";
import { Link } from "react-router-dom";

//* Style imports
import access_denied from "assets/static/access_denied.svg";

function AccessDenied({ aboutMsg, redirectMsg, redirectLink }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "65vh",
        width: "100%",
      }}
    >
      <h2 className="card-title text-muted fw-bold" style={{marginTop: '10rem'}}>ACESSO NEGADO</h2>
      <img
        src={access_denied}
        alt="Acesso negado"
        style={{
          height: "100%",
          maxHeight: "60%",
          objectFit: "contain",
        }}
      />
      <p style={{textAlign: 'center'}}>
      {aboutMsg}
      </p>
      <Link to={redirectLink}>
        <span> {redirectMsg}</span>
      </Link>
    </div>
  );
}

export default AccessDenied;
