//* React Imports
import React from "react";

//* Style Imports
import { Button, IconButton, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

//* Utils Imports
import Theme from "utils/theme";
import UnderConstruction from "../UnderConstruction/UnderConstruction";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  // top: '50%',
  left: "25%",
  transform: "translate(0, 0, 0, 50%)",
  width: "50%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,
  // display: 'flex',
  // flexDirection: 'column',
  overflowY: "auto",
  "@media(max-width: 1000px)": {
    width: "90%",
    left: "5%",
  },
};

const InfoModal = (props) => {
  const [open, setOpen] = React.useState(false);

  const [height, setHeight] = React.useState(0);
  const [rerender, setRerender] = React.useState(0);
  const content = React.useRef();
  const modalRef = React.useRef();

  React.useLayoutEffect(() => {
    if (open) {
      if (content.current !== undefined && content.current !== null)
        setHeight(content.current.clientHeight);
      else setRerender((item) => item + 1);
      content.current = document.getElementById("ancor");
    }
  }, [open, rerender, content.current?.clientHeight]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <button onClick={handleOpen} className="text-primary">
        {props.modalData.label}
      </button>
      <Modal
        ref={modalRef}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={Theme}>
          <Box sx={style}>
            {
              <>
                <div id="ancor" ref={content}>
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                  {/* <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    <div className="text-center">
                      <h3
                        className="mt-0 header-title fw-bolder"
                        style={{ fontSize: "1.75rem", color: "black" }}
                      >
                        {props.modalData.title}
                      </h3>
                      <p style={{ fontSize: "1rem", color: "black" }}>
                        {props.modalData.subtitle}
                      </p>
                    </div>
                  </Typography> */}
                  {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className="modal-body mx-3">
                      <p>{props.modalData.introText}</p>
                      <h4 className="modal-title fw-bold mt-3">
                        {props.modalData.section1.title}
                      </h4>
                      <p>{props.modalData.section1.text}</p>
                      <h4 className="modal-title fw-bold mt-3">
                        {props.modalData.section2.title}
                      </h4>
                      <p>{props.modalData.section2.text}</p>
                      <h4 className="modal-title fw-bold mt-3">
                        {props.modalData.section3.title}
                      </h4>
                      <p>{props.modalData.section3.text}</p>
                      <h4 className="modal-title fw-bold mt-3">
                        {props.modalData.section4.title}
                      </h4>
                      <p>{props.modalData.section4.text}</p>
                    </div>
                  </Typography> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "75vh",
                    }}
                  >
                    <UnderConstruction />
                  </div>
                </div>
                {/* {
                  <div
                    className="d-flex align-items-end"
                    style={
                      height > modalRef?.current?.clientHeight * 0.8
                        ? {}
                        : { height: `calc(100% - ${height}px)` }
                    }
                  >
                    <div className="ms-auto">
                      <Button
                        variant="contained"
                        disableElevation
                        size="large"
                        color="info"
                        onClick={handleClose}
                      >
                        <span>{props.modalData.confirmationMessage}</span>
                      </Button>
                    </div>
                  </div>
                } */}
              </>
            }
          </Box>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default InfoModal;
