import React from 'react'

const LabeledDivider = ({ label }) => {
  return (
    <div className="position-relative mt-5">
      <div className='position-absolute top-50 start-50 translate-middle w-100 rounded' style={{ borderBottom: '1px solid #333' }} />
      <p className="position-absolute top-50 start-50 translate-middle px-2" style={{ backgroundColor: '#f7f7ff' }}>{label}</p>
    </div>
  )
}

export default LabeledDivider