//* React imports
import React from "react";

//* Style imports
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

function ChangePassword({ formData, setFormData }) {
  return (
    <div>
      <div className="form-group-row">
        <TextField
          label="Nova senha"
          variant="outlined"
          fullWidth
          required
          type={!formData.viewPassword ? "password" : "text"}
          error={formData.newPassword.error}
          helperText={formData.newPassword.msg}
          value={formData.newPassword.value}
          onChange={(e) => {
            setFormData({
              ...formData,
              newPassword: { value: e.target.value, error: false },
            });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setFormData({
                      ...formData,
                      viewPassword: !formData.viewPassword,
                    })
                  }
                  edge="end"
                >
                  {formData.viewPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="mt-4">
        <TextField
          label="Confirmar nova senha"
          variant="outlined"
          fullWidth
          required
          type={!formData.viewPasswordConfirmed ? "password" : "text"}
          error={formData.newPasswordConfirmation.error}
          helperText={formData.newPasswordConfirmation.msg}
          value={formData.newPasswordConfirmation.value}
          onChange={(e) => {
            setFormData({
              ...formData,
              newPasswordConfirmation: { value: e.target.value, error: false },
            });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setFormData({
                      ...formData,
                      viewPasswordConfirmed: !formData.viewPasswordConfirmed,
                    })
                  }
                  edge="end"
                >
                  {formData.viewPasswordConfirmed ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
}

export default ChangePassword;
