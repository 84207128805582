//* React Imports
import React, { useEffect, useRef, useState } from "react";

//* Style Imports
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";

//* Utils Imports
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { ErrorAlert, SuccessAlert, WarningAlert } from "utils/utilities";
import validator from "validator";
import { useSelector } from "react-redux";
import { URL } from "variables";

function MyProfile() {
  const [state, setState] = useState({
    loading: true,
    loadingSave: false,

    viewOldPassword: false,
    viewNewPassword: false,
    viewNewPasswordConfirmation: false,
  });

  const [formData, setFormData] = useState({
    name: { value: "", error: false, msgError: "" },
    email: { value: "", error: false, msgError: "" },

    oldPassword: { value: "", error: false, msgError: "" },
    newPassword: { value: "", error: false, msgError: "" },
    newPasswordConfirmation: { value: "", error: false, msgError: "" },
  });

  const user = useSelector((store) => store.AppReducer.user);
  const token = localStorage.getItem("token");
  // const shared_worker = useRef();

  // function init_shared_worker() {
  //   let shared_worker = new SharedWorker("/chat_geral.js");
  //   console.log("achou", shared_worker);
  //   shared_worker.port.onmessage = (e) => {
  //     console.log(e.data);
  //     if (e.data.status == "connect") {
  //       shared_worker.port.postMessage({
  //         status: "connect",
  //         user: user,
  //         token: token,
  //         URL: URL,
  //       });
  //     } else if (e.data.status == "connected") {
  //     } else if (e.data.status == "MessageReceiveUser") {
  //     }
  //   };
  //   shared_worker.port.start();
  // }

  useEffect(() => {
    fetch(`${URL}api/get_user/${user.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        setState({
          ...state,
          loading: false,
        });
        setFormData({
          ...formData,
          name: { value: resp.user.name },
          email: { value: resp.user.email },
        });

        // init_shared_worker();
      })
      .catch((err) => console.log(err));
  }, []);

  function handleSubmit() {
    let hasError = false;
    let variables = ["name", "email"];
    let data = { ...formData };
    variables.forEach((item) => {
      if (!data[item].value) {
        data[item].error = true;
        data[item].msgError = "Campo em Branco";
        hasError = true;
      }
      setFormData(data);
      return;
    });
    if (hasError) return;

    if (!validator.isEmail(formData.email.value)) {
      setFormData({
        ...formData,
        email: {
          value: formData.email.value,
          error: true,
          msgError: "Digite um formato de e-mail válido",
        },
      });
      return;
    }

    if (
      formData.oldPassword.value &&
      (!formData.newPassword.value || !formData.newPasswordConfirmation.value)
    ) {
      setFormData({
        ...formData,
        newPassword: {
          value: formData.newPassword.value,
          error: true,
          msgError: "Preencha os campos igualmente",
        },
        newPasswordConfirmation: {
          value: formData.newPasswordConfirmation.value,
          error: true,
          msgError: "Preencha os campos igualmente",
        },
      });
      return;
    }

    fetch(`${URL}api/update_user`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user.id,
        name: formData.name.value,
        email: formData.email.value,
        old_password: formData.oldPassword.value,
        password: formData.newPassword.value,
        password_confirmation: formData.newPasswordConfirmation.value,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setState({ ...state, loadingSave: false });
          } else {
            SuccessAlert("Perfil atualizado com sucesso");
            setFormData({
              ...formData,
              oldPassword: {
                value: "",
                error: false,
                msgError: "",
              },
              newPassword: {
                value: "",
                error: false,
                msgError: "",
              },
              newPasswordConfirmation: {
                value: "",
                error: false,
                msgError: "",
              },
            });
            setTimeout(() => {
              setState({
                ...state,
                loadingSave: false,
              });
            }, 3000);
          }
        } catch (err) {
          setState({ ...state, loadingSave: true });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setState({ ...state, loadingSave: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div
          style={{
            width: "50%",
            margin: "0 auto",
          }}
        >
          <header>
            <h1 className="card-title text-body">
              <b>Editar Perfil</b>
            </h1>
            <span className="text-body">
              Edite detalhes básicos do seu perfil.
            </span>
          </header>
          <hr />
          <main className="mt-4">
            <div className="form-group-row mt-4">
              <TextField
                label="Nome Completo"
                variant="outlined"
                fullWidth
                required
                error={formData.name.error}
                helperText={formData.name.msgError}
                value={formData.name.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: { value: e.target.value, error: false },
                  });
                }}
              />
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="E-mail"
                variant="outlined"
                fullWidth
                required
                error={formData.email.error}
                helperText={formData.email.msgError}
                value={formData.email.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    email: { value: e.target.value, error: false },
                  });
                }}
              />
            </div>
            <hr />
            <div className="form-group-row mt-4">
              <TextField
                label="Senha Atual"
                variant="outlined"
                fullWidth
                type={!state.viewOldPassword ? "password" : "text"}
                error={formData.oldPassword.error}
                helperText={formData.oldPassword.msgError}
                value={formData.oldPassword.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    oldPassword: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setState({
                            ...state,
                            viewOldPassword: !state.viewOldPassword,
                          })
                        }
                        edge="end"
                      >
                        {state.viewOldPassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group-row mt-5">
              <TextField
                label="Nova Senha"
                variant="outlined"
                fullWidth
                required={formData.oldPassword.value ? true : false}
                disabled={!formData.oldPassword.value}
                type={!state.viewNewPassword ? "password" : "text"}
                error={formData.newPassword.error}
                helperText={formData.newPassword.msgError}
                value={formData.newPassword.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    newPassword: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setState({
                            ...state,
                            viewNewPassword: !state.viewNewPassword,
                          })
                        }
                        edge="end"
                      >
                        {state.viewNewPassword ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="Confirmar Nova Senha"
                variant="outlined"
                fullWidth
                required={formData.oldPassword.value ? true : false}
                disabled={!formData.oldPassword.value}
                type={!state.viewNewPasswordConfirmation ? "password" : "text"}
                error={formData.newPasswordConfirmation.error}
                helperText={formData.newPasswordConfirmation.msgError}
                value={formData.newPasswordConfirmation.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    newPasswordConfirmation: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setState({
                            ...state,
                            viewNewPasswordConfirmation:
                              !state.viewNewPasswordConfirmation,
                          })
                        }
                        edge="end"
                      >
                        {state.viewNewPasswordConfirmation ? (
                          <VisibilityOffOutlined />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Button
              variant="contained"
              disableElevation
              size="large"
              color="primary"
              className="col-12 my-5"
              disabled={state.loadingSave}
              onClick={() => handleSubmit()}
            >
              {state.loadingSave && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {!state.loadingSave && <span>Salvar</span>}
            </Button>
          </main>
        </div>
      )}
    </div>
  );
}

export default MyProfile;
