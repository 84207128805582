//* React imports
import React from "react";

//* Style imports
import { Typography, Box } from "@mui/material";

export default function SideSection() {
  return (
    <Box sx={{
      width: "100%",
      maxWidth: "45rem",
      minHeight: "calc(100% - 5.8rem)",
      marginTop: "5.8rem",
      background: "linear-gradient(90deg, var(--blue-1)  0%, var(--blue-2) 100%)",
      display: {
        xs: "none",
        sm: "none",
        md: "flex",
      },
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Typography sx={{
        color: "white",
        fontSize: {
          md: "5rem",
          lg: "6rem",
        },
        fontWeight: "bold",
        maxWidth: "80%",
      }}>
        OTIMIZE SEU TEMPO E SEU LIXO
      </Typography>
    </Box>
  );
}
