//* React imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

//* Style imports
import {
  Alert,
  Button,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import { DeleteOutline, Reply } from "@mui/icons-material";

//* Utils imports
import { URL } from "variables";
import validator from "validator";
import Swal from "sweetalert2";

//* Components imports
import EnterpriseData from "screen/Auth/SignUp/Steps/EnterpriseData";
import InfoModal from "components/common/InformativeModal/InfoModal";
import { ErrorAlert, SuccessAlert, WarningAlert } from "utils/utilities";
import SignupModal from "components/common/SignupModals/ValidateModal";
import { maskTelefone } from "utils/masks";

function ViewCarrier() {
  const [state, setState] = useState({
    loading: true,
    loadingSave: false,
    imageHover: false,

    carrierData: [],
  });

  const [formData, setFormData] = useState({
    companyName: { value: "", error: false, msgError: "" },
    commercialName: { value: "", error: false, msgError: "" },
    responsibleName: { value: "", error: false, msgError: "" },
    responsibleEmail: { value: "", error: false, msgError: "" },

    image: "",
    imgPath: "",
    vehicles: [],
    drivers: [],
  });

  //* Enterprise data
  const [enterpriseData, setEnterpriseData] = useState({
    cnpj: { value: "", error: false, msgError: "" },
    cep: { value: "", error: false, msgError: "" },
    city: { value: "", error: false, msgError: "", disabled: false },
    state: { value: "", error: false, msgError: "", disabled: false },
    street: { value: "", error: false, msgError: "", disabled: false },
    nbhd: { value: "", error: false, msgError: "", disabled: false },
    number: { value: "", error: false, msgError: "" },
    comp: { value: "", error: false, msgError: "" },
    phone: { value: "", error: false, msgError: "" },
  });

  //Setting variables to fetches
  const token = localStorage.getItem("token");

  const params = useParams();
  let carrier_id = params.id;

  let history = useNavigate();

  useEffect(() => {
    fetch(`${URL}api/get_carrier/${carrier_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        console.log("resp", resp);
        setState({ ...state, loading: false, carrierData: resp.carrier });
        setFormData({
          ...formData,
          companyName: { value: resp.carrier.company_name },
          commercialName: { value: resp.carrier.commercial_name },
          responsibleName: { value: resp.carrier.user.name },
          responsibleEmail: { value: resp.carrier.user.email },
          imgPath: resp.carrier.enterprise.image
            ? URL + resp.carrier.enterprise.image
            : "",
          vehicles: resp.carrier.vehicles,
          drivers: resp.carrier.drivers,
        });
        setEnterpriseData({
          ...enterpriseData,
          cnpj: { value: resp.carrier.enterprise.cnpj },
          cep: { value: resp.carrier.enterprise.cep || "" },
          city: { value: resp.carrier.enterprise.city || "" },
          state: { value: resp.carrier.enterprise.state || "" },
          street: { value: resp.carrier.enterprise.street || "" },
          nbhd: { value: resp.carrier.enterprise.nbhd || "" },
          number: { value: resp.carrier.enterprise.number || "" },
          comp: { value: resp.carrier.enterprise.comp || "" },
          phone: { value: resp.carrier.enterprise.phone || "" },
        });
      })
      .catch((err) => console.log(err));
  }, []);

  const handleImageChange = (event) => {
    let selectedFile = event.target.files[0];
    let reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setState({
          ...state,
          imageHover: false,
        });
        setFormData({
          ...formData,
          image: selectedFile,
          imgPath: reader.result,
        });
      }
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  function handleCarrier(value) {
    fetch(`${URL}api/get_carrier/${carrier_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        setState({ ...state, loading: false });
        setFormData({
          ...formData,
          vehicles: resp.carrier.vehicles,
          drivers: resp.carrier.drivers,
        });
      })
      .catch((err) => console.log(err));
  }

  function deleteTransport(id, label) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Essa ação é irreversível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, deletar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${URL}api/delete_${label}/${id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then(async (responseLog) => {
            let resp = await responseLog.json();
            SuccessAlert("Deletado com sucesso!");
            handleCarrier();
          })
          .catch((err) => ErrorAlert(err));
      }
    });
  }

  function handleSubmit() {
    let hasError = false;

    let responsibleVariables = [
      "responsibleName",
      "responsibleEmail",
      "commercialName",
      "companyName",
    ];
    let responsibleValidation = { ...formData };
    responsibleVariables.forEach((item) => {
      if (!responsibleValidation[item].value) {
        responsibleValidation[item].error = true;
        responsibleValidation[item].msgError = "Campo em Branco";
        hasError = true;
      }
      setFormData(responsibleValidation);
    });

    let enterpriseVariables = [
      "cnpj",
      "cep",
      "city",
      "state",
      "number",
      "street",
      "nbhd",
      "phone",
    ];
    let enterpriseValidation = { ...enterpriseData };
    enterpriseVariables.forEach((item) => {
      if (!enterpriseValidation[item].value) {
        enterpriseValidation[item].error = true;
        enterpriseValidation[item].msgError = "Campo em Branco";
        hasError = true;
      }
      setEnterpriseData(enterpriseValidation);
    });

    if (hasError) return;

    if (!validator.isEmail(formData.responsibleEmail.value)) {
      setFormData({
        ...formData,
        responsibleEmail: {
          value: formData.responsibleEmail.value,
          error: true,
          msgError: "Digite um formato de e-mail válido",
        },
      });
      return;
    }

    if (enterpriseData.phone.value.length < 15) {
      setEnterpriseData({
        ...enterpriseData,
        phone: {
          value: enterpriseData.phone.value,
          error: true,
          msgError: "Preencha o campo corretamente",
        },
      });
      return;
    }
    if (enterpriseData.cnpj.value.length < 18) {
      setEnterpriseData({
        ...enterpriseData,
        cnpj: {
          value: enterpriseData.cnpj.value,
          error: true,
          msgError: "Preencha o campo corretamente",
        },
      });
      return;
    }
    if (enterpriseData.cep.value.length < 8) {
      setEnterpriseData({
        ...enterpriseData,
        cep: {
          value: enterpriseData.cep.value,
          error: true,
          msgError: "Preencha o campo corretamente",
        },
      });
      return;
    }

    setState({ ...state, loadingSave: true });

    let form = new FormData();
    form.append("image", formData.image);
    form.append("name", formData.responsibleName.value);
    form.append("email", formData.responsibleEmail.value);
    form.append("company_name", formData.companyName.value);
    form.append("commercial_name", formData.commercialName.value);

    form.append("cnpj", enterpriseData.cnpj.value);
    form.append("cep", enterpriseData.cep.value);
    form.append("city", enterpriseData.city.value);
    form.append("state", enterpriseData.state.value);
    form.append("street", enterpriseData.street.value);
    form.append("number", enterpriseData.number.value);
    form.append("nbhd", enterpriseData.nbhd.value);
    form.append("comp", enterpriseData.comp.value);
    form.append("phone", enterpriseData.phone.value);

    fetch(`${URL}api/update_carrier/${carrier_id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: form,
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.status) {
            setState({ ...state, loadingSave: false });
            SuccessAlert("Transportadora atualizada com sucesso!");
          }
        } catch (err) {
          console.log(err);
          setState({ ...state, loadingSave: false });
          ErrorAlert("Erro ao pegar resposta do servidor.");
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loadingSave: false });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div>
          <header>
            <h1 className="card-title text-body">
              <b>Ver Transportadora</b>
            </h1>
            <span className="text-body">
              Veja com detalhes e edite os dados da transportadora.
            </span>
          </header>
          <hr />
          <main className="mt-4">
            <div className="form-group-row d-flex align-items-center justify-content-center">
              <div className="mb-3 text-center">
                <div
                  style={{
                    width: "130px",
                    height: "130px",
                    border: "1px solid rgba(60, 0, 150, 0.8)",
                    borderRadius: "50%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                  onMouseEnter={() => setState({ ...state, imageHover: true })}
                  onMouseLeave={() => setState({ ...state, imageHover: false })}
                >
                  <label
                    htmlFor="profile-image-upload"
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      textAlign: "center",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      opacity: state.imageHover ? "0.75" : "1",
                      transition: "opacity 0.3s ease",
                    }}
                  >
                    <input
                      type="file"
                      id="profile-image-upload"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    {formData.imgPath ? (
                      <img
                        src={formData.imgPath}
                        alt="Foto de Perfil"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          display: "block",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                          background: "#f2f2f2",
                          color: "#888",
                          fontSize: "14px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      >
                        Selecionar Imagem
                      </span>
                    )}
                  </label>
                </div>
                {formData.imgPath && (
                  <Tooltip title="Remover imagem">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setState({
                          ...state,
                          imageHover: false,
                        });
                        setFormData({
                          ...formData,
                          image: "",
                          imgPath: "",
                        });
                      }}
                    >
                      <DeleteOutline style={{ color: "#f8333c" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="form-group-row mt-3">
              <TextField
                label="Razão Social"
                variant="outlined"
                fullWidth
                error={formData.companyName.error}
                helperText={formData.companyName.msgError}
                value={formData.companyName.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companyName: { value: e.target.value, error: false },
                  });
                }}
              />
            </div>
            <div className="form-group-row mt-3">
              <TextField
                label="Nome Fantasia"
                variant="outlined"
                fullWidth
                error={formData.commercialName.error}
                helperText={formData.commercialName.msgError}
                value={formData.commercialName.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    commercialName: { value: e.target.value, error: false },
                  });
                }}
              />
            </div>
            <div className="form-group-row mt-3">
              <TextField
                label="Telefone"
                variant="outlined"
                fullWidth
                required
                error={enterpriseData.phone.error}
                helperText={enterpriseData.phone.msgError}
                value={enterpriseData.phone.value}
                onChange={(e) => {
                  setEnterpriseData({
                    ...enterpriseData,
                    phone: {
                      value: maskTelefone(e.target.value),
                      error: false,
                    },
                  });
                }}
              />
            </div>
            <div className="form-group mt-3 row">
              <div className=" col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <TextField
                      label="Nome Responsável"
                      variant="outlined"
                      fullWidth
                      error={formData.responsibleName.error}
                      helperText={formData.responsibleName.msgError}
                      value={formData.responsibleName.value}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          responsibleName: {
                            value: e.target.value,
                            error: false,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12 mt-md-0 mt-3">
                    <TextField
                      label="E-mail Responsável"
                      variant="outlined"
                      fullWidth
                      error={formData.responsibleEmail.error}
                      helperText={formData.responsibleEmail.msgError}
                      value={formData.responsibleEmail.value}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          responsibleEmail: {
                            value: e.target.value,
                            error: false,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <EnterpriseData
              formData={enterpriseData}
              setFormData={setEnterpriseData}
            />
            <hr />
            <div id="vehicles">
              <h4>
                <strong>Veículos</strong>
              </h4>
              <div className="row d-flex justify-content-between">
                <div className="col-12 col-lg-4 col-md-6">
                  <span className="text-body">
                    Todos os veículos vinculados à transportadora.
                  </span>
                </div>
                <div className="mt-3 mt-md-0 col-12 col-md-6 d-flex justify-content-end">
                  <div>
                    <SignupModal
                      type={"VehicleQuickSignup"}
                      carriers={[state.carrierData]}
                      carrierId={state.carrierData.id}
                      handleCarrier={handleCarrier}
                    />
                  </div>
                </div>
              </div>
              {formData.vehicles.length > 0 && (
                <div className="table-responive text-center mt-4">
                  <table className="table table-hover">
                    <thead className="thead-blueen">
                      <tr>
                        <th scope="col">Placa</th>
                        <th scope="col">Modelo</th>
                        <th scope="col">Marca</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Ações</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-blueen">
                      {formData.vehicles.map((item, id) => (
                        <tr key={id}>
                          <td className="align-middle">{item.license_plate}</td>
                          <td className="align-middle">
                            {item.vehicle_model.model}
                          </td>
                          <td className="align-middle">
                            {item.vehicle_model.brand}
                          </td>
                          <td className="align-middle">
                            {item.vehicle_type.type}
                          </td>
                          <td className="align-middle">
                            <Tooltip title="Excluir">
                              <IconButton
                                onClick={() =>
                                  deleteTransport(item.id, "vehicle")
                                }
                              >
                                <DeleteOutline />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {formData.vehicles.length == 0 && (
                <Alert className="my-3" severity="info">
                  Nenhum veículo foi encontrado.
                </Alert>
              )}
            </div>
            <div id="drivers">
              <h4>
                <strong>Motoristas</strong>
              </h4>
              <div className="row d-flex justify-content-between">
                <div className="col-12 col-lg-4 col-md-6">
                  <span className="text-body">
                    Todos os motoristas vinculados à transportadora.
                  </span>
                </div>
                <div className="mt-3 mt-md-0 col-12 col-md-6 d-flex justify-content-end">
                  <div>
                    <SignupModal
                      type={"DriverQuickSignup"}
                      carriers={[state.carrierData]}
                      carrierId={state.carrierData.id}
                      handleCarrier={handleCarrier}
                    />
                  </div>
                </div>
              </div>
              {formData.drivers.length > 0 && (
                <div className="table-responive text-center mt-4">
                  <table className="table table-hover">
                    <thead className="thead-blueen">
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">CNH</th>
                        <th scope="col">Ações</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-blueen">
                      {formData.drivers.map((item, id) => (
                        <tr key={id}>
                          <td className="align-middle">{item.name}</td>
                          <td className="align-middle">{item.cnh_number}</td>
                          <td className="align-middle">
                            <Tooltip title="Excluir">
                              <IconButton
                                onClick={() =>
                                  deleteTransport(item.id, "driver")
                                }
                              >
                                <DeleteOutline />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {formData.drivers.length == 0 && (
                <Alert className="my-3" severity="info">
                  Nenhum motorista foi encontrado.
                </Alert>
              )}
            </div>
            {/* <hr />
            <div id="sys_acc" className="container d-flex">
              <div className="row">
                <h5>
                  <strong>Acesso ao Sistema</strong>
                </h5>
              </div>
              <div className="px-4 row">
                <div className="form-check form-switch">
                  <input
                    disabled
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                      console.log(e);
                    }}
                  />
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="d-flex align-items-center justify-content-between my-5">
                <Link to={"/pbo/dashboard/carriers"}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    color="error"
                    disabled={state.loadingSave}
                    startIcon={<Reply />}
                  >
                    <span>Voltar</span>
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                  disabled={state.loadingSave}
                  onClick={() => handleSubmit()}
                >
                  {state.loadingSave && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!state.loadingSave && <span>Salvar</span>}
                </Button>
              </div>
              <hr />
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default ViewCarrier;
