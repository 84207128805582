//* React imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

//* Style imports
import { Button } from "@mui/material";
import { Reply } from "@mui/icons-material";

//* Utils imports
import { URL } from "variables";
import { ErrorAlert, handleStatus } from "utils/utilities";
import { maskMoneyV2, maskNumberBr } from "utils/masks";

function ViewFinishedCollect() {
  const [state, setState] = useState({
    loading: true,

    //Collect Data
    collectStatus: "",
    createdDate: "",
    appointmentDate: "",
    pickupDate: "",
    receiptDate: "",
    finishedDate: "",
    totalWeight: "",
    totalVolume: "",
    income: "",
    expend: "",
    balance: "",
    carrier: "",
    vehicle: "",
    driver: "",
    invoiceKey: "",
    invoiceNumber: "",
    invoiceSeries: "",
    wasteRejects: [],
  });

  //Setting variables to fetches
  const token = localStorage.getItem("token");

  const params = useParams();
  let solicitation_id = params.id;

  let history = useNavigate();

  useEffect(() => {
    fetch(`${URL}api/get_open_solicitation/${solicitation_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        console.log(resp);
        setState({
          ...state,
          loading: false,

          collectStatus: resp.collect_solicitation.status,

          createdDate: resp.collect_solicitation.created_at
            ? resp.collect_solicitation.created_at.replace(
                /(\d*)-(\d*)-(\d*).*/,
                "$3/$2/$1"
              )
            : "Data não informada",
          appointmentDate: resp.collect_solicitation.appointment_date
            ? resp.collect_solicitation.appointment_date.replace(
                /(\d*)-(\d*)-(\d*).*/,
                "$3/$2/$1"
              )
            : "Data não informada",
          pickupDate: resp.collect_solicitation.pickup_date
            ? resp.collect_solicitation.pickup_date.replace(
                /(\d*)-(\d*)-(\d*).*/,
                "$3/$2/$1"
              )
            : "Data não informada",
          receiptDate: resp.collect_solicitation.receipt_date
            ? resp.collect_solicitation.receipt_date.replace(
                /(\d*)-(\d*)-(\d*).*/,
                "$3/$2/$1"
              )
            : "Data não informada",
          finishedDate: resp.collect_solicitation.finished_date
            ? resp.collect_solicitation.finished_date.replace(
                /(\d*)-(\d*)-(\d*).*/,
                "$3/$2/$1"
              )
            : "Data não informada",

          totalWeight: resp.collect_solicitation.total_weight,
          totalVolume: resp.collect_solicitation.total_volume,
          income: resp.collect_solicitation.income,
          expend: resp.collect_solicitation.expend,
          balance: resp.collect_solicitation.balance,

          invoiceKey: resp.collect_solicitation.invoice_key || "Não informada",
          invoiceNumber:
            resp.collect_solicitation.invoice_number || "Não informado",
          invoiceSeries:
            resp.collect_solicitation.invoice_series || "Não informada",

          carrier:
            resp.collect_solicitation?.middleware_transport?.transportadora
              ?.company_name || "Transportadora não informada",
          vehicle:
            resp.collect_solicitation?.middleware_transport?.veiculo?.modelo ||
            "Veículo não informado",
          driver:
            resp.collect_solicitation?.middleware_transport?.motorista?.name ||
            "Motorista não informado",

          wasteRejects: resp.collect_solicitation?.waste_rejects,
        });
      })
      .catch((err) => {
        setState({ ...state, loading: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }, []);

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div>
          <header>
            <h1 className="card-title text-body">
              <b>Coleta Finalizada</b>
            </h1>
            {/* <span className="text-body">LOREM IPSUM</span> */}
          </header>
          <hr />
          <main className="mt-4">
            <div className="row">
              <div id="waste_rejects">
                <h4>
                  <strong>Dados dos Resíduos e Rejeitos</strong>
                </h4>
                <div className="table-responive text-center mt-4">
                  <table className="table table-hover">
                    <thead className="thead-blueen">
                      <tr>
                        <th scope="col">Material</th>
                        <th scope="col">Peso</th>
                        <th scope="col">Volume</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-blueen">
                      {state.wasteRejects.map((item, id) => (
                        <tr key={id}>
                          <td className="align-middle">
                            {item.waste_reject.name}
                          </td>
                          <td className="align-middle">
                            {maskNumberBr(item.weight_kg)} kg
                          </td>
                          <td className="align-middle">
                            {maskNumberBr(item.volume_lt)} L
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
              <div id="despacho" className="col-12">
                <h4>
                  <strong>Dados do Despacho</strong>
                </h4>
                <div id="situation" className="mt-4">
                  <div className="form-group row">
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Status:
                      </label>
                      {state.collectStatus}
                      {handleStatus(state.collectStatus)}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Data da criação:
                      </label>
                      {state.createdDate}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Data de agendamento:
                      </label>
                      {state.appointmentDate}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Data de coleta:
                      </label>
                      {state.pickupDate}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Data de recebimento:
                      </label>
                      {state.receiptDate}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Data de finalização:
                      </label>
                      {state.finishedDate}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Peso total:
                      </label>
                      {maskNumberBr(state.totalWeight)} kg
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Volume total:
                      </label>
                      {maskNumberBr(state.totalVolume)} L
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Receita:
                      </label>
                      {maskMoneyV2(state.income)}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Despesa:
                      </label>
                      {maskMoneyV2(state.expend)}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Saldo:
                      </label>
                      {maskMoneyV2(state.balance)}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Transportadora:
                      </label>
                      {state.carrier}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Veículo:
                      </label>
                      {state.vehicle}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Motorista:
                      </label>
                      {state.driver}
                    </div>
                  </div>
                </div>
                <hr />
                <div id="fiscal">
                  <h4>
                    <strong>Dados Fiscais</strong>
                  </h4>
                  <div className="form-group row">
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Chave da NF-e:
                      </label>
                      {state.invoiceKey}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Número da NF-e:
                      </label>
                      {state.invoiceNumber}
                    </div>
                    <div className="col-12">
                      <label className="col-4 col-form-label fw-bold">
                        Série da NF-e:
                      </label>
                      {state.invoiceSeries}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between my-5">
                <Link to={"/pbo/dashboard/collect-solicitations"}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    color="error"
                    disabled={state.loadingSave}
                    startIcon={<Reply />}
                  >
                    <span>Voltar</span>
                  </Button>
                </Link>
                <></>
              </div>
              <hr />
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default ViewFinishedCollect;
