//* React imports
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//* Style imports
import { Button, Step, StepLabel, Stepper } from "@mui/material";
import { Reply } from "@mui/icons-material";

//* Utils imports
import { ErrorAlert, SuccessAlert } from "utils/utilities";
import { login } from "../../../../redux/AppActions";
import { URL } from "variables";

//* Components imports
import EnterpriseData from "../Steps/EnterpriseData";
import Terms from "../Steps/Terms";
import UserPicker from "../Steps/UserPicker";

function QuickSignup() {
  const [state, setState] = useState({
    loading: false,
    loadingSave: false,
    page: 0,
    verify: false,
    openModal: false,
  });

  const user = useSelector((store) => store.AppReducer.user);
  const token = useSelector((store) => store.AppReducer.token);
  const dispatch = useDispatch();
  let history = useNavigate();

  const [enterpriseType, setEnterpriseType] = useState({
    type: "",
  });

  const [enterpriseData, setEnterpriseData] = useState({
    cnpj: { value: "", error: false, msgError: "" },
    cep: { value: "", error: false, msgError: "" },
    city: { value: "", error: false, msgError: "", disabled: false },
    state: { value: "", error: false, msgError: "", disabled: false },
    street: { value: "", error: false, msgError: "", disabled: false },
    nbhd: { value: "", error: false, msgError: "", disabled: false },
    number: { value: "", error: false, msgError: "" },
    comp: { value: "", error: false, msgError: "" },
  });

  function renderDescription() {
    if (state.page === 0) {
      return (
        <p className="card-subtitle">
          Informações sobre a localização da empresa.
        </p>
      );
    }
    if (state.page === 1) {
      return <p className="card-subtitle">Selecione o tipo de empresa.</p>;
    }
    if (state.page === 2) {
      return (
        <p className="card-subtitle">
          Certifique-se de ler e estar de acordo com os termos de uso para
          acessar o sistema Blueen Open.
        </p>
      );
    }
  }

  function renderPage() {
    if (state.page === 0) {
      return (
        <EnterpriseData
          formData={enterpriseData}
          setFormData={setEnterpriseData}
        />
      );
    }
    if (state.page === 1) {
      return (
        <UserPicker
          formData={enterpriseType}
          setFormData={setEnterpriseType}
          state={state}
          setState={setState}
        />
      );
    }

    if (state.page === 2) {
      return <Terms formData={state} setFormData={setState} />;
    }
  }

  function stepBack() {
    if (state.page > 0) {
      setState({ ...state, page: state.page - 1 });
      if (state.page === 2) {
        setEnterpriseType({ ...enterpriseType, type: "" });
        return;
      }
    }
  }

  function nextStep() {
    if (state.page === 0) {
      let variables = Object.keys(enterpriseData);
      let data = { ...enterpriseData };
      variables.forEach((item) => {
        if (data[item].value == "") {
          data[item].error = true;
          data[item].msgError = "Campo em Branco";
        }
        setEnterpriseData(data);
        return;
      });
      if (
        enterpriseData.cnpj.value.length !== 18 &&
        enterpriseData.cnpj.value !== ""
      ) {
        setEnterpriseData({
          ...enterpriseData,
          cnpj: {
            value: enterpriseData.cnpj.value,
            error: true,
            msgError: "Digite o CNPJ corretamente",
          },
        });
        return;
      }
      if (
        enterpriseData.cep.value.length <= 8 &&
        enterpriseData.cep.value !== ""
      ) {
        setEnterpriseData({
          ...enterpriseData,
          cep: {
            value: enterpriseData.cep.value,
            error: true,
            msgError: "Digite o CEP corretamente",
          },
        });
        return;
      }
      if (
        enterpriseData.cnpj.error !== true &&
        enterpriseData.cep.error !== true &&
        enterpriseData.city.error !== true &&
        enterpriseData.nbhd.error !== true &&
        enterpriseData.number.error !== true &&
        enterpriseData.state.error !== true &&
        enterpriseData.street.error !== true
      ) {
        setState({
          ...state,
          page: 1,
          loadingSave: false,
        });
        return;
      }
    }

    if (state.page === 1) {
      if (enterpriseType.type === "") {
        ErrorAlert("Escolha um tipo de usuário");
      } else {
        setState({ ...state, loadingSave: false, page: 2 });
      }
    }

    if (state.page === 2) {
      if (state.verify === true) {
        setState({ ...state, loadingSave: true });
        fetch(`${URL}api/auth/register_invited`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user.id,
            cnpj: enterpriseData.cnpj.value,
            cep: enterpriseData.cep.value,
            city: enterpriseData.city.value,
            state: enterpriseData.state.value,
            street: enterpriseData.street.value,
            number: enterpriseData.number.value,
            nbhd: enterpriseData.nbhd.value,
            comp: enterpriseData.comp.value,
            enterprise_type: enterpriseType.type,
          }),
        })
          .then(async (responseLog) => {
            try {
              let resp = await responseLog.json();
              if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let error = "";
                for (let i = 0; i < errors.length; i++) {
                  if (i != errors.length - 1) error += errors[i] + "\n";
                  else error += errors[i];
                }
                console.log(error);
                window.scrollTo(500, 0);

                setState({ ...state, loadingSave: false });
                ErrorAlert(error);
              } else {
                localStorage.setItem("token", token);
                localStorage.setItem("user", JSON.stringify(resp.user));
                dispatch(login({ token: token, user: resp.user }));

                SuccessAlert("Cadastro efetuado com sucesso!");

                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            } catch (err) {
              console.log(err);
              setState({ ...state, loadingSave: false });
              ErrorAlert("Erro ao pegar resposta do servidor");
            }
          })
          .catch((err) => {
            console.log(err);
            setState({ ...state, loadingSave: false });
            ErrorAlert(
              "Erro ao pegar resposta do servidor. Você está conectado a internet?"
            );
          });
      }
    }
  }

  return (
    <div>
      {state.loading && (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <header className="mb-4">
          <h2 className="font-18 card-title text-body">Cadastre sua empresa</h2>
          <p className="card-subtitle my-2">{renderDescription()}</p>
        </header>
      )}
      {!state.loading && (
        <div className="form-horizontal w-100">
          <div>
            <Stepper activeStep={state.page}>
              <Step>
                <StepLabel></StepLabel>
              </Step>
              <Step>
                <StepLabel></StepLabel>
              </Step>
              <Step>
                <StepLabel></StepLabel>
              </Step>
            </Stepper>
            {renderPage()}
            <div className="mt-4">
              <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                className="col-12"
                disabled={
                  state.loadingSave ||
                  (state.page == 2 && state.verify == false)
                }
                onClick={() => nextStep()}
              >
                {state.loadingSave && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {!state.loadingSave && state.page < 3 && <span>Avançar</span>}
                {!state.loadingSave && state.page >= 3 && <span>Concluir</span>}
              </Button>
            </div>
            {state.page > 0 && (
              <div className="mt-3">
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="error"
                  disabled={state.loadingSave}
                  startIcon={<Reply />}
                  onClick={() => stepBack()}
                >
                  {state.loadingSave && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!state.loadingSave && <span>Voltar</span>}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default QuickSignup;
