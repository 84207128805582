//* React imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//* Style imports
import {
  Alert,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { DeleteOutline, EditOutlined, Reply } from "@mui/icons-material";

//* Utils imports
import validator from "validator";
import { URL } from "variables";
import { ErrorAlert, SuccessAlert, handleStatus } from "utils/utilities";
import { maskMoneyV2, maskNumberBr, maskTelefone } from "utils/masks";
import EnterpriseData from "screen/Auth/SignUp/Steps/EnterpriseData";

function AddCarrier() {
  const [state, setState] = useState({
    loading: false,
    loadingSave: false,
    carriers: [],
    categories: ["A", "B", "C", "D", "E"],
  });

  const [formData, setFormData] = useState({
    companyName: { value: "", error: false, msgError: "" },
    commercialName: { value: "", error: false, msgError: "" },
    responsibleName: { value: "", error: false, msgError: "" },
    responsibleEmail: { value: "", error: false, msgError: "" },
    image: "",
    imgPath: "",
    imageHover: "",
  });

  //* Enterprise data
  const [enterpriseData, setEnterpriseData] = useState({
    cnpj: { value: "", error: false, msgError: "" },
    cep: { value: "", error: false, msgError: "" },
    city: { value: "", error: false, msgError: "", disabled: false },
    state: { value: "", error: false, msgError: "", disabled: false },
    street: { value: "", error: false, msgError: "", disabled: false },
    nbhd: { value: "", error: false, msgError: "", disabled: false },
    number: { value: "", error: false, msgError: "" },
    comp: { value: "", error: false, msgError: "" },
    phone: { value: "", error: false, msgError: "" },
  });

  //Setting variables to fetches
  const token = localStorage.getItem("token");
  const enterprise_id = useSelector(
    (store) => store.AppReducer.user.enterprise_id
  );

  let history = useNavigate();

  function handleSubmit() {
    let hasError = false;

    let responsibleVariables = [
      "responsibleName",
      "responsibleEmail",
      "commercialName",
      "companyName",
    ];
    let responsibleValidation = { ...formData };
    responsibleVariables.forEach((item) => {
      if (!responsibleValidation[item].value) {
        responsibleValidation[item].error = true;
        responsibleValidation[item].msgError = "Campo em Branco";
        hasError = true;
      }
      setFormData(responsibleValidation);
    });

    let enterpriseVariables = [
      "cnpj",
      "cep",
      "city",
      "state",
      "number",
      "street",
      "nbhd",
      "phone",
    ];
    let enterpriseValidation = { ...enterpriseData };
    enterpriseVariables.forEach((item) => {
      if (!enterpriseValidation[item].value) {
        enterpriseValidation[item].error = true;
        enterpriseValidation[item].msgError = "Campo em Branco";
        hasError = true;
      }
      setEnterpriseData(enterpriseValidation);
    });

    if (hasError) return;

    if (!validator.isEmail(formData.responsibleEmail.value)) {
      setFormData({
        ...formData,
        responsibleEmail: {
          value: formData.responsibleEmail.value,
          error: true,
          msgError: "Digite um formato de e-mail válido",
        },
      });
      return;
    }

    if (enterpriseData.phone.value.length < 15) {
      setEnterpriseData({
        ...enterpriseData,
        phone: {
          value: enterpriseData.phone.value,
          error: true,
          msgError: "Preencha o campo corretamente",
        },
      });
      return;
    }
    if (enterpriseData.cnpj.value.length < 18) {
      setEnterpriseData({
        ...enterpriseData,
        cnpj: {
          value: enterpriseData.cnpj.value,
          error: true,
          msgError: "Preencha o campo corretamente",
        },
      });
      return;
    }
    if (enterpriseData.cep.value.length < 8) {
      setEnterpriseData({
        ...enterpriseData,
        cep: {
          value: enterpriseData.cep.value,
          error: true,
          msgError: "Preencha o campo corretamente",
        },
      });
      return;
    }

    setState({ ...state, loadingSave: true });

    let form = new FormData();
    form.append("image", formData.image);
    form.append("name", formData.responsibleName.value);
    form.append("email", formData.responsibleEmail.value);
    form.append("company_name", formData.companyName.value);
    form.append("commercial_name", formData.commercialName.value);
    form.append("enterprise_destination_id", enterprise_id);

    form.append("cnpj", enterpriseData.cnpj.value);
    form.append("cep", enterpriseData.cep.value);
    form.append("city", enterpriseData.city.value);
    form.append("state", enterpriseData.state.value);
    form.append("street", enterpriseData.street.value);
    form.append("number", enterpriseData.number.value);
    form.append("nbhd", enterpriseData.nbhd.value);
    form.append("comp", enterpriseData.comp.value);
    form.append("phone", enterpriseData.phone.value);

    fetch(`${URL}api/quick_store_carrier/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: form,
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();

          if (resp.errors != null || resp.error != null) {
            let error = resp.errors.error;
            setState({ ...state, loadingSave: false });
            if (error === "emailerror") {
              setFormData({
                ...formData,
                responsibleEmail: {
                  value: formData.responsibleEmail.value,
                  error: true,
                  msgError: "Este e-mail já está em uso",
                },
              });
              return;
            }
            if (error === "cnpjerror") {
              setEnterpriseData({
                ...enterpriseData,
                cnpj: {
                  value: enterpriseData.cnpj.value,
                  error: true,
                  msgError: "Já existe uma empresa com esse CNPJ",
                },
              });
              return;
            }
          } else {
            SuccessAlert("Transportadora cadastrada com sucesso!");
            history("/pbo/dashboard/carriers");
          }
        } catch (err) {
          console.log(err);
          setState({ ...state, loadingSave: false });
          ErrorAlert("Erro ao pegar resposta do servidor.");
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loadingSave: false });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  const handleImageChange = (event) => {
    let selectedFile = event.target.files[0];
    let reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setState({
          ...state,
          imageHover: false,
        });
        setFormData({
          ...formData,
          image: selectedFile,
          imgPath: reader.result,
        });
      }
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div>
          <header>
            <h1 className="card-title text-body">
              <b>Adicionar Transportadora</b>
            </h1>
            <span className="text-body">Adicione uma transportadora.</span>
          </header>
          <hr />
          <main className="mt-4">
            <div className="form-group-row d-flex align-items-center justify-content-center">
              <div className="mb-3 text-center">
                <div
                  style={{
                    width: "130px",
                    height: "130px",
                    border: "1px solid rgba(60, 0, 150, 0.8)",
                    borderRadius: "50%",
                    overflow: "hidden",
                    position: "relative",
                  }}
                  onMouseEnter={() => setState({ ...state, imageHover: true })}
                  onMouseLeave={() => setState({ ...state, imageHover: false })}
                >
                  <label
                    htmlFor="profile-image-upload"
                    style={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      textAlign: "center",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      opacity: state.imageHover ? "0.75" : "1",
                      transition: "opacity 0.3s ease",
                    }}
                  >
                    <input
                      type="file"
                      id="profile-image-upload"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                    {formData.imgPath ? (
                      <img
                        src={formData.imgPath}
                        alt="Foto de Perfil"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          display: "block",
                        }}
                      />
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                          background: "#f2f2f2",
                          color: "#888",
                          fontSize: "14px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      >
                        Selecionar Imagem
                      </span>
                    )}
                  </label>
                </div>
                {formData.imgPath && (
                  <Tooltip title="Remover imagem">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setState({
                          ...state,
                          imageHover: false,
                        });
                        setFormData({
                          ...formData,
                          image: "",
                          imgPath: "",
                        });
                      }}
                    >
                      <DeleteOutline style={{ color: "#f8333c" }} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className="form-group-row">
              <TextField
                label="Razão Social"
                variant="outlined"
                fullWidth
                required
                error={formData.companyName.error}
                helperText={formData.companyName.msgError}
                value={formData.companyName.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companyName: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
            <div className="form-group-row mt-3">
              <TextField
                label="Nome Fantasia"
                variant="outlined"
                fullWidth
                required
                error={formData.commercialName.error}
                helperText={formData.commercialName.msgError}
                value={formData.commercialName.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    commercialName: { value: e.target.value, error: false },
                  });
                }}
              />
            </div>
            <div className="form-group-row mt-3">
              <TextField
                label="Telefone"
                variant="outlined"
                fullWidth
                required
                error={enterpriseData.phone.error}
                helperText={enterpriseData.phone.msgError}
                value={enterpriseData.phone.value}
                onChange={(e) => {
                  setEnterpriseData({
                    ...enterpriseData,
                    phone: {
                      value: maskTelefone(e.target.value),
                      error: false,
                    },
                  });
                }}
              />
            </div>
            <div className="form-group mt-3 row">
              <div className=" col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <TextField
                      label="Nome Responsável"
                      variant="outlined"
                      fullWidth
                      required
                      error={formData.responsibleName.error}
                      helperText={formData.responsibleName.msgError}
                      value={formData.responsibleName.value}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          responsibleName: {
                            value: e.target.value,
                            error: false,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6 col-12 mt-md-0 mt-3">
                    <TextField
                      label="E-mail Responsável"
                      variant="outlined"
                      fullWidth
                      required
                      error={formData.responsibleEmail.error}
                      helperText={formData.responsibleEmail.msgError}
                      value={formData.responsibleEmail.value}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          responsibleEmail: {
                            value: e.target.value,
                            error: false,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <EnterpriseData
              formData={enterpriseData}
              setFormData={setEnterpriseData}
            />
            <div className="row">
              <div className="d-flex align-items-center justify-content-between my-5">
                <Link to={"/pbo/dashboard/drivers"}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    color="error"
                    disabled={state.loadingSave}
                    startIcon={<Reply />}
                  >
                    <span>Voltar</span>
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                  disabled={state.loadingSave}
                  onClick={() => handleSubmit()}
                >
                  {state.loadingSave && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!state.loadingSave && <span>Salvar</span>}
                </Button>
              </div>
              <hr />
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default AddCarrier;
