//* React imports
import React from "react";

//* Style imports
import { Checkbox, FormControlLabel } from "@mui/material";

function Terms({ formData, setFormData }) {
  return (
    <div>
      <div className="form-group-row mt-5">
        <div className="p-3">
          <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
            <p className="lead">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
              architecto commodi, ad veniam asperiores ratione perferendis. Cum
              rerum repudiandae nesciunt. Asperiores blanditiis suscipit nam.
              Reprehenderit quae architecto aspernatur ullam mollitia.
            </p>
            <p className="lead">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
              architecto commodi, ad veniam asperiores ratione perferendis. Cum
              rerum repudiandae nesciunt. Asperiores blanditiis suscipit nam.
              Reprehenderit quae architecto aspernatur ullam mollitia.
            </p>
            <p className="lead">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
              architecto commodi, ad veniam asperiores ratione perferendis. Cum
              rerum repudiandae nesciunt. Asperiores blanditiis suscipit nam.
              Reprehenderit quae architecto aspernatur ullam mollitia.
            </p>
            <p className="lead">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
              architecto commodi, ad veniam asperiores ratione perferendis. Cum
              rerum repudiandae nesciunt. Asperiores blanditiis suscipit nam.
              Reprehenderit quae architecto aspernatur ullam mollitia.
            </p>
          </div>
          <div className="row mt-3">
            <FormControlLabel
              control={
                <Checkbox
                  disabled={formData.loadingSave}
                  checked={formData.verify}
                  onChange={() =>
                    setFormData({
                      ...formData,
                      verify: !formData.verify,
                    })
                  }
                />
              }
              label="Aceito os termos de uso"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
