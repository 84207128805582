import Landing from "./Landing";
import Navbar from "components/specific/LandingPage/Navbar";
import Services from "./Services";
import Footer from "components/specific/LandingPage/Footer";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Depoimentos from "./Depoimentos";
import QuemSomos from "./QuemSomos";
import Parceiros from "./Parceiros";
import { useEffect } from "react";
import Contato from "./Contato";

const HomePage = ({ on_top }) => {
  return (
    <>
      <ExpandCircleDownIcon
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        style={{
          position: "fixed",
          zIndex: 100,
          bottom: "2rem",
          right: on_top ? "-4rem" : "2rem",
          fontSize: "3rem",
          transition: "all 0.5s ease",
          backgroundColor: "white",
          borderRadius: "50%",
          transform: "rotate(180deg)",
          color: "var(--blue-1)",
          cursor: "pointer",
        }}
      />

      <Landing {...{ on_top }} />
      <QuemSomos />
      <Services />
      <Parceiros />
      <Contato />
      {/* <Depoimentos /> */}
    </>
  );
};

export default HomePage;
