import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { URL } from "variables";
import Notification from "../Notification";
import { Alert, Chip, Divider, IconButton, Pagination } from "@mui/material";
import { Check, MoreVert } from "@mui/icons-material";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { notificationCounter } from "redux/AppActions";

function NotificationsPage() {
  const reducer = useSelector((store) => store.AppReducer);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    loading: true,
    notificationContent: [],
    page: 1,
    totalPages: 1,
  });

  const [menu, setMenu] = useState(false);
  const toggle = useCallback(() => setMenu(!menu), [menu]);

  useEffect(() => {
    getNotifications(1);
  }, [reducer.notifications]);

  function getNotifications(page) {
    fetch(
      `${URL}api/get_blueen_notifications?page=${page}&id=${reducer.user.id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${reducer.token}`,
        },
      }
    )
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        setState({
          ...state,
          loading: false,
          notificationContent: resp.notifications,
          page: resp.pagination.current_page,
          totalPages: resp.pagination.total_pages,
        });
      })
      .catch((err) => console.log(err));
  }

  const handleChangePage = (event, page) => {
    if (page != state.page) {
      setState({ ...state, page: page });
      getNotifications(page);
    }
  };

  function markAsRead() {
    fetch(`${URL}api/auth/mark_as_read`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${reducer.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: reducer.user.id,
        isOpen: true,
      }),
    })
      .then(async () => {
        getNotifications(1);
        dispatch(notificationCounter(0));
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      {state.loading && (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-lg-8 col-12">
              <header className="d-flex justify-content-between align-items-center">
                <h1 className="card-title text-body mb-3 fw-bold">
                  Notificações
                </h1>
                <Dropdown isOpen={menu} toggle={toggle} direction="end">
                  <DropdownToggle tag="button">
                    <IconButton size="small" color="inherit">
                      <MoreVert />
                    </IconButton>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => markAsRead()}>
                      <Check /> Marcar todas como lidas
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </header>
              {state.notificationContent.length > 0 && (
                <div className="card">
                  {state.notificationContent.map((item, id) => (
                    <React.Fragment key={item.id}>
                      <Notification
                        state={state}
                        setState={setState}
                        reducer={reducer}
                        item={item}
                      />
                      {id !== state.notificationContent.length - 1 && (
                        <Divider />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
              {state.notificationContent.length == 0 && (
                <Alert severity="info">Nenhuma notificação encontrada</Alert>
              )}
            </div>
            {state.notificationContent.length > 0 && (
              <div
                style={{
                  width: "65%",
                }}
                className="d-flex justify-content-end my-2"
              >
                <Pagination
                  count={state.totalPages}
                  page={state.page}
                  onChange={handleChangePage}
                  defaultPage={state.page}
                  color="primary"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default NotificationsPage;
