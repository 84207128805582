import { Container } from "@mui/system";
import CustomSection from "components/specific/CustomSection";
import social from "assets/static/landing/social.svg";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import home_bg from "assets/static/landing/home_bg.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import arrow_white from "assets/static/arrow_white.png";
import styles from "./styles.module.css";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { phoneMaskBrazil } from "hooks/helpers";
import ReactInputMask from "react-input-mask";
import { post } from "hooks/requests";
import { URL } from "variables";
import { ErrorAlert, SuccessAlert } from "utils/utilities";

const Landing = ({ on_top }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(false);

  // modal
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [message, setMessage] = useState("");

  const handlePhoneChange = (e) => {
    setPhone(phoneMaskBrazil(e));
  };

  const handleSendContact = async () => {
    if (!name || !email || !phone || !cnpj) {
      ErrorAlert("Preencha todos os campos!");
      return;
    }
    setLoading(true);
    fetch(`${URL}api/auth/contact_us`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        cnpj: cnpj,
        message: message,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setLoading(false);
          } else {
            setName("");
            setEmail("");
            setPhone("");
            setCnpj("");
            setMessage("");
            setLoading(false);
            SuccessAlert("Sua mensagem foi enviada com sucesso!");
          }
        } catch (err) {
          setLoading(false);
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  };

  return (
    <CustomSection
      style={{
        overflow: "hidden",
        position: "relative",
      }}
      id="home"
      bg_image_ghost={home_bg}
      bg_image_ghost_opacity={1}
    >
      <KeyboardDoubleArrowDownIcon
        onClick={() =>
          window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth",
          })
        }
        style={{
          position: "absolute",
          bottom: "2rem",
          left: "50%",
          transform: "translateX(-50%)",
          fontSize: on_top ? "3rem" : "0",
          transition: "all 0.5s ease",
          color: "var(--text-reverse-2)",
          cursor: "pointer",
        }}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "46rem",
            backgroundColor: "#f7f7ff",
            display: "flex",
            flexDirection: "column",
            padding: "4rem 5rem",
            gap: "1rem",
            borderRadius: "1rem",
          }}
        >
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            Entrar em Contato
          </Typography>
          <Typography variant="p" style={{ fontSize: "1.1rem" }}>
            Preencha os dados abaixo para entrar em contato e fazer parte da
            Blueen Open.
          </Typography>
          <TextField
            label="Nome completo"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="E-mail"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Telefone"
            variant="outlined"
            fullWidth
            value={phone}
            inputProps={{ maxLength: 15 }}
            onChange={(e) => handlePhoneChange(e)}
          />
          <ReactInputMask
            mask="99.999.999/9999-99"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
            maskChar=""
          >
            {() => <TextField label="CNPJ" variant="outlined" fullWidth />}
          </ReactInputMask>
          <TextField
            label="Mensagem"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            type="button"
            style={{
              backgroundColor: "#009b8f",
              color: "#fbfbfb",
              fontWeight: "bold",
              borderRadius: "0.4rem",
              cursor: "pointer",
              padding: "0.6rem 1.8rem",
              width: "max-content",
              alignSelf: "flex-end",
            }}
            disabled={loading}
            onClick={handleSendContact}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <span>Enviar</span>
            )}
          </button>
        </Box>
      </Modal>

      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
            minHeight: "100vh",
            gap: "2rem",
            // flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "60%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              margin: {
                xs: "auto auto 0 auto",
                sm: "auto auto 0 auto",
                md: "auto",
              },
            }}
          >
            <Box
              sx={{
                borderLeft: {
                  xs: "none",
                  sm: "0.2rem solid var(--blue-1)",
                  md: "0.2rem solid var(--blue-1)",
                },
                padding: "1rem",
                paddingLeft: {
                  xs: "0",
                  sm: "2rem",
                  md: "2rem",
                },
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                zIndex: 10,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xs: "1.2rem",
                    sm: "1.4rem",
                    md: "1.6rem",
                  },
                  color: "var(--blue-1)",
                  fontWeight: "bold",
                }}
              >
                BLUEEN
              </Typography>

              <Typography
                variant="h2"
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "1.8rem",
                    sm: "2.6rem",
                    md: "3.6rem",
                  },
                }}
              >
                Otimize seu tempo e seu lixo.
              </Typography>

              <Typography
                variant="p"
                sx={{
                  fontWeight: "normal",
                  maxWidth: "80%",
                  fontSize: {
                    xs: "0.9rem",
                    sm: "1rem",
                    md: "1.1rem",
                  },
                  textAlign: "justify",
                  fontWeight: "bold",
                  // backdropFilter: "blur(10px)",
                }}
              >
                Sistema web e mobile de gestão ponta a ponta da cadeia de
                resíduos que conecta todos os atores do processo.
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontWeight: "normal",
                  maxWidth: "80%",
                  fontSize: {
                    xs: "0.9rem",
                    sm: "1rem",
                    md: "1.1rem",
                  },
                  textAlign: "justify",
                  fontWeight: "bold",
                  // backdropFilter: "blur(10px)",
                }}
              >
                Gestão de resíduos com eficiência e inteligência, para
                geradores, coletores, cooperativas, destinos finais e empresas
                de todos os tamanhos e segmentos.
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontWeight: "normal",
                  maxWidth: "80%",
                  fontSize: {
                    xs: "0.9rem",
                    sm: "1rem",
                    md: "1.1rem",
                  },
                  textAlign: "justify",
                  fontWeight: "bold",
                  // backdropFilter: "blur(10px)",
                }}
              >
                Otimize seus processos, garanta o atendimento a requisitos
                legais e melhore seu desempenho ESG.
              </Typography>

              <div
                className="Transition-1"
                style={{
                  display: "flex",
                }}
              >
                <button
                  className={`Transition-1 ${styles.Button}`}
                  style={{
                    position: "relative",
                    letterSpacing: "0.1rem",
                  }}
                  onClick={handleOpen}
                >
                  Contato
                  <img
                    className="Transition-1"
                    src={arrow_white}
                    alt="Entrar"
                    style={{
                      position: "absolute",
                      top: "80%",
                      left: "10%",
                      transform: "translate(-50%, -50%)",
                      opacity: "0",
                    }}
                  />
                </button>
              </div>
            </Box>
          </Box>
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              maxWidth: "28rem",
              // background: "var(--purple-1)",
              margin: {
                xs: "0 auto auto auto",
                sm: "0 auto auto auto",
                md: "auto",
              },
            }}
          >
            {/* <img
              src={social}
              alt="Mídia social"
              style={{
                width: "150%",
                position: "relative",
                right: "50%",
                zIndex: "0",
              }}
            /> */}
          </Box>
        </Box>
      </Container>
    </CustomSection>
  );
};

export default Landing;
