import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { MdCloudUpload } from 'react-icons/md'

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

function InputFileUpload({ label, name, type, color, handleChange }) {

  return (
    <Button
      fullWidth
      color={color}
      component="label"
      variant="contained"
      startIcon={<MdCloudUpload />}
      href="#file-upload"
    >
      {label}
      <VisuallyHiddenInput name={name} type="file" accept={`application/${type}`} onChange={handleChange} />
    </Button>
  );
}

InputFileUpload.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  handleSubmit: PropTypes.func,
};

InputFileUpload.defaultProps = {
  name: 'file',
  label: 'Selecione um arquivo',
  type: '*',
  color: 'primary',
  handleChange: ({ target }) => console.error('missing a file handling function', target)
};

export default InputFileUpload