import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { Reply } from '@mui/icons-material'

const PresetButtons = props => {
  return (
    <div className="row">
      <div className="d-flex align-items-center justify-content-between my-5">
        <Link to={props.backPath}>
          <Button
            variant="contained"
            disableElevation
            size="large"
            color="error"
            disabled={props.loadingSave}
            startIcon={<Reply />}
          >
            <span>Voltar</span>
          </Button>
        </Link>
        <Button
          variant="contained"
          disableElevation
          size="large"
          color="primary"
          disabled={props.loadingSave}
          onClick={props.handleSubmit}
          sx={{ minHeight: 42.25, minWidth: 100 }}
        >
          {props.loadingSave && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {!props.loadingSave && <span>{props.label}</span>}
        </Button>
      </div>
    </div>
  )
}

PresetButtons.propTypes = {
  loadingSave: PropTypes.bool,
  label: PropTypes.string,
  backPath: PropTypes.string,
  handleSubmit: PropTypes.func,
};

PresetButtons.defaultProps = {
  loadingSave: false,
  label: 'Salvar',
  backPath: '/pbo/dashboard',
  handleSubmit: () => console.log('saving'),
};

export default PresetButtons