//* React imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//* Style imports
import {
  Button,
  IconButton,
  InputAdornment,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import "../style.css";

//* Utils imports
import validator from "validator";
import { URL } from "../../../variables";
import { ErrorAlert, SuccessAlert } from "utils/utilities";

//* Components imports
import SideSection from "../../../components/specific/AuthSideSection/SideSection";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

function ForgotPassword() {
  //* General variables
  const [state, setState] = useState({
    loading: false,
    loadingSave: false,
    page: 0,
    resendMailMsg: false,
    error: "",
    viewPassword: false,
    viewPasswordConfirmed: false,
  });

  let history = useNavigate();

  //* User infos to update password
  const [formData, setFormData] = useState({
    email: { value: "", error: false, msgError: "" },
    code: { value: "", error: false, msgError: "" },
    newPassword: { value: "", error: false, msgError: "" },
    newPasswordConfirmation: { value: "", error: false, msgError: "" },
  });

  //* Controls the Stepper component
  function nextStep() {
    //* Step One
    if (state.page === 0) {
      if (formData.email.value === "") {
        setFormData({
          ...formData,
          email: { error: true, msgError: "Campo em Branco" },
        });
        setState({ ...state, loadingSave: false });
        return;
      }
      if (
        formData.email.value !== "" &&
        !validator.isEmail(formData.email.value)
      ) {
        setFormData({
          ...formData,
          email: {
            value: formData.email.value,
            error: true,
            msgError: "Digite um formato de e-mail válido",
          },
        });
        return;
      }
      if (formData.email.error === false) {
        setState({ ...state, loadingSave: true });
        fetch(`${URL}api/auth/new_password`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email.value,
          }),
        })
          .then(async (responseLog) => {
            try {
              let resp = await responseLog.json();
              if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let error = "";
                for (let i = 0; i < errors.length; i++) {
                  if (i != errors.length - 1) error += errors[i] + "\n";
                  else error += errors[i];
                }
                console.log(error);
                window.scrollTo(500, 0);

                setState({ ...state, loadingSave: false });
                ErrorAlert(error);
              } else {
                setState({
                  ...state,
                  page: 1,
                  loadingSave: false,
                });
              }
            } catch (err) {
              console.log(err);
              setState({ ...state, loadingSave: false });
              ErrorAlert("Erro ao pegar resposta do servidor");
            }
          })
          .catch((err) => {
            console.log(err);
            setState({ ...state, loadingSave: false });
            ErrorAlert(
              "Erro ao pegar resposta do servidor. Você está conectado a internet?"
            );
          });
      }
      return;
    }

    //* Step Two
    if (state.page === 1) {
      if (formData.code.value === "") {
        setFormData({
          ...formData,
          code: { error: true, msgError: "Campo em Branco" },
        });
        setState({ ...state, loadingSave: false });
        return;
      }
      if (formData.code.error === false) {
        setState({ ...state, loadingSave: true });
        fetch(`${URL}api/auth/insert_code`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email.value,
            code: formData.code.value,
          }),
        })
          .then(async (responseLog) => {
            try {
              let resp = await responseLog.json();
              if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let error = "";
                for (let i = 0; i < errors.length; i++) {
                  if (i != errors.length - 1) error += errors[i] + "\n";
                  else error += errors[i];
                }
                console.log(error);
                window.scrollTo(500, 0);
                setState({ ...state, loadingSave: false });
                ErrorAlert(error);
              } else {
                setState({
                  ...state,
                  page: 2,
                  loadingSave: false,
                });
              }
            } catch (err) {
              console.log(err);
              setState({ ...state, loadingSave: false });
              ErrorAlert("Erro ao pegar resposta do servidor");
            }
          })
          .catch((err) => {
            console.log(err);
            setState({ ...state, loadingSave: false });
            ErrorAlert(
              "Erro ao pegar resposta do servidor. Você está conectado a internet?"
            );
          });
      }
      return;
    }

    //* Step Three
    if (state.page === 2) {
      if (formData.newPassword.value === "") {
        setFormData({
          ...formData,
          newPassword: { error: true, msgError: "Campo em Branco" },
        });
        setState({ ...state, loadingSave: false });
        return;
      }
      if (formData.newPasswordConfirmation.value === "") {
        setFormData({
          ...formData,
          newPasswordConfirmation: { error: true, msgError: "Campo em Branco" },
        });
        setState({ ...state, loadingSave: false });
        return;
      }
      if (
        formData.newPassword.error === false &&
        formData.newPasswordConfirmation.error === false
      ) {
        setState({ ...state, loadingSave: true });
        fetch(`${URL}api/auth/update_password`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: formData.email.value,
            password: formData.newPassword.value,
            password_confirmation: formData.newPasswordConfirmation.value,
          }),
        })
          .then(async (responseLog) => {
            try {
              let resp = await responseLog.json();
              if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let error = "";
                for (let i = 0; i < errors.length; i++) {
                  if (i != errors.length - 1) error += errors[i] + "\n";
                  else error += errors[i];
                }
                console.log(error);
                window.scrollTo(500, 0);
                setState({ ...state, loadingSave: false });
                ErrorAlert(error);
              } else {
                // setState({ ...state, loadingSave: false });
                SuccessAlert("Senha alterada com sucesso!");
                setTimeout(() => {
                  history("/login");
                }, 3000);
              }
            } catch (err) {
              console.log(err);
              setState({ ...state, loadingSave: false });
              ErrorAlert("Erro ao pegar resposta do servidor");
            }
          })
          .catch((err) => {
            console.log(err);
            setState({ ...state, loadingSave: false });
            ErrorAlert(
              "Erro ao pegar resposta do servidor. Você está conectado a internet?"
            );
          });
      }
    }
  }

  //* Updates the description of each step
  function renderDescription() {
    if (state.page === 0) {
      return (
        <p className="card-subtitle">
          Insira seu endereço de e-mail associado à sua conta Blueen que
          enviaremos um código para a alteração de sua senha.
        </p>
      );
    }
    if (state.page === 1) {
      return (
        <p className="card-subtitle">
          Verifique se você recebeu um e-mail contendo um código com 8
          caracteres.
        </p>
      );
    }
    if (state.page === 2) {
      return (
        <p className="card-subtitle">
          Crie uma nova senha com no mínimo 6 caracteres.
        </p>
      );
    }
  }

  //* Shows the inputs on the screen, based the steps
  function renderPage() {
    //* Step one
    if (state.page == 0) {
      return (
        <div className="form-group-row mt-5">
          <TextField
            label="E-mail"
            variant="outlined"
            fullWidth
            required
            error={formData.email.error}
            helperText={formData.email.msgError}
            value={formData.email.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                email: { value: e.target.value, error: false },
              });
            }}
          />
        </div>
      );
    }

    //* Step two
    if (state.page == 1) {
      return (
        <div className="form-group-row mt-5">
          <TextField
            label="Código de Autenticação"
            variant="outlined"
            fullWidth
            required
            error={formData.code.error}
            helperText={formData.code.msgError}
            value={formData.code.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                code: { value: e.target.value, error: false },
              });
            }}
          />
        </div>
      );
    }

    //* Step three
    if (state.page == 2) {
      return (
        <div>
          <div className="form-group-row mt-5">
            <TextField
              label="Senha"
              variant="outlined"
              fullWidth
              required
              type={!state.viewPassword ? "password" : "text"}
              error={formData.newPassword.error}
              helperText={formData.newPassword.msgError}
              value={formData.newPassword.value}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  newPassword: {
                    value: e.target.value,
                    error: false,
                  },
                });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setState({
                          ...state,
                          viewPassword: !state.viewPassword,
                        })
                      }
                      edge="end"
                    >
                      {state.viewPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form-group-row mt-5">
            <TextField
              label="Confirme sua senha"
              variant="outlined"
              fullWidth
              required
              type={!state.viewPasswordConfirmed ? "password" : "text"}
              error={formData.newPasswordConfirmation.error}
              helperText={formData.newPasswordConfirmation.msgError}
              value={formData.newPasswordConfirmation.value}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  newPasswordConfirmation: {
                    value: e.target.value,
                    error: false,
                  },
                });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setState({
                          ...state,
                          viewPasswordConfirmed: !state.viewPasswordConfirmed,
                        })
                      }
                      edge="end"
                    >
                      {state.viewPasswordConfirmed ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      );
    }
  }

  //* Main Function
  return (
    <div>
      {state.loading && (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div
          className="d-block h-100"
          style={{
            overflow: "hidden",
            backgroundSize: "cover",
            padding: 0,
            margin: 0,
          }}
        >
          <div
            className="d-flex justify-content-center"
            style={{
              minHeight: "100vh",
            }}
          >
            <SideSection />
            <main
              className="w-100"
              style={{
                height: "calc(100% - 5.8rem)",
                marginTop: "5.8rem",
              }}
            >
              <div
                className="d-flex"
                style={{
                  maxWidth: "50rem",
                  margin: "auto",
                }}
              >
                <div className="m-5 form-horizontal w-100 p-5">
                  <div className="mt-3">
                    <h1 className="card-title text-body">
                      Esqueceu sua senha?
                    </h1>
                    {renderDescription()}
                  </div>
                  <div className="mt-5">
                    <Stepper activeStep={state.page}>
                      <Step>
                        <StepLabel></StepLabel>
                      </Step>
                      <Step>
                        <StepLabel></StepLabel>
                      </Step>
                      <Step>
                        <StepLabel></StepLabel>
                      </Step>
                    </Stepper>
                    {renderPage()}
                    <div className="mt-5">
                      <Button
                        variant="contained"
                        disableElevation
                        size="large"
                        color="primary"
                        className="col-12"
                        disabled={state.loadingSave}
                        onClick={() => nextStep()}
                      >
                        {state.loadingSave && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {!state.loadingSave && state.page < 2 && (
                          <span>Avançar</span>
                        )}
                        {!state.loadingSave && state.page >= 2 && (
                          <span>Alterar Senha</span>
                        )}
                      </Button>
                    </div>
                  </div>
                  {state.page <= 1 && (
                    <div className="mt-5">
                      <p>
                        Se lembrou da senha?
                        <Link style={{ textDecoration: "none" }} to={"/login"}>
                          <a> Faça o login</a>
                        </Link>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
