import Swal from "sweetalert2";

export const renderAlert = (callback) => {
  Swal.fire({
    title: "Você tem certeza?",
    text: "Essa ação é irreversível!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim",
    cancelButtonText: "Cancelar",
  }).then((result) => {
    if (result.isConfirmed) {
      callback()
    }
  });
}

export function renderMessage(type, message) {
  Swal.fire({
    title: "Algo deu errado",
    text: message,
    icon: type,
  });
}