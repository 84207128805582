import { Tooltip } from "@mui/material";
import { useState } from "react";

function ToolInfos() {
  const [tooltipText, setTooltipText] = useState("Clique para copiar o texto");

  const handleCopy = () => {
    const textToCopy = document.getElementById("text-to-copy").innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setTooltipText("Copiado com sucesso");

      setTimeout(() => {
        setTooltipText("Clique para copiar o texto");
      }, 2000);
    });
  };

  return (
    <div className="container mt-3">
      <header>
        <h1 className="card-title text-body">
          <b>Informações da Ferramenta</b>
        </h1>
        <p className="text-body">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          gravida tempor magna, ac suscipit elit volutpat non. Proin in suscipit
          ligula. Proin id erat ultrices, molestie tellus eu, iaculis nulla.
          Etiam porta faucibus tortor, vitae porta ex ornare eu. Sed euismod leo
          lorem, sed consectetur lorem fermentum nec. Aenean vulputate eget
          dolor at congue. Vivamus a eleifend arcu, quis hendrerit risus.
          Integer quis nisi at urna congue imperdiet et eu diam. Integer laoreet
          nulla ac dolor dapibus rhoncus.
        </p>
      </header>
      <main className="my-3 mb-3">
        <h5 className="card-title text-body">
          <b>Suspendisse quis augue</b>
        </h5>
        <p className="text-body">
          Suspendisse quis augue et felis consequat facilisis vitae vel est.
          Mauris sit amet finibus augue. Nullam porttitor, mi in convallis
          dictum, leo urna efficitur turpis, at dignissim lorem dui vel lectus.
          Nulla malesuada non metus molestie consectetur.
        </p>
        <h5 className="card-title text-body">
          <b>Praesent luctus ipsum</b>
        </h5>
        <p className="text-body">
          Praesent luctus ipsum eget blandit eleifend. Class aptent taciti
          sociosqu ad litora torquent per conubia nostra, per inceptos
          himenaeos.
        </p>
        <h5 className="card-title text-body">
          <b>Fusce vulputate</b>
        </h5>
        <p className="text-body">
          Fusce vulputate felis et urna suscipit, sit amet finibus ex mollis.
          Cras vehicula justo ut ante mattis dictum. Class aptent taciti
          sociosqu ad litora torquent per conubia nostra, per inceptos
          himenaeos.
        </p>
        <h5 className="card-title text-body">
          <b>Contato</b>
        </h5>
        <p className="text-body">
          Se você tiver alguma dúvida em relação a esta Ferramenta, entre em
          contato conosco pelo e-mail:
          <Tooltip title={tooltipText} arrow>
            <span
              id="text-to-copy"
              style={{
                color: "#3C0096",
                cursor: "pointer",
              }}
              onClick={handleCopy}
            >
              contato@blueen.app
            </span>
          </Tooltip>
        </p>
      </main>
    </div>
  );
}

export default ToolInfos;
