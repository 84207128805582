import { TextField, Tooltip } from "@mui/material";
import { phoneMaskBrazil } from "hooks/helpers";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ErrorAlert, SuccessAlert, WarningAlert } from "utils/utilities";
import { URL } from "variables";

function Settings() {
  const [tooltipText, setTooltipText] = useState("Clique para copiar o texto");
  const [state, setState] = useState({
    loading: false,
  });
  const user = useSelector((store) => store.AppReducer.user);
  const token = localStorage.getItem("token");

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("Solicitar serviços ou consultoria");
  const [message, setMessage] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);

  const handleCopy = () => {
    const textToCopy = document.getElementById("text-to-copy").innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setTooltipText("Copiado com sucesso");

      setTimeout(() => {
        setTooltipText("Clique para copiar o texto");
      }, 2000);
    });
  };

  const handleSendContact = async () => {
    if (!email || !subject || !message) {
      ErrorAlert("Preencha todos os campos!");
      return;
    }
    setLoadingSave(true);
    fetch(`${URL}api/request_services_blueen`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: user.name,
        email: email,
        subject: subject,
        message: message,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setLoadingSave(false);
          } else {
            setEmail("");
            setSubject("Solicitar serviços ou consultoria");
            setMessage("");
            setLoadingSave(false);
            SuccessAlert("Sua mensagem foi enviada com sucesso!");
          }
        } catch (err) {
          setLoadingSave(false);
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setLoadingSave(false);
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  };

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div
          style={{
            width: "50%",
            margin: "0 auto",
          }}
        >
          <header>
            <h1 className="card-title text-body">
              <b>Configurações</b>
            </h1>
            <p className="text-body">
              Defina suas preferências e acesse os demais recursos da Blueen
              Open.
            </p>
          </header>
          <hr />
          <main className="mt-4">
            <div className="d-block my-4">
              <h4 className="fw-bold">Solicitar serviços</h4>
              <p className="text-body">
                Deseja obter acesso à mais funções e aprimorar seu plano com a
                Blueen? Preencha o formulário abaixo ou entre em contato com a
                Blueen pelo e-mail{" "}
                <Tooltip title={tooltipText} arrow>
                  <span
                    id="text-to-copy"
                    style={{
                      color: "#3C0096",
                      cursor: "pointer",
                    }}
                    onClick={handleCopy}
                  >
                    contato@blueen.app
                  </span>
                </Tooltip>
              </p>
              <p>
                Ao preencher este formulário, os dados serão enviados por e-mail
                à equipe Blueen para solicitar contato. Em breve, a equipe
                entrará em contato fornecendo mais informações.
              </p>
              <TextField
                className="my-2"
                label="E-mail para Resposta"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                className="my-2"
                label="Assunto"
                variant="outlined"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <TextField
                className="my-2"
                label="Mensagem"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button
                type="button"
                style={{
                  backgroundColor: "#009b8f",
                  color: "#fbfbfb",
                  fontWeight: "bold",
                  borderRadius: "0.4rem",
                  cursor: "pointer",
                  padding: "0.6rem 1.8rem",
                  width: "max-content",
                  alignSelf: "flex-end",
                }}
                disabled={loadingSave}
                onClick={handleSendContact}
              >
                {loadingSave ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <span>Enviar</span>
                )}
              </button>
            </div>

            {/* <div id="inactivate" className="d-block my-4">
              <h4 className="fw-bold">Inativar conta</h4>
              <p className="text-body">
                Você pode reativar sua conta a qualquer momento. Essa ação
                apenas o torna invisível para quaisquer novas ações da Blueen
                Open.
              </p>
              <button
                className="btn btn-warning"
                onClick={() => WarningAlert("Função em desenvolvimento")}
              >
                Inativar sua conta
              </button>
            </div>
            <div className="d-block my-4">
              <h4 className="text-danger fw-bold">Deletar conta</h4>
              <p className="text-body">
                Essa ação é irreversível, tenha certeza antes de qualquer ação.
                Você não prefere <a href="#inactivate">inativar sua conta</a>?
              </p>
              <button
                className="btn btn-danger"
                onClick={() => WarningAlert("Função em desenvolvimento")}
              >
                Deletar sua conta
              </button>
            </div> */}
          </main>
        </div>
      )}
    </div>
  );
}

export default Settings;
