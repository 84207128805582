//* React imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//* Style imports
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Pagination,
  TextField,
  Tooltip,
} from "@mui/material";

//* Utils imports
import { URL } from "variables";
import {
  DeleteOutline,
  EditOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { ErrorAlert, SuccessAlert } from "utils/utilities";

function VehiclesList() {
  const [state, setState] = useState({
    loading: true,
    searchData: "",
    searching: false,
    page: 1,
    totalPages: 1,

    vehicles: [],
  });

  const enterprise_id = useSelector(
    (store) => store.AppReducer.user.enterprise_id
  );

  const token = localStorage.getItem("token");

  useEffect(() => {
    setState({ ...state, searching: true });
    const timeoutId = setTimeout(() => {
      getVehicles(1, state.searchData);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [state.searchData]);

  function getVehicles(page, searchData, searching) {
    fetch(
      `${URL}api/get_carrier_vehicles?page=${page}&search=${searchData}&enterprise_id=${enterprise_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        console.log("resp", resp);
        setState({
          ...state,
          loading: false,
          searching: false,
          vehicles: resp.vehicles,
          page: resp.pagination.current_page,
          totalPages: resp.pagination.total_pages,
        });
      })
      .catch((err) => console.log(err));
  }

  function deleteVehicle(id) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Essa ação é irreversível!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, deletar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${URL}api/delete_vehicle/${id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then(async (responseLog) => {
            let resp = await responseLog.json();
            SuccessAlert("Deletado com sucesso!");

            let vehicles = [...state.vehicles];
            vehicles = vehicles.filter((item2, id2) => item2.id != id);
            console.log(vehicles);
            setState({ vehicles });
          })
          .catch((err) => ErrorAlert(err));
      }
    });
  }

  const handleChangePage = (event, page) => {
    if (page != state.page) {
      setState({ ...state, page: page });
      getVehicles(page, state.searchData);
    }
  };

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div>
          <header>
            <h1 className="card-title text-body">
              <b>Veículos</b>
            </h1>
            <p className="text-body">
              Lista com todos os veículos vinculados ao seu destino.
            </p>
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-md-6">
                <TextField
                  variant="standard"
                  fullWidth
                  value={state.searchData}
                  placeholder="Pesquise pela placa do veículo ou nome da transportadora..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {!state.searching && <SearchOutlined />}
                        {state.searching && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setState({ ...state, searchData: e.target.value, page: 1 });
                  }}
                />
              </div>
              <div className="mt-3 mt-md-0 col-12 col-md-6 d-flex justify-content-end">
                <div>
                  <Link to={"/pbo/dashboard/vehicles/add"}>
                    <Button
                      variant="contained"
                      disableElevation
                      size="large"
                      color="primary"
                    >
                      Adicionar Veículo
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </header>
          <main className="mt-4">
            {state.vehicles.length > 0 && (
              <div className="table-responive text-center">
                <table className="table table-hover">
                  <thead className="thead-blueen">
                    <tr>
                      <th scope="col">Placa</th>
                      <th scope="col">Modelo</th>
                      <th scope="col">Categoria</th>
                      <th scope="col">Transportadora</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-blueen">
                    {state.vehicles.map((item, id) => (
                      <tr key={id}>
                        <td className="align-middle">{item.license_plate}</td>
                        <td className="align-middle">
                          {item.vehicle_model.model}
                        </td>
                        <td className="align-middle">
                          {item.vehicle_type.type}
                        </td>
                        <td className="align-middle">
                          {item.carrier.company_name}
                        </td>
                        <td className="align-middle">
                          <Link to={"/pbo/dashboard/vehicles/view/" + item.id}>
                            <Tooltip title="Editar">
                              <IconButton>
                                <EditOutlined />
                              </IconButton>
                            </Tooltip>
                          </Link>
                          <Tooltip title="Excluir">
                            <IconButton onClick={() => deleteVehicle(item.id)}>
                              <DeleteOutline />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end mb-5">
                  <Pagination
                    count={state.totalPages}
                    page={state.page}
                    onChange={handleChangePage}
                    defaultPage={state.page}
                    color="primary"
                  />
                </div>
              </div>
            )}
            {state.vehicles.length == 0 && (
              <Alert severity="info">Nenhum registro encontrado...</Alert>
            )}
          </main>
        </div>
      )}
    </div>
  );
}

export default VehiclesList;
