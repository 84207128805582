import { Container, Typography } from "@mui/material";
import CustomSection from "components/specific/CustomSection";
import { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import partner_1 from "assets/static/partners/capital-empreendedor.png";
import partner_2 from "assets/static/partners/CNPq.png";
import partner_3 from "assets/static/partners/Conecta_Startup.png";
import partner_4 from "assets/static/partners/fapesp-img.jpg";
import partner_5 from "assets/static/partners/Inovativa.png";
import partner_6 from "assets/static/partners/IPT.png";
import partner_7 from "assets/static/partners/parquesjrp.png";
import partner_8 from "assets/static/partners/Sebrae_for_startups.png";
import partner_9 from "assets/static/partners/Sebrae.png";
import partner_10 from "assets/static/partners/Copeerlagos.png";
import partner_11 from "assets/static/partners/EnterScience.png";

const responsive = {
  0:{
    items: 2
  },
  630:{
    items: 3
  },
  830:{
    items: 4
  },
  1000:{
    items: 5
  },
  1230:{
    items: 6
  }
}

const Parceiros = () => {
  const [testimonies, setTestimonies] = useState([
    {
      name: "Consectetur Adipiscing",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl.",
      avatar: "https://picsum.photos/200/300",
      occupation: "CEO do Gogle",
    },
    {
      name: "Tincidunt Lacinia",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl.",
      avatar: "https://picsum.photos/300/300",
      occupation: "Diretor da Eipou",
    },
    {
      name: "Aliquam Nisl",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl.",
      avatar: "https://picsum.photos/200/200",
      occupation: "Fundador da Microso Soft",
    },
  ]);
  const [readyToRender, setReadyToRender] = useState(true);

  // const getto = async () => {
  //   const res = await get(`${URL}api/testimonies`);

  //   if (res.httpCode === 200) {
  //     // setTestimonies(res.testimonies);
  //     let temp_testimonies = res.testimonies.map((data) => {
  //       return {
  //         name: data.name,
  //         text: data.text,
  //         avatar: STORAGE_URL+data.avatar_path,
  //         occupation: data.occupation,
  //       };
  //     });
  //     setTestimonies(temp_testimonies);
  //     setReadyToRender(true);
  //   }
  // };

  // useEffect(() => {
  //   getto().catch((e) => {
  //     console.log(e);
  //   });
  // }, []);

  return (
    readyToRender &&
    testimonies.length > 0 && (
      <CustomSection
        bg_color="white"
        style={{
          overflow: "hidden",
          position: "relative",
          minHeight: "auto",
        }}
        id="parceiros"
      >
        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.4rem",
            margin: "auto",
            padding: "8rem 0",
            width: "100vw",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "var(--purple-1)",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Nossos parceiros
          </Typography>

          <Typography
            variant="p"
            sx={{
              color: "var(--text-main-1)",
              fontSize: "1.1rem",
              textAlign: "center",
              margin: "0 auto",
              marginBottom: "4rem",
              fontWeight: "normal",
              maxWidth: "30rem",
              "&:after": {
                content: "''",
                display: "block",
                marginTop: "30px",
                width: "40px",
                borderTop: "2px solid #444",
                margin: "30px auto 0",
              },
            }}
          >
            Parceiros que acreditam no nosso trabalho
          </Typography>

          <OwlCarousel
          className="owl-theme"
          loop
          dots={false}
          nav={false}
          items={5}
          autoplay={true}
          autoplayTimeout={2000}
          responsive={responsive}
        >
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_1} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_2} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_3} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_4} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_5} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_6} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_7} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_8} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_9} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_10} />
          </div>
          <div className="item">
            <img style={{ height: "5rem" }} src={partner_11} />
          </div>
        </OwlCarousel>
        </Container>
      </CustomSection>
    )
  );
};

export default Parceiros;
