import React from 'react'
import { URL } from 'variables'
import { renderAlert, renderMessage } from 'utils/alerts'
import { Link, useNavigate } from 'react-router-dom'
import { Delete, get } from 'hooks/requests'
import { Button, IconButton, InputAdornment, Pagination, TextField, Tooltip } from '@mui/material'
import { DeleteOutline, EditOutlined, VisibilityOutlined } from '@mui/icons-material'
import { MdSearch } from 'react-icons/md'

const ComplianceList = (props) => {
  props = { ...props, owner: 'destinations' }
  const [search, setSearch] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [loadingSearch, setLoadingSearch] = React.useState(false)
  const [data, setData] = React.useState([])
  const [dateOf, setDateOf] = React.useState('')
  const [dateFor, setDateFor] = React.useState('')
  const [pagination, setPagination] = React.useState({
    page: 1, totalPages: 1
  })
  const searchTimeout = React.useRef(null)

  const history = useNavigate()

  React.useEffect(() => {
    getData()
  }, [pagination.page, search])

  const getData = async () => {
    setLoadingSearch(true)
    const response = await get(`${URL}api/get_compliances?owner=${'destiny_id'}&page=${pagination.page}&search=${search}&dateOf=${dateOf}&dateFor=${dateFor}`)

    setLoading(false)
    setLoadingSearch(false)

    if (response.httpCode !== 200) {
      history('/pbo/dashboard')
      return
    }

    setData(response.data)
    if (!pagination.totalPages) setPagination({ ...pagination, totalPages: response.pagination.last_page })
  }

  const handleDelete = async (id) => {
    const response = await Delete(`${URL}api/delete_compliance/${id}`)

    if (response.httpCode !== 200) {
      renderMessage('error', response.message)
      return
    }

    setData(data.filter(item => item.id === id))
    setPagination({ ...pagination, page: 1 })
  }

  const handleChangePage = (e, page) => {
    if (page !== pagination.page) {
      setPagination({ ...pagination, page: page })
      getData()
    }
  };

  return (
    <div className="mx-3">
      {!loading && (
        <div className='row'>
          <h1 className="card-title text-body">
            <b>Compliances</b>
          </h1>
          <p className="text-body">
            Lista com todos os registros de documento.
          </p>
          <div className="col-12 col-md-6">
            <form
              className="app-search mt-1"
              style={{ width: '100%' }}
              onSubmit={(e) => {
                e.preventDefault();

                if (loadingSearch) {
                  return
                }

                clearTimeout(searchTimeout.current);
                setPagination({ ...pagination, page: 1 })
                getData();
              }}
            >
              <TextField
                variant="standard"
                fullWidth
                placeholder="Pesquise pelo nome do documento..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {!loadingSearch && <MdSearch size={20} />}
                      {loadingSearch && (
                        <span
                          className="spinner-border spinner-border-sm ms-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </InputAdornment>
                  ),
                }}
                onChange={({ target }) => {
                  clearTimeout(searchTimeout.current);

                  if (pagination.page !== 1) setPagination({ ...pagination, page: 1 })

                  searchTimeout.current = setTimeout(() => {
                    setSearch(target.value)
                  }, 750)
                }}
              />
            </form>
          </div>

          <div className="mt-3 mt-md-0 col-12 col-md-6 d-flex justify-content-end">
            <div>
              <Link to={"/pbo/dashboard/destinations/compliance/create"}>
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                >
                  Adicionar Compliance
                </Button>
              </Link>
            </div>
          </div>

          <div className="table-rep-plugin mt-3">
            <div className="table-responsive mb-0" data-pattern="priority-columns">
              <table id="tech-companies-1" className="table table-hover" style={{ textAlign: 'center' }}>
                <thead className="thead-blueen">
                  <tr>
                    <th scope='col'>Tipo</th>
                    <th scope='col'>Nome do documento</th>
                    <th scope='col'>Responsável</th>
                    <th scope='col' data-priority="3">Ações</th>
                  </tr>
                </thead>
                <tbody className="tbody-blueen">
                  {data.length === 0 && <tr>
                    <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum documento cadastrado</td>
                  </tr>}

                  {data.map(item => {
                    return (
                      <tr key={item.id}>
                        <td className="align-middle">{item.type}</td>
                        <td className="align-middle">{item.name}</td>
                        <td className="align-middle">{item.responsible}</td>
                        <td className="align-middle">
                          <Link to={`/pbo/dashboard/${props.owner}/compliance/view/${item.id}`}>
                            <Tooltip title="Ver">
                              <IconButton>
                                <VisibilityOutlined />
                              </IconButton>
                            </Tooltip>
                          </Link>

                          <Link to={`/pbo/dashboard/${props.owner}/compliance/edit/${item.id}`}>
                            <Tooltip title="Editar">
                              <IconButton>
                                <EditOutlined />
                              </IconButton>
                            </Tooltip>
                          </Link>

                          <Tooltip title="Excluir">
                            <IconButton onClick={() => renderAlert(handleDelete(item.id))}>
                              <DeleteOutline />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="d-flex justify-content-end mb-5">
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handleChangePage}
              defaultPage={pagination.page}
              color="primary"
            />
          </div>
        </div>
      )}

      {loading && (
        <div className="row">
          {' '}
          <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ComplianceList