//* React Imports
import { Route, Routes, Navigate } from "react-router-dom";

//* Components imports
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";

import AltLogin from "screen/Auth/AltLogin/AltLogin";

export const Main = () => {
  return (
    <Routes>
      {/* <Route path={"/*"} element={<HomePage />} /> */}
      <Route path="/alt-login" element={<AltLogin />} />

      <Route path="/pbo/*" element={<PrivateRoutes />} />

      <Route path={"/*"} element={<PublicRoutes />} />
      {/* <Route path="/*" element={<Navigate to="/login" />} /> */}
    </Routes>
  );
};
