import under_dev from "assets/static/under_dev.svg";

const PolicyPrivacy = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <div className="container my-5">
        <div className="card">
          <div className="card-body">
            <h2 className="text-center font-weight-bold mb-4">
              Política de Privacidade
            </h2>
            <p>
              Esta Política de Privacidade descreve como a Blueen coleta, usa,
              armazena e compartilha informações de seus usuários. Ao utilizar o
              aplicativo Blueen, você concorda com as práticas descritas nesta
              política.
            </p>

            <p style={{ fontWeight: 800 }}>Coleta de Informações</p>
            <p>
              A Blueen coleta informações que você fornece voluntariamente, como
              nome, endereço de e-mail e informações de contato. Além disso,
              podemos coletar informações técnicas, como endereços de IP, tipo
              de navegador, sistema operacional, provedor de serviços de
              internet (ISP) e informações de localização.
            </p>

            <p style={{ fontWeight: 800 }}>Uso de Informações</p>
            <p>
              As informações coletadas pela Blueen são usadas para fornecer e
              melhorar o aplicativo, além de permitir a gestão de resíduos
              sólidos com logística reversa. Podemos usar as informações para
              personalizar sua experiência, enviar comunicações de marketing,
              analisar tendências e para fins estatísticos.
            </p>

            <p style={{ fontWeight: 800 }}>Armazenamento de Informações</p>
            <p>
              A Blueen armazena as informações dos usuários em servidores
              seguros e protegidos. As informações do usuário serão armazenadas
              pelo tempo necessário para fornecer o serviço solicitado, ou
              conforme exigido por lei.
            </p>

            <p style={{ fontWeight: 800 }}>Compartilhamento de Informações</p>
            <p>
              A Blueen não compartilha informações pessoais de seus usuários com
              terceiros, exceto quando exigido por lei. Podemos compartilhar
              informações anônimas ou agregadas com nossos parceiros comerciais
              ou para fins de pesquisa.
            </p>

            <p style={{ fontWeight: 800 }}>Segurança</p>
            <p>
              A Blueen se compromete a proteger as informações pessoais de seus
              usuários e adota medidas de segurança para evitar o acesso não
              autorizado, uso indevido ou alteração de informações pessoais. No
              entanto, não podemos garantir a segurança absoluta das
              informações, já que nenhum método de transmissão pela Internet ou
              armazenamento eletrônico é 100% seguro.
            </p>

            <p style={{ fontWeight: 800 }}>Alterações nesta Política</p>
            <p>
              A Blueen pode atualizar esta Política de Privacidade
              periodicamente. Se fizermos alterações significativas,
              notificaremos os usuários por e-mail ou por meio de um aviso no
              aplicativo.
            </p>

            <p style={{ fontWeight: 800 }}>Contato</p>
            <p>
              Se você tiver alguma dúvida ou preocupação em relação a esta
              Política de Privacidade, entre em contato conosco pelo e-mail:
              contato@blueen.app
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyPrivacy;
