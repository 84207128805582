import { Box, Collapse, Container, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { ArrowDropUp } from "@mui/icons-material";
import CustomSection from "components/specific/CustomSection";
import ReactInputMask from "react-input-mask";
import { URL } from "variables";
import { phoneMaskBrazil } from "hooks/helpers";
import { ErrorAlert, SuccessAlert } from "utils/utilities";

import contact_us_bg from "assets/static/landing/contact_us2.png";
import { useSelector } from "react-redux";

function HelpMe() {
  const user = useSelector((store) => store.AppReducer.user);
  const token = localStorage.getItem("token");

  const [collapse1, setCollapse1] = useState(false);
  const [collapse2, setCollapse2] = useState(false);

  const toggleCollapse1 = () => {
    setCollapse1(!collapse1);
  };

  const toggleCollapse2 = () => {
    setCollapse2(!collapse2);
  };

  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSendContact = async () => {
    if (!email || !subject || !message) {
      ErrorAlert("Preencha todos os campos!");
      return;
    }

    setLoading(true);
    fetch(`${URL}api/help_me_blueen`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        name: user.name,
        email: email,
        subject: subject,
        message: message,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setLoading(false);
          } else {
            setEmail("");
            setSubject("");
            setMessage("");
            setLoading(false);
            SuccessAlert("Sua mensagem foi enviada com sucesso!");
          }
        } catch (err) {
          setLoading(false);
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  };

  return (
    <div className="container mt-3">
      <header>
        <h1 className="card-title text-body">
          <b>Ajuda</b>
        </h1>
        <p className="text-body">
          Confira as dúvidas frequentes e ajudas disponíveis para o sistema
          Blueen Open.
        </p>
      </header>
      <main className="my-3 mb-3">
        <div className="card">
          <div
            onClick={toggleCollapse1}
            className="card-header p-2 d-flex justify-content-between align-items-center"
          >
            <span>Categoria 1</span>
            <ArrowDropUp
              sx={{
                right: "1rem",
                top: "1rem",
                cursor: "pointer",
                backgroundColor: "transparent",
                padding: "0.5rem",
                borderRadius: "50%",
                fontSize: "2.8rem",
                transform: collapse1 ? "scale(1, -1)" : "scale(1, 1)",
                transition: "0.3s ease-in-out",
                ":hover": {
                  backgroundColor: "#E0E0E0",
                },
              }}
            />
          </div>
          <Collapse
            in={collapse1}
            className="card-body p-2 d-flex justify-content-between"
          >
            <div>
              <p>Conteúdo da categoria 1</p>
            </div>
          </Collapse>
        </div>
        <div className="card">
          <div
            onClick={toggleCollapse2}
            className="card-header p-2 d-flex justify-content-between align-items-center"
          >
            <span>Categoria 2</span>
            <ArrowDropUp
              sx={{
                right: "1rem",
                top: "1rem",
                cursor: "pointer",
                backgroundColor: "transparent",
                padding: "0.5rem",
                borderRadius: "50%",
                fontSize: "2.8rem",
                transform: collapse2 ? "scale(1, -1)" : "scale(1, 1)",
                transition: "0.3s ease-in-out",
                ":hover": {
                  backgroundColor: "#E0E0E0",
                },
              }}
            />
          </div>
          <Collapse
            in={collapse2}
            className="card-body p-2 d-flex justify-content-between"
          >
            <div>
              <p>Conteúdo da categoria 2</p>
            </div>
          </Collapse>
        </div>
      </main>
      <footer>
        <CustomSection
          bg_color="white"
          style={{
            overflow: "hidden",
            position: "relative",
            minHeight: "auto",
          }}
          id="contato"
          bg_image_ghost={contact_us_bg}
          bg_image_ghost_opacity={1}
        >
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
              },
              minHeight: "100vh",
              gap: "2rem",
              // flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "50%",
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                margin: {
                  xs: "auto auto 0 auto",
                  sm: "auto auto 0 auto",
                  md: "auto",
                },
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.6rem",
                  width: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                  padding: "2.6rem 2.4rem",
                  borderRadius: "0.4rem",
                  backdropFilter: "blur(1rem)",
                }}
              >
                <Typography
                  variant="h3"
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  Ainda está com dúvidas?
                </Typography>
                <Typography
                  variant="p"
                  style={{ marginBottom: "1.6rem", fontSize: "1.1rem" }}
                >
                  Preencha os campos abaixo e nossa equipe de suporte entrará em
                  contato por e-mail.
                </Typography>
                <TextField
                  label="E-mail para Resposta"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  label="Assunto"
                  variant="outlined"
                  fullWidth
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <TextField
                  label="Mensagem"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button
                  type="button"
                  style={{
                    backgroundColor: "#009b8f",
                    color: "#fbfbfb",
                    fontWeight: "bold",
                    borderRadius: "0.4rem",
                    cursor: "pointer",
                    padding: "0.6rem 1.8rem",
                    width: "max-content",
                    alignSelf: "flex-end",
                  }}
                  disabled={loading}
                  onClick={handleSendContact}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <span>Enviar</span>
                  )}
                </button>
              </div>
            </Box>

            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                maxWidth: "28rem",
                // background: "var(--purple-1)",
                margin: {
                  xs: "0 auto auto auto",
                  sm: "0 auto auto auto",
                  md: "auto",
                },
              }}
            ></Box>
          </Container>
        </CustomSection>
      </footer>
    </div>
  );
}

export default HelpMe;
