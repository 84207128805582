import React from 'react'
import { URL } from 'variables'
import { get } from 'hooks/requests'
import { MdInfo } from 'react-icons/md'
import { maskMoneyV2 } from 'utils/masks'
import { Reply } from '@mui/icons-material'
import { months, timeUnits } from 'utils/options'
import { Button, IconButton, Tooltip } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'

const ViewCompliance = () => {
  const { owner, id } = useParams()
  const history = useNavigate()

  const [compliance, setCompliance] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const response = await get(`${URL}api/view_compliance/${owner}/${id}`)

    if (response.httpCode !== 200) {
      history('/pbo/dashboard/destinations/compliance')
      return
    }

    const contentList = response.compliance.contents.map(item => {
      let unity = ''
      let dateLabel = ''
      let notificationLabel = ''

      if (item.type === 'Quantidade') unity = ' KG'
      if (item.type === 'Volume') unity = ' LT'
      if (item.type === 'Validade') {
        // If the accumulator is still empty, we don't add a comma before the label, otherwise we add a comma before the label
        notificationLabel = item?.notifications.reduce((accumulator, notification) => {
          if (accumulator === '') return `${notification.notify_on} ${timeUnits.find(unit => unit.value === notification.type).label} antes`
          else return `${accumulator}, ${notification.notify_on} ${timeUnits.find(unit => unit.value === notification.type).label} antes`
        }, '')
      } else {
        notificationLabel = `${item.notification}% da capacidade máxima`
      }

      if (item.type === 'Validade') {
        const [year, month, day] = item.expiration_date.split("-");
        dateLabel = `Válido até ${day}/${month}/${year}`
      }

      if (item.monthly_start_date && item.type !== 'Validade') {
        dateLabel = `Inicio em ${months.find(month => month.value === item.monthly_start_date).name}`
      }
      else if (item.annual_start_date && item.type !== 'Validade') {
        dateLabel = `Inicio em ${item.annual_start_date}`
      }

      return {
        ...item,
        unity,
        notification_label: notificationLabel,
        content_label: item.type === 'Saldo Financeiro' ? maskMoneyV2(item.content) : item.content,
        date_label: dateLabel
      }
    })

    setCompliance({ ...response.compliance, contentList })
    setLoading(false)
  }

  // Function to get the correct data according to the url
  const getOwner = () => {
    switch (owner) {
      case 'destinations':
        return 'Destino'
      case 'pontos_coleta':
        return 'Ponto de coleta'
      case 'transportadoras':
        return 'Transportadora'
      case 'veiculos':
        return 'Veículo'
      case 'motoristas':
        return 'Motorista'
      default:
        history('/pbo/dashboard/destinations/compliance')
        return
    }
  }

  return (
    <div className="mx-3">
      <h1 className="card-title text-body">
        <b>Visualizar documentação</b>
      </h1>
      <span className="text-body">Gerencie a sua documentação complience.</span>

      {!loading ?
        <section>
          <div className="col-12 mt-3">
            <p><strong>Nome: </strong>{compliance.name}</p>
          </div>

          <div className="col-12">
            <p><strong>Tipo: </strong>{compliance.type}</p>
          </div>

          <div className="col-12">
            <p><strong>{getOwner() + ': '}</strong>
              {compliance[owner].name}
            </p>
          </div>

          <div className="col-12">
            <p><strong>Responsável: </strong>{compliance.responsible}</p>
          </div>

          <div className="col-12">
            <p><strong>Valores monitorados: </strong></p>
            <div className="table-responsive text-center mt-3">
              <table id="tech-companies-1" className="table table-hover mt-2" >
                <thead className='thead-blueen'>
                  <tr>
                    <th scope='col'>Tipo</th>
                    <th scope='col'>Quantidade</th>
                    <th scope='col'>Data</th>
                    <th scope='col'>Notificar em</th>
                  </tr>
                </thead>
                <tbody className='tbody-blueen'>
                  {compliance.contents.length === 0 && <tr>
                    <td colSpan="4" style={{ textAlign: 'center' }}>Nenhum valor registrado</td>
                  </tr>}
                  {compliance.contentList.map((item, index) => (
                    <tr key={index}>
                      <td className="align-middle">{item.type}</td>
                      <td className="align-middle">{item.content ? item.content_label + item.unity : 'N/A'}</td>
                      <td className="align-middle">{item.date_label}</td>
                      <td className="align-middle">{item.notification_label} {item.type !== 'Validade' &&
                        <Tooltip
                          arrow
                          title={item.type !== 'Saldo Financeiro'
                            ? (item.content * (item.notification / 100) + item.unity)
                            : maskMoneyV2(item.content * (item.notification / 100))}
                          placement="top">
                          <IconButton size='medium'>
                            <MdInfo color='#333' />
                          </IconButton>
                        </Tooltip>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-12">
            <p><strong>Anexo: </strong></p>
            <div className="pdf-view">
              <iframe className='w-100 h-100' src={`${URL}storage/${compliance.document}`}></iframe>
            </div>
          </div>

          <div className="col-12 mt-5">
            <Link to={"/pbo/dashboard/destinations/compliance"}>
              <Button
                variant="contained"
                disableElevation
                size="large"
                color="error"
                startIcon={<Reply />}
              >
                <span>Voltar</span>
              </Button>
            </Link>
          </div>
        </section>
        :
        <div className='d-flex justify-content-center p-5'>
          <div className="spinner-border text-primary" role="status"></div>
        </div>}

    </div>
  )
}

export default ViewCompliance