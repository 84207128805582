//* React imports
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

//* Style imports
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import "./style.css";

//* Utils imports
import { ErrorAlert, WarningAlert } from "utils/utilities";
import { login } from "../../redux/AppActions";
import { URL } from "../../variables";
import validator from "validator";

//* Components imports
import SideSection from "../../components/specific/AuthSideSection/SideSection";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

function Login() {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.AppReducer.token);
  let history = useNavigate();

  const [state, setState] = useState({
    loading: false,
    loadingSave: false,
    viewPassword: false,
  });
  const [formData, setFormData] = useState({
    email: { value: "", error: false, msg: "" },
    password: { value: "", error: false, msg: "" },
  });

  function handleSubmit() {
    let variables = Object.keys(formData);
    let data = { ...formData };
    variables.forEach((item) => {
      if (data[item].value === "") {
        data[item].error = true;
        data[item].msg = "Campo em Branco";
      }
      setFormData(data);
      return;
    });

    if (formData.email.error === false && formData.password.error === false) {
      setState({ ...state, loadingSave: true });
      fetch(`${URL}api/auth/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formData.email.value,
          password: formData.password.value,
        }),
      })
        .then(async (responseLog) => {
          try {
            let resp = await responseLog.json();
            console.log(resp);
            if (resp.errors != undefined && resp.errors.length != 0) {
              let errors = Object.values(resp.errors);
              let erro = "";
              for (let i = 0; i < errors.length; i++) {
                if (i != errors.length - 1) erro += errors[i] + "\n";
                else erro += errors[i];
              }
              console.log(erro);

              setState({
                loading: false,
                loadingSave: false,
              });
              if (
                erro == "Redirecionando para a tela de login de convidados..."
              ) {
                WarningAlert(erro);
                setTimeout(() => {
                  history("/alt-login");
                }, 1000);
              } else {
                ErrorAlert(erro);
              }
            } else {
              // login sucesso
              setState({ loadingSave: false });

              localStorage.setItem("token", resp.access_token);
              localStorage.setItem("user", JSON.stringify(resp.user));
              dispatch(login({ token: resp.access_token, user: resp.user }));
            }
          } catch (err) {
            console.log(err);
            setState({
              loading: false,
              loadingSave: false,
            });
            ErrorAlert("Erro ao pegar resposta do servidor");
          }
        })
        .catch((err) => {
          console.log(err);
          setState({
            loading: false,
            loadingSave: false,
          });
          ErrorAlert(
            "Erro ao pegar resposta do servidor. Você está conectado a internet?"
          );
        });
    }
  }

  return (
    <div>
      {state.loading && (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div
          className="d-block h-100"
          style={{
            overflow: "hidden",
            backgroundSize: "cover",
            padding: 0,
            margin: 0,
          }}
        >
          <div className="d-flex flex-row vh-100">
            <SideSection />

            <main
              style={{
                width: "100%",
              }}
            >
              <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="form-horizontal">
                  <div>
                    <h1 className="card-title text-body">
                      <b>Login</b>
                    </h1>
                    <p className="card-subtitle mb-4">
                      Seja bem vindo(a). Por favor, faça o login em sua conta.
                    </p>
                  </div>
                  <div>
                    <div className="form-group-row">
                      <TextField
                        label="E-mail"
                        variant="outlined"
                        fullWidth
                        required
                        error={formData.email.error}
                        helperText={formData.email.msg}
                        value={formData.email.value}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            email: { value: e.target.value, error: false },
                          });
                        }}
                        onBlur={(e) => {
                          if (
                            e.target.value !== "" &&
                            !validator.isEmail(e.target.value)
                          ) {
                            setFormData({
                              ...formData,
                              email: {
                                value: e.target.value,
                                error: true,
                                msg: "Digite um e-mail válido",
                              },
                            });
                            return;
                          }
                        }}
                      />
                    </div>
                    <div className="mt-4">
                      <TextField
                        label="Senha"
                        variant="outlined"
                        fullWidth
                        required
                        type={!state.viewPassword ? "password" : "text"}
                        error={formData.password.error}
                        helperText={formData.password.msg}
                        value={formData.password.value}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            password: { value: e.target.value, error: false },
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setState({
                                    ...state,
                                    viewPassword: !state.viewPassword,
                                  })
                                }
                                edge="end"
                              >
                                {state.viewPassword ? (
                                  <VisibilityOffOutlined />
                                ) : (
                                  <VisibilityOutlined />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div
                        className=" d-flex justify-content-end align-items-center"
                        style={{ paddingTop: "10px" }}
                      >
                        <div>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={"/forgot-password"}
                          >
                            <a>Esqueceu sua senha?</a>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button
                        variant="contained"
                        disableElevation
                        size="large"
                        color="primary"
                        className="col-12"
                        disabled={state.loadingSave}
                        onClick={() => handleSubmit()}
                      >
                        {state.loadingSave && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        {!state.loadingSave && <span>Entrar</span>}
                      </Button>
                    </div>
                  </div>
                  {/* <div className="mt-4">
                    <p>
                      Novo por aqui?
                      <Link style={{ textDecoration: "none" }} to={"/signup"}>
                        <a> Crie uma conta</a>
                      </Link>
                    </p>
                  </div> */}
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
