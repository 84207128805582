//* React imports
import React from "react";

//* Style imports
import { IconButton, InputAdornment, TextField } from "@mui/material";

//* Utils Imports
import validator from "validator";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

function UserData({ formData, setFormData }) {
  return (
    <div>
      <div className="form-group-row">
        <TextField
          label="E-mail"
          variant="outlined"
          fullWidth
          required
          error={formData.email.error}
          helperText={formData.email.msg}
          value={formData.email.value}
          onChange={(e) => {
            setFormData({
              ...formData,
              email: { value: e.target.value, error: false },
            });
          }}
          onBlur={(e) => {
            if (e.target.value !== "" && !validator.isEmail(e.target.value)) {
              setFormData({
                ...formData,
                email: {
                  value: e.target.value,
                  error: true,
                  msg: "Digite um e-mail válido",
                },
              });
              return;
            }
          }}
        />
      </div>
      <div className="mt-4">
        <TextField
          label="Senha"
          variant="outlined"
          fullWidth
          required
          type={!formData.viewPassword ? "password" : "text"}
          error={formData.password.error}
          value={formData.password.value}
          onChange={(e) => {
            setFormData({
              ...formData,
              password: { value: e.target.value, error: false },
            });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setFormData({
                      ...formData,
                      viewPassword: !formData.viewPassword,
                    })
                  }
                  edge="end"
                >
                  {formData.viewPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
}

export default UserData;
