//* React imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//* Style imports
import {
  Alert,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { Reply } from "@mui/icons-material";

//* Utils imports
import { maskDate, maskHour, maskMoneyV2, maskNumberBr } from "utils/masks";
import { URL } from "variables";
import {
  ErrorAlert,
  SuccessAlert,
  WarningAlert,
  handleStatus,
} from "utils/utilities";
import { NumericFormat } from "react-number-format";

//* Components imports
import InfoModal from "components/common/InformativeModal/InfoModal";
import SignupModal from "components/common/SignupModals/ValidateModal";
import { BsCalendarPlus, BsCalendarX } from "react-icons/bs";

function ConfirmSchedule() {
  const [state, setState] = useState({
    loading: true,
    loadingSave: false,
    carrierLoading: false,

    dailyHours: [],
    collectData: [],
    expedientData: [],
    numericWeekdays: [],
    workToday: true,
    expedientIsOpen: false,

    carriers: [],
    vehicles: [],
    drivers: [],
    middlewareCarrierId: "",
    middlewareVehicleId: "",
    middlewareDriverId: "",
    middlewareIsOpen: true,

    equivalence: [],
    equivalenceWeight: [],
    equivalenceVolume: [],
    typedValueIsNegative: false,

    //Editable data
    appointmentDate: "",
    appointmentHour: "",
    pickupDate: "",
    receiptDate: "",
    totalWeight: "",
    totalVolume: "",
    income: "",
    expend: "",
    balance: "",
    carrierId: "",
    vehicleId: "",
    driverId: "",

    isOpen: true,

    invoiceKey: "",
    invoiceNumber: "",
    invoiceSeries: "",
    invoicePendency: false,
  });

  //props.modalData PATTERN for InfoModal.jsx
  const modalData = {
    label: "Clique aqui para saber quando essa etapa é feita.",
    title: "Confirmar Coleta",
    introText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl",
    section1: {
      title: "Lorem",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl. \nLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl.",
    },
    section2: { title: "", text: "" },
    section3: { title: "", text: "" },
    section4: { title: "", text: "" },
    confirmationMessage: "Entendi",
  };

  //Setting variables to fetches
  const reducer = useSelector((store) => store.AppReducer);
  const token = localStorage.getItem("token");
  const params = useParams();
  let solicitation_id = params.id;
  let enterprise_id = reducer.user.enterprise_id;

  let history = useNavigate();

  useEffect(() => {
    Promise.all([
      fetch(`${URL}api/get_open_solicitation/${solicitation_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      fetch(`${URL}api/get_carriers_list/${enterprise_id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    ])
      .then(([resCollect, resTransport]) =>
        Promise.all([resCollect.json(), resTransport.json()])
      )
      .then(([collect, transport]) => {
        const equivalences = collect.collect_solicitation.waste_rejects.map(
          (item) => item.volume_lt / item.weight_kg
        );
        const equivalencesWeight =
          collect.collect_solicitation.waste_rejects.map(
            (item) => item.weight_kg / item.weight
          );
        const equivalencesVolume =
          collect.collect_solicitation.waste_rejects.map(
            (item) => item.volume_lt / item.volume
          );

        fetch(`${URL}api/auth/get_org_expedient/`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            url_back: collect.collect_solicitation.organization.url_back,
          }),
        })
          .then(async (responseLog) => {
            let org_expedient = await responseLog.json();

            const day = collect.collect_solicitation?.appointment_date
              ? new Date(
                  collect.collect_solicitation.appointment_date
                ).getUTCDay()
              : null;
            const numericDays = org_expedient.expedient.map((item) =>
              Number(item.numeric_day)
            );

            setState({
              ...state,
              loading: false,
              //* Collect data
              collectData: collect.collect_solicitation,
              totalWeight: collect.collect_solicitation.total_weight,
              totalVolume: collect.collect_solicitation.total_volume,

              invoiceKey: collect.collect_solicitation.invoice_key ?? "",
              invoiceNumber: collect.collect_solicitation.invoice_number ?? "",
              invoiceSeries: collect.collect_solicitation.invoice_series ?? "",
              invoicePendency:
                collect.collect_solicitation.invoice_pendency ?? false,

              income: collect.collect_solicitation.income,
              expend: collect.collect_solicitation.expend,
              balance: collect.collect_solicitation.balance,

              //* Wastes Rejects data
              equivalence: equivalences,
              equivalenceWeight: equivalencesWeight,
              equivalenceVolume: equivalencesVolume,

              //* Bringing all dates
              appointmentDate: collect.collect_solicitation.appointment_date,
              appointmentHour: collect?.collect_solicitation
                ?.appointment_start_hour
                ? {
                    start_time:
                      collect?.collect_solicitation?.appointment_start_hour,
                    end_time:
                      collect?.collect_solicitation?.appointment_end_hour,
                  }
                : "",
              pickupDate: collect.collect_solicitation.pickup_date ?? "",
              receiptDate: collect.collect_solicitation.receipt_date ?? "",

              //* Transport data
              carrierId:
                collect.collect_solicitation?.middleware_transport
                  ?.transportadora?.id ?? "",
              vehicleId:
                collect.collect_solicitation?.middleware_transport?.veiculo
                  ?.id ?? "",
              driverId:
                collect.collect_solicitation?.middleware_transport?.motorista
                  ?.id ?? "",

              //* Checks if the transport data came from Blueen or Open
              //One of these variables is fixed, and the other one is mutable according to updates on this component
              middlewareIsOpen:
                collect.collect_solicitation.middleware_transport?.is_open,
              isOpen:
                collect.collect_solicitation.middleware_transport?.is_open,

              //* Middleware transport data
              middlewareCarrierId:
                collect.collect_solicitation?.middleware_transport
                  ?.transportadora ?? "",
              middlewareVehicleId:
                collect.collect_solicitation?.middleware_transport?.veiculo ??
                "",
              middlewareDriverId:
                collect.collect_solicitation?.middleware_transport?.motorista ??
                "",

              carriers: collect.collect_solicitation.middleware_transport
                ?.transportadora
                ? transport.carriers.concat(
                    collect.collect_solicitation.middleware_transport
                      .transportadora
                  )
                : transport.carriers,

              vehicles: collect.collect_solicitation.middleware_transport
                ?.veiculo
                ? state.vehicles.concat(
                    collect.collect_solicitation.middleware_transport.veiculo
                  )
                : [],
              drivers: collect.collect_solicitation.middleware_transport
                ?.motorista
                ? state.drivers.concat(
                    collect.collect_solicitation.middleware_transport.motorista
                  )
                : [],

              //* Expedient Data
              expedientData: org_expedient.expedient ?? "",
              numericWeekdays: numericDays,
              workToday: collect?.collect_solicitation?.appointment_date
                ? numericDays.includes(
                    new Date(
                      collect.collect_solicitation.appointment_date
                    ).getUTCDay()
                  )
                : true,
              dailyHours:
                day && !collect?.collect_solicitation?.appointment_start_hour
                  ? org_expedient?.expedient?.filter(
                      (item) => Number(item.numeric_day) === day
                    )[0]?.opening_hour
                  : [],

              //* Status data
              statusUser: getStatusObject(
                collect?.collect_solicitation?.middleware_status_user || false,
                collect?.collect_solicitation?.status_user || false
              ),
            });
          })
          .catch((err) => console.log(err));
      });
  }, []);

  const getStatusObject = (status, statusOpen) => {
    if (!status && !statusOpen) return null;

    const obj =
      statusOpen && (!status || status.created_at < statusOpen.created_at)
        ? statusOpen
        : status;

    if (!obj || !obj.description || !obj.user || !obj.created_at) return null;

    return {
      message: obj.description,
      user: obj.user.name,
      created_at: maskDate(obj.created_at),
      hour: maskHour(obj.created_at),
    };
  };

  //When any weight wastereject value is altered, a lot of fields need to update
  //This function basically do all the math based on the equivalence that was previously set up on the useeffect fetch
  //Any doubt with this calc, text @Davi or @Juca
  function handleWeight(values, item, id) {
    const valueTyped = values.value || "0";
    const newKg = Number(valueTyped) || 0;
    const newLt = parseFloat(newKg) * parseFloat(state.equivalence[id]) || 0;

    const wasteRejectItem = state.collectData.waste_rejects.find(
      (reject) => reject.waste_reject_id === item.waste_reject_id
    );

    var oldFinancialAmountKg =
      parseFloat(item.weight_kg) * parseFloat(item.value_per_kg) || 0;

    if (values.value < 0) {
      ErrorAlert("Valores negativos não são suportados.");
      setState({ ...state, typedValueIsNegative: true });
      return;
    }

    //Calc based on equivalence
    if (wasteRejectItem) {
      wasteRejectItem.weight = newKg / state.equivalenceWeight[id] || 0;
      wasteRejectItem.volume = newLt / state.equivalenceVolume[id] || 0;
      wasteRejectItem.weight_kg = newKg || 0;
      wasteRejectItem.volume_lt = newLt || 0;

      setState({
        ...state,
        collectData: { waste_rejects: wasteRejectItem },
      });
    }
    // Calculating the new total weight and volume based on the given data
    const { newTotalWeight, newTotalVolume } =
      state.collectData.waste_rejects.reduce(
        (accum, item) => ({
          newTotalWeight:
            accum.newTotalWeight + parseFloat(item.weight_kg) || 0,
          newTotalVolume:
            accum.newTotalVolume + parseFloat(item.volume_lt) || 0,
        }),
        { newTotalWeight: 0, newTotalVolume: 0 }
      );

    //Calculating the new financial amount (based on kg)
    var newFinancialAmountKg = newKg * parseFloat(item.value_per_kg) || 0;
    var newIncome =
      parseFloat(state.income) + newFinancialAmountKg - oldFinancialAmountKg ||
      0;
    var newExpend =
      parseFloat(state.expend) + newFinancialAmountKg - oldFinancialAmountKg ||
      0;

    //Here we decide if the new financial amount is a Income or an Expend
    switch (item.financial_type) {
      case "Receita":
        let balanceRec = newIncome - state.expend;
        balanceRec =
          Math.round(parseFloat(balanceRec) * 100) / 100 == 0
            ? 0
            : Math.round(parseFloat(balanceRec) * 100) / 100;
        setState({
          ...state,
          income: newIncome.toFixed(2) || 0,
          balance: balanceRec.toFixed(2),
          totalWeight: newTotalWeight || 0,
          totalVolume: newTotalVolume || 0,
          typedValueIsNegative: false,
        });
        break;
      case "Despesa":
        let balanceDesp = state.income - newExpend;
        balanceDesp =
          Math.round(parseFloat(balanceDesp) * 100) / 100 == 0
            ? 0
            : Math.round(parseFloat(balanceDesp) * 100) / 100;
        setState({
          ...state,
          expend: newExpend.toFixed(2) || 0,
          balance: balanceDesp.toFixed(2),
          totalWeight: newTotalWeight || 0,
          totalVolume: newTotalVolume || 0,
          typedValueIsNegative: false,
        });
        break;
      default:
        WarningAlert("Despesa evitada, não é possível alterar esse valor.");
        break;
    }
  }

  //With the working hours of Blueen company in consideration, this function maps the available days for rescheduling the collect.
  function handleDate(value) {
    let day = new Date(value).getUTCDay();
    if (!state.numericWeekdays.includes(day)) {
      setState({
        ...state,
        appointmentDate: "",
        appointmentHour: "",
        dailyHours: [],
        workToday: false,
      });
    } else {
      state.expedientData.map((item, id) => {
        if (item.numeric_day == day) {
          setState({
            ...state,
            appointmentDate: value,
            appointmentHour: "",
            dailyHours: item.opening_hour,
            workToday: true,
          });
        }
      });
    }
    if (value === "") {
      setState({
        ...state,
        appointmentDate: "",
        appointmentHour: "",
        dailyHours: [],
        workToday: true,
      });
      return;
    }
  }

  //Open or close the calendar with the expedient data
  function handleCalendar() {
    setState({ ...state, expedientIsOpen: !state.expedientIsOpen });
  }

  //Other fields depends on the carrier, so it's a more complex function to handle every change
  function handleCarrier(value) {
    if (!state.middlewareIsOpen && value == state.middlewareCarrierId.id) {
      setState({
        ...state,
        carrierLoading: false,
        carrierId: value,
        vehicleId: state.middlewareVehicleId.id,
        driverId: state.middlewareDriverId.id,
        isOpen: false,
        drivers: [
          {
            id: state.middlewareDriverId.id,
            name: state.middlewareDriverId.name,
          },
        ],
        vehicles: [
          {
            id: state.middlewareVehicleId.id,
            modelo: state.middlewareVehicleId.modelo,
          },
        ],
      });
      return;
    }

    //Every carrier change, a refetch is done
    setState({ ...state, carrierLoading: true });
    fetch(`${URL}api/get_drivers_and_vehicles/${value}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        setState({
          ...state,
          carrierLoading: false,
          carrierId: value,
          vehicleId: "",
          driverId: "",
          isOpen: true,
          drivers: resp.drivers,
          vehicles: resp.vehicles,
        });
      })
      .catch((err) => {
        setState({ ...state, carrierLoading: false });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  //When creating a carrier, the new object needs to be added on the previously array
  const updateState = (item, data, item_id, id) => {
    setState((prevState) => ({
      ...prevState,
      [item]: [...prevState[item], { ...data }],
      [item_id]: id,
      vehicleId: "",
      driverId: "",
      isOpen: true,
      drivers: [],
      vehicles: [],
    }));
  };

  const handleSituacao = () => {
    let status = "Rascunho";
    if (state.appointmentDate) status = "Agendado";
    if (state.pickupDate) status = "Coletado";
    if (state.receiptDate) status = "Recebido";
    return status;
  };

  //This function confirms the schedule and updates the collect solicitation
  function handleSubmit() {
    if (state.typedValueIsNegative) {
      ErrorAlert("Valores negativos não são suportados.");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return;
    }

    //If i put any value on carrierId, validates if every other transport data is filled
    if (state.carrierId && (!state.driverId || !state.vehicleId)) {
      ErrorAlert("Preencha os dados de transporte corretamente");
      return;
    }

    let hasInvalidQuantities = state.collectData.waste_rejects.some((item) => {
      return (
        !item.weight_kg ||
        item.weight_kg == 0 ||
        !item.volume_lt ||
        item.volume_lt == 0
      );
    });

    if (hasInvalidQuantities) {
      ErrorAlert("Quantidades zeradas não são permitidas");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return;
    }

    let formData = { ...state.collectData };

    //Saving financial data
    formData.income = state.income;
    formData.expend = state.expend;
    formData.balance = state.balance;
    formData.total_weight = state.totalWeight;
    formData.total_volume = state.totalVolume;

    //Saving dates
    formData.appointment_date = state.appointmentDate;
    formData.appointment_start_hour = state.appointmentHour.start_time;
    formData.appointment_end_hour = state.appointmentHour.end_time;
    formData.pickup_date = state.pickupDate;
    formData.receipt_date = state.receiptDate;

    //Saving fiscal data
    formData.invoice_key = state.invoiceKey;
    formData.invoice_number = state.invoiceNumber;
    formData.invoice_series = state.invoiceSeries;

    //Saving transport data
    formData.carrier_id = state.carrierId;
    formData.vehicle_id = state.vehicleId;
    formData.driver_id = state.driverId;

    formData.is_open = state.isOpen;

    formData.user_id = reducer.user.id;
    formData.situacao = handleSituacao();

    if (!formData.appointment_date) {
      ErrorAlert("Dados de agendamento são obrigatórios");
      window.scrollTo(500, 0);
      return;
    }
    if (!formData.appointment_start_hour) {
      ErrorAlert("Dados de agendamento são obrigatórios");
      window.scrollTo(500, 0);
      return;
    }

    fetch(`${URL}api/confirm_collect`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setState({ ...state, loadingSave: false });
          } else {
            history("/pbo/dashboard/confirmed-collect-solicitations");
            SuccessAlert("Registros da coleta atualizados com sucesso.");
          }
        } catch (err) {
          setState({ ...state, loadingSave: true });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setState({ ...state, loadingSave: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div>
          <header>
            <h1 className="card-title text-body">
              <b>Confirmar Coleta</b>
            </h1>
            <span className="text-body">
              Confirme os dados da coleta. <InfoModal modalData={modalData} />
            </span>
          </header>
          <hr />
          {state.collectData.appointment_date &&
            state.collectData.pickup_date &&
            state.collectData.receipt_date && (
              <>
                <Alert severity="warning">
                  Caso todos os dados estejam preenchidos, aguarde sua
                  organização parceira finalizar essa coleta.
                </Alert>
              </>
            )}
          <main className="mt-4">
            <div id="waste_rejects">
              <h4>
                <strong>Dados dos Resíduos e Rejeitos</strong>
              </h4>
              <div className="row">
                <strong className="align-middle">
                  {state.statusUser
                    ? `Alterado por ${state.statusUser.user}`
                    : "Não alterado"}
                </strong>
                <span className="align-middle">
                  {state.statusUser ? (
                    <span>
                      {state.statusUser.message}
                      <span className="small bold">
                        {" "}
                        - {state.statusUser.created_at} às{" "}
                        {state.statusUser.hour}
                      </span>
                    </span>
                  ) : (
                    "Descrição de alterações"
                  )}
                </span>
              </div>
              <div className="table-responive text-center mt-4">
                <table className="table table-hover">
                  <thead className="thead-blueen">
                    <tr>
                      <th scope="col">Material</th>
                      <th scope="col">Peso</th>
                      <th scope="col">Volume</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-blueen">
                    {state.collectData.waste_rejects.map((item, id) => (
                      <tr key={id}>
                        <td className="align-middle">
                          {item.waste_reject.name}
                        </td>
                        <td>
                          <NumericFormat
                            customInput={TextField}
                            valueIsNumericString
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            decimalScale={2}
                            decimalSeparator=","
                            sx={{ m: 1, width: "15ch" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  kg
                                </InputAdornment>
                              ),
                            }}
                            value={item.weight_kg}
                            onValueChange={(values) =>
                              handleWeight(values, item, id)
                            }
                          />
                        </td>
                        <td className="align-middle">
                          {maskNumberBr(item.volume_lt)} L
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <hr className="my-4" />
            <div className="row">
              <div id="despacho" className="col-12">
                <h4>
                  <strong>Dados do Despacho</strong>
                </h4>
                <div id="situation" className="mt-4">
                  <div className="my-3">
                    <strong>Status: </strong>
                    {state.collectData.status}
                    {handleStatus(state.collectData.status)}
                  </div>
                  <div className="my-3">
                    <strong>Data de Criação da Solicitação: </strong>
                    {state.collectData.created_at.replace(
                      /(\d*)-(\d*)-(\d*).*/,
                      "$3/$2/$1"
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-4 my-3">
                      <strong>
                        Data agendamento<b className="text-danger">*</b>
                      </strong>
                      <div className="d-flex">
                        <TextField
                          margin="normal"
                          variant="outlined"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={state.appointmentDate}
                          onChange={(e) => handleDate(e.target.value)}
                        ></TextField>
                        {!state.expedientIsOpen && (
                          <Tooltip title="Abrir expediente">
                            <button
                              onClick={() => handleCalendar()}
                              className="btn btn-primary m-4 h-50"
                            >
                              <BsCalendarPlus />
                            </button>
                          </Tooltip>
                        )}
                        {state.expedientIsOpen && (
                          <Tooltip title="Fechar expediente">
                            <button
                              onClick={() => handleCalendar()}
                              className="btn btn-danger m-4 h-50"
                            >
                              <BsCalendarX />
                            </button>
                          </Tooltip>
                        )}
                      </div>
                      {state.dailyHours.length == 0 &&
                        state.workToday &&
                        state.appointmentDate && (
                          <Alert severity="info">
                            Entre {state.collectData.appointment_start_hour} e{" "}
                            {state.collectData.appointment_end_hour}
                          </Alert>
                        )}
                      {state.workToday && !state.appointmentDate && (
                        <Alert severity="error">
                          Escolha um dia para o agendamento
                        </Alert>
                      )}
                      {!state.workToday && (
                        <Alert severity="info">
                          A empresa não funciona nesse dia da semana!
                        </Alert>
                      )}
                      {state.dailyHours.length > 0 && (
                        <div className="form-group row">
                          {state.dailyHours.map((item, id) => (
                            <div key={id} className="col-12 col-sm-6 mt-3">
                              <div className="card text-center">
                                <div className="card-body">
                                  <h5 className="card-title">
                                    {item.start_time} - {item.end_time}
                                  </h5>
                                  {state.appointmentHour.id != item.id && (
                                    <button
                                      onClick={() => {
                                        setState({
                                          ...state,
                                          appointmentHour: item,
                                        });
                                      }}
                                      className="btn bg-primary text-light"
                                    >
                                      Selecionar
                                    </button>
                                  )}
                                  {state.appointmentHour.id == item.id && (
                                    <button
                                      className="btn bg-primary text-light"
                                      disabled
                                    >
                                      Selecionado
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {state.expedientIsOpen && (
                      <div className="col-12 col-md-6 mx-4">
                        <div className="my-3 col-12">
                          <ul className="list-group text-capitalize">
                            <li className="list-group-item bg-primary text-light">
                              <b>Dias & horários de funcionamento</b>
                            </li>
                            {state.expedientData.map((item, id) => (
                              <li
                                key={id}
                                className="list-group-item d-flex justify-content-between"
                              >
                                <p className="pt-3">
                                  <b>{item.weekday}: </b>
                                  {item.opening_hour.map((item2, id2) => (
                                    <React.Fragment key={id2}>
                                      {item2.start_time} - {item2.end_time}
                                      {id2 < item.opening_hour.length - 1 &&
                                        " | "}
                                    </React.Fragment>
                                  ))}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 my-3">
                    <strong>Data coleta</strong>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      type="date"
                      value={state.pickupDate}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) =>
                        setState({ ...state, pickupDate: e.target.value })
                      }
                    ></TextField>
                  </div>
                  <div className="col-12 col-md-4 my-3">
                    <strong>Data de recebimento</strong>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={state.receiptDate}
                      onChange={(e) =>
                        setState({ ...state, receiptDate: e.target.value })
                      }
                    ></TextField>
                  </div>
                  <div className="my-3">
                    <strong>Peso Total: </strong>
                    {maskNumberBr(state.totalWeight)} kg
                  </div>
                  <div className="my-3">
                    <strong>Volume Total: </strong>
                    {maskNumberBr(state.totalVolume)} L
                  </div>
                  <div className="my-3">
                    <strong>Receita: </strong>
                    {maskMoneyV2(state.income)}
                  </div>
                  <div className="my-3">
                    <strong>Despesas: </strong>
                    {maskMoneyV2(state.expend)}
                  </div>
                  <div className="my-3">
                    <strong>Saldo: </strong>
                    {maskMoneyV2(state.balance)}
                  </div>
                </div>
                <hr />
                {/* DADOS TRANSPORTE */}
                <div id="transporte" className="col-12">
                  <h4>
                    <strong>Dados do Transporte</strong>
                  </h4>
                  <div id="situation" className="mt-4">
                    <div className="col-12 my-3">
                      <strong>
                        Transportadora
                        {state.carrierId && <b className="text-danger">*</b>}
                      </strong>
                      <TextField
                        className="form-control"
                        variant="outlined"
                        fullWidth
                        select
                        value={state.carrierId}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SignupModal
                                type={"CarrierQuickSignup"}
                                updateState={updateState}
                              />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          handleCarrier(e.target.value);
                        }}
                      >
                        {state.carriers.map((item, id) => (
                          <MenuItem key={id} value={item.id ?? " "}>
                            {item.company_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="col-12 my-3">
                      <strong>
                        Veículo
                        {state.carrierId && <b className="text-danger">*</b>}
                      </strong>
                      <TextField
                        className="form-control"
                        variant="outlined"
                        fullWidth
                        select
                        value={state.vehicleId}
                        disabled={state.carrierLoading}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SignupModal
                                isOpen={!state.isOpen}
                                type={"VehicleQuickSignup"}
                                carriers={state.carriers}
                                carrierId={state.carrierId}
                                handleCarrier={handleCarrier}
                              />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setState({ ...state, vehicleId: e.target.value });
                        }}
                      >
                        {state.vehicles.map((item, id) => (
                          <MenuItem key={id} value={item.id ?? " "}>
                            {item.modelo}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="col-12 my-3">
                      <strong>
                        Motorista
                        {state.carrierId && <b className="text-danger">*</b>}
                      </strong>
                      <TextField
                        className="form-control"
                        variant="outlined"
                        fullWidth
                        select
                        disabled={state.carrierLoading}
                        value={state.driverId}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SignupModal
                                type={"DriverQuickSignup"}
                                carriers={state.carriers}
                                carrierId={state.carrierId}
                                handleCarrier={handleCarrier}
                                isOpen={!state.isOpen}
                              />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          setState({ ...state, driverId: e.target.value });
                        }}
                      >
                        {state.drivers.map((item, id) => (
                          <MenuItem key={id} value={item.id ?? " "}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <hr />
                {/* NOTA FISCAL */}
                {!state.invoicePendency && (
                  <div id="fiscal" className="col-12">
                    <h4>
                      <strong>Dados Fiscais</strong>
                    </h4>
                    <div id="situation" className="mt-4">
                      <div className="mb-3">
                        <strong>Chave da NF-e</strong>
                        <TextField
                          variant="outlined"
                          fullWidth
                          value={state.invoiceKey}
                          onChange={(e) =>
                            setState({ ...state, invoiceKey: e.target.value })
                          }
                        />
                      </div>
                      <div className="d-flex justify-content-between row mb-3">
                        <div className="form-group-row col-sm-5 col-12">
                          <strong>Número da NF-e</strong>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            value={state.invoiceNumber}
                            onChange={(e) => {
                              setState({
                                ...state,
                                invoiceNumber: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="form-group-row col-sm-6 col-12">
                          <strong>Série da NF-e</strong>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            value={state.invoiceSeries}
                            onChange={(e) => {
                              setState({
                                ...state,
                                invoiceSeries: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {state.invoicePendency && (
                  <Alert severity="info">
                    Os dados fiscais serão preenchidos pela sua organização
                    parceira. Contate-os diretamente para mais informações.
                  </Alert>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-between my-5">
                <Link to={"/pbo/dashboard/confirmed-collect-solicitations"}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    color="error"
                    disabled={state.loadingSave}
                    startIcon={<Reply />}
                  >
                    <span>Voltar</span>
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                  disabled={state.loadingSave}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {state.loadingSave && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!state.loadingSave && <span>Confirmar</span>}
                </Button>
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default ConfirmSchedule;
