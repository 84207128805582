//* Style imports
import under_dev from "assets/static/under_dev.svg";

const UnderConstruction = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <img src={under_dev} alt="Em desenvolvimento"
        style={{
          height: "100%",
          maxHeight: "60%",
          objectFit: "contain",
          marginTop: "10rem",
        }}
      />
    </div>
  );
}

export default UnderConstruction;