//* React imports
import React, { useLayoutEffect, useRef, useState } from "react";

//* Style imports
import {
  Box,
  IconButton,
  Modal,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Theme from "utils/theme";

//* Utils imports
import {
  AddHomeWork,
  Close,
  LocalShipping,
  PersonAddAlt1,
} from "@mui/icons-material";

//* Components imports
import CarrierModal from "./QuickSignupModals/CarrierModal";
import VehicleModal from "./QuickSignupModals/VehicleModal";
import DriverModal from "./QuickSignupModals/DriverModal";
import UnderConstruction from "../UnderConstruction/UnderConstruction";

const style = {
  position: "absolute",
  left: "50%",
  transform: "translate(0, 0, 0, 50%)",
  width: "50%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  "@media(max-width: 1000px)": {
    width: "90%",
    left: "5%",
  },
};

const SignupModal = (props) => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [state, setState] = useState({
    redirect: false,
    loading_screen: true,
  });

  const [height, setHeight] = useState(0);
  const [rerender, setRerender] = useState(0);
  const content = useRef();
  const modalRef = useRef();

  useLayoutEffect(() => {
    if (open) {
      if (content.current !== undefined && content.current !== null)
        setHeight(content.current.clientHeight);
      else setRerender((item) => item + 1);
      content.current = document.getElementById("ancor");
    }
  }, [open, rerender, content.current?.clientHeight]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function validateModal() {
    switch (props.type) {
      case "CarrierQuickSignup":
        return (
          <CarrierModal close={handleClose} updateState={props.updateState} />
        );
      case "VehicleQuickSignup":
        return (
          <VehicleModal
            close={handleClose}
            carriers={props.carriers}
            carrierId={props.carrierId}
            handleCarrier={props.handleCarrier}
          />
        );
      case "DriverQuickSignup":
        return (
          <DriverModal
            close={handleClose}
            carriers={props.carriers}
            carrierId={props.carrierId}
            handleCarrier={props.handleCarrier}
          />
        );
      default:
        return <UnderConstruction />;
    }
  }

  function handleIcon() {
    switch (props.type) {
      case "CarrierQuickSignup":
        return <AddHomeWork />;
      case "VehicleQuickSignup":
        return <LocalShipping />;
      case "AddVehicle":
        return "ADICIONAR VEÍCULO";
      case "AddDriver":
        return "ADICIONAR MOTORISTA";
      default:
        return <PersonAddAlt1 />;
    }
  }

  return (
    <>
      <button
        onClick={handleOpen}
        disabled={props.isOpen}
        className="btn btn-primary"
      >
        {handleIcon()}
      </button>
      <Modal
        ref={modalRef}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={Theme}>
          <Box sx={style}>
            <>
              <div id="ancor" ref={content}>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <div className="text-center">
                    <h4
                      className="mt-0 header-title"
                      style={{ fontSize: "1.5rem", color: "black" }}
                    >
                      Cadastro Rápido
                    </h4>
                    <p style={{ fontSize: "1rem", color: "black" }}></p>
                  </div>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {validateModal()}
                </Typography>
              </div>
            </>
          </Box>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default SignupModal;
