import React from "react";
import { useDispatch } from "react-redux";
import { Main } from "./routes/Main";
import "./theme.css";

function App() {
  const dispatch = useDispatch();
  let token = localStorage.getItem("token");
  let user = localStorage.getItem("user");

  if (user == null || user == undefined) {
    user = {};
  } else {
    user = JSON.parse(user);
  }
  dispatch({ type: "login", payload: { token: token, user: user } });

  return (
    <div className="App white-mode">
      <Main />
    </div>
  );
}

export default App;
