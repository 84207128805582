//* Libraries imports
import { createSlice } from "@reduxjs/toolkit";

const ui = createSlice({
  name: "ui",
  initialState: {
    collapsed: false,
    toggled: false,
  },
  reducers: {
    toggleSidebar(state, action) {
      state.toggled = action.payload;
    },
    collapseSidebar(state, action) {
      state.collapsed = action.payload;
    },
  },
});

export const { toggleSidebar, collapseSidebar } = ui.actions;
export default ui.reducer;
