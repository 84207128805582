//* React imports
import React from "react";

function UserTypes({ icon, description, formData, setFormData, state, setState }) {
//* Singular user types
  return (
    <div className="d-flex align-items-center justify-content-center">
      <div
        className="text-center col-8"
        style={{ margin: "0", padding: "0" }}
        onClick={() => {
          setFormData({ ...formData, type: description });
          setState({...state, page: 2})
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <img
            style={{
              width: "12vh",
              height: "12vh",
              cursor: "pointer",
              borderRadius: "50%",
            }}
            src={icon}
            alt={description}
          />
        </div>
        <div className="typeDescription">
          <a className="p-1">{description}</a>
        </div>
      </div>
    </div>
  );
}

export default UserTypes;
