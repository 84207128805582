//* React imports
import { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

//* Style imports
import { Box } from "@mui/system";
import styles from "./styles.module.css";
import logo_white from "../../../../assets/static/logo/green_white.png";
import arrow_purple from "../../../../assets/static/arrow_purple.png";

const nav_links = [
  {
    name: "Home",
    element_id: "#home",
  },
  {
    name: "Quem somos",
    element_id: "#quem-somos",
  },
  {
    name: "Funcionalidades",
    element_id: "#funcionalidades",
  },
  {
    name: "Parceiros",
    element_id: "#parceiros",
  },
  {
    name: "Contato",
    element_id: "#contato",
  },
];

const Navbar = ({ on_top, setOnTop, setForceOnTop }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const scrollTo = (element_id) => {
    console.log(location.pathname);

    if (location.pathname === "/") {
      console.log("uai so");
      const element = document.querySelector(element_id);
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      // alert("codigo para redirecionar a home comentado para testes futuros");
      navigate("/", { replace: true });
      // after navigate to home, scroll to element
      setTimeout(() => {
        const element = document.querySelector(element_id);
        element.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    if (location.pathname !== "/") {
      setOnTop(false);
      setForceOnTop(true);
    } else {
      setOnTop(true);
      setForceOnTop(false);
    }
  }, [location]);

  return (
    <nav
      // NavbarContainer = main styles
      // Transition = main transition
      // NavbarOnTop = styles when navbar is on top
      className={
        styles.NavbarContainer +
        " Transition-1 " +
        (on_top ? " " + styles.NavbarOnTop : "")
      }
      style={{
        backgroundColor: on_top ? "transparent" : "var(--purple-1)",
        boxShadow: "0 0 0 0.1rem rgba(255,255, 255, 0.2)",
        zIndex: 100,
        // boxShadow: on_top
        //   ? "none"
        //   : "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px, rgba(0, 0, 0, 0.1) 0px 7px 29px 0px",
      }}
    >
      <div
        className="Transition-1"
        style={{
          maxWidth: on_top ? "100%" : "1536px",
          width: "100%",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: on_top
            ? "0.6rem 2rem 0.6rem 2rem"
            : "0.6rem 2rem 0.6rem 2rem",
          marginBottom: "0",
          borderRadius: "5rem",
        }}
      >
        <a href="/">
          <img
            className="Transition-1"
            src={logo_white}
            alt="Logotipo da Bluee Open"
            style={{
              width: on_top ? "10rem" : "8rem",
              filter: on_top
                ? "brightness(var(--image-brightness))"
                : "inherit",
            }}
          />
        </a>

        {/* main items */}
        <Box
          className="Transition-1"
          sx={{
            display: "flex",
            position: "relative",
            gap: on_top
              ? {
                  md: "1.5rem",
                  lg: "3rem",
                }
              : {
                  md: "1rem",
                  lg: "2rem",
                },
            // top: "0",
          }}
        >
          {nav_links.map((item, index) => (
            <div className={styles.navItemsWrapper} key={index}>
              <Box
                className="Transition-1"
                // component="a"
                // href={item.link}
                onClick={() => scrollTo(item.element_id)}
                sx={{
                  cursor: "pointer",
                  color: on_top
                    ? "var(--text-main-2)"
                    : "var(--text-reverse-2)",
                  fontWeight: "bold",
                  padding: "0.4rem",
                  // letterSpacing: on_top ? "0.2rem" : "auto",
                  "&:hover": {
                    color: on_top ? "var(--purple-1)" : "var(--text-reverse-2)",
                    transition: "color 0.3s",
                  },
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    width: "100%",
                    height: "2px",
                    bottom: "0",
                    left: "0",
                    backgroundColor: on_top
                      ? "var(--purple-1)"
                      : "var(--text-reverse-2)",
                    transform: "scaleX(0)",
                    transformOrigin: "bottom right",
                    transition: "transform 0.3s",
                  },
                  "&:hover:after": {
                    transform: "scaleX(1)",
                    transformOrigin: "bottom left",
                  },
                }}
              >
                {item.name}
              </Box>
            </div>
          ))}
        </Box>

        <div
          className="Transition-1"
          style={{
            width: on_top ? "10rem" : "8rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Link to={"/login"}>
            <button
              className={`Transition-1 ${styles.loginButton}`}
              style={{
                position: "relative",
                letterSpacing: "0.1rem",
              }}
            >
              Entrar
              <img
                className="Transition-1"
                src={arrow_purple}
                alt="Entrar"
                style={{
                  position: "absolute",
                  top: "80%",
                  left: "10%",
                  transform: "translate(-50%, -50%)",
                  opacity: "0",
                }}
              />
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
