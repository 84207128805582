import CustomSection from "components/specific/CustomSection";
import { Container, Box, Typography } from "@mui/material";
import crew from "assets/static/landing/crew.svg";
import about_us_bg from "assets/static/landing/about_us_bg.png";

const QuemSomos = () => {
  return (
    <CustomSection
      bg_color="var(--background-1)"
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      id="quem-somos"
      bg_image_ghost={about_us_bg}
      bg_image_ghost_opacity={1}
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
            minHeight: "100vh",
            gap: "2rem",
            // flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              maxWidth: "28rem",
              margin: {
                xs: "0 auto auto auto",
                sm: "0 auto auto auto",
                md: "auto",
              },
            }}
          >
            {/* <img
              src={crew}
              alt="Quem somos"
              style={{
                width: "100%",
                width: "150%",
                position: "relative",
                zIndex: "0",
              }}
            /> */}
          </Box>

          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "60%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              margin: {
                xs: "auto auto 0 auto",
                sm: "auto auto 0 auto",
                md: "auto",
              },
            }}
          >
            <Box
              sx={{
                borderRight: {
                  xs: "none",
                  sm: "0.2rem solid var(--purple-2)",
                  md: "0.2rem solid var(--purple-2)",
                },
                padding: "1rem",
                paddingLeft: {
                  xs: "0",
                  sm: "2rem",
                  md: "2rem",
                },
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                zIndex: 10,
                textAlign: "right",
                alignItems: "flex-end",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  maxWidth: "80%",
                  fontSize: {
                    xs: "1rem",
                    sm: "1.2rem",
                    md: "1.4rem",
                  },
                  color: "var(--purple-1)",
                  fontWeight: "bold",
                }}
              >
                Nascemos com o propósito de unir inovação e sustentabilidade,
                para gerar impacto ambiental e social positivo.
              </Typography>

              <Typography
                variant="h2"
                sx={{
                  maxWidth: "80%",
                  fontWeight: "bold",
                  fontSize: {
                    xs: "1.2rem",
                    sm: "2rem",
                    md: "3rem",
                  },
                }}
              >
                A Blueen é uma Cleantech especializada em Economia Circular e
                ESG.
              </Typography>

              <Typography
                variant="p"
                sx={{
                  fontWeight: "normal",
                  maxWidth: "80%",
                  fontSize: {
                    xs: "0.9rem",
                    sm: "1rem",
                    md: "1.1rem",
                  },
                  textAlign: "justify",
                  direction: "rtl",
                  fontWeight: "bold",
                }}
              >
                Nosso compromisso é com a sustentabilidade e acreditamos que os
                resíduos sólidos são pilar fundamental para tornar os negócios a
                prova de futuro. Trabalhamos junto aos nossos parceiros para
                tornar a Economia Circular mais acessível, dinâmica e rentável
                para as empresas, através de nossa solução inovadora
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </CustomSection>
  );
};

export default QuemSomos;
