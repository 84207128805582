import React from 'react'
import AddCompliance from './AddCompliance'
import { useNavigate, useParams } from 'react-router-dom';
import { get } from 'hooks/requests';
import { URL } from 'variables';
import { months, timeUnits } from 'utils/options';
import { maskMoneyV2 } from 'utils/masks';

const EditCompliance = () => {
  const { owner, id } = useParams()
  const history = useNavigate()

  const [editData, setEditData] = React.useState('')
  const [loading, setLoading] = React.useState(true)
  const [selectOptions, setSelectOptions] = React.useState([])

  React.useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const response = await get(`${URL}api/get_compliance/${owner}/${id}`)
    if (response.httpCode !== 200) {
      history('/pbo/dashboard/destinations')
      return
    }

    const contents = response.compliance.contents.map(item => {
      let unity = ''
      let dateLabel = ''
      let notificationLabel = ''
      // Unity labels
      if (item.type === 'Quantidade') unity = ' KG'
      if (item.type === 'Volume') unity = ' LT'
      // Notification labels
      if (item.type === 'Validade') {
        // If the accumulator is still empty, we don't add a comma before the label, otherwise we add a comma before the label
        notificationLabel = item?.notifications.reduce((accumulator, notification) => {
          if (accumulator === '') return `${notification.notify_on} ${timeUnits.find(unit => unit.value === notification.type).label} antes`
          else return `${accumulator}, ${notification.notify_on} ${timeUnits.find(unit => unit.value === notification.type).label} antes`
        }, '')
      } else {
        notificationLabel = `${item.notification}% da capacidade máxima`
      }

      // Date labels
      if (item.type === 'Validade') {
        const [year, month, day] = item.expiration_date.split("-");
        dateLabel = `Válido até ${day}/${month}/${year}`
      }

      if (item.monthly_start_date && item.type !== 'Validade') {
        dateLabel = `Inicio em ${months.find(month => month.value === item.monthly_start_date).name}`
      }
      else if (item.annual_start_date && item.type !== 'Validade') {
        dateLabel = `Inicio em ${item.annual_start_date}`
      }

      return {
        ...item,
        unity,
        notification_label: notificationLabel,
        content_label: item.type === 'Saldo Financeiro' ? maskMoneyV2(item.content) : item.content,
        date_label: dateLabel
      }
    })

    setEditData({ ...response.compliance, contents })
    setSelectOptions(response.select_options)
    setLoading(false)
  }

  return (
    <>
      {!loading
        ?
        <AddCompliance
          editSelectOptions={selectOptions}
          editData={editData}
        />
        :
        <div className='d-flex justify-content-center p-5'>
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      }
    </>
  )
}

export default EditCompliance