//* React imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

//* Style imports
import {
  Alert,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { EditOutlined, Reply } from "@mui/icons-material";

//* Utils imports
import validator from "validator";
import { URL } from "variables";
import { ErrorAlert, SuccessAlert, handleStatus } from "utils/utilities";
import { maskMoneyV2, maskNumberBr } from "utils/masks";
import EnterpriseData from "screen/Auth/SignUp/Steps/EnterpriseData";

function AddDriver() {
  const [state, setState] = useState({
    loading: true,
    loadingSave: false,
    carriers: [],
    categories: ["A", "B", "C", "D", "E"],
  });

  const [formData, setFormData] = useState({
    name: { value: "", error: false, msgError: "" },
    email: { value: "", error: false, msgError: "" },
    cpf: { value: "", error: false, msgError: "" },
    cnhNumber: { value: "", error: false, msgError: "" },
    cnhType: { value: "", error: false, msgError: "" },
    description: { value: "", error: false, msgError: "" },
    carrier: { value: "", error: false, msgError: "" },
  });

  //Setting variables to fetches
  const token = localStorage.getItem("token");
  const enterprise_id = useSelector(
    (store) => store.AppReducer.user.enterprise_id
  );

  let history = useNavigate();

  useEffect(() => {
    fetch(`${URL}api/get_carriers_list/${enterprise_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        setState({
          ...state,
          loading: false,
          carriers: resp.carriers,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  function handleSubmit() {
    let hasError = false;
    let variables = ["name", "email", "cpf", "cnhNumber", "carrier"];
    let data = { ...formData };
    variables.forEach((item) => {
      if (!data[item].value) {
        data[item].error = true;
        data[item].msgError = "Campo em Branco";
        hasError = true;
      }
      setFormData(data);
      return;
    });
    if (hasError) return;

    if (!validator.isEmail(formData.email.value)) {
      setFormData({
        ...formData,
        email: {
          value: formData.email.value,
          error: true,
          msgError: "Digite um formato de e-mail válido",
        },
      });
      return;
    }

    if (formData.cnhNumber.value.length < 9) {
      setFormData({
        ...formData,
        cnhNumber: {
          value: formData.cnhNumber.value,
          error: true,
          msgError: "O campo possui 9 dígitos",
        },
      });
      return;
    }
    if (formData.cpf.value.length < 11) {
      setFormData({
        ...formData,
        cpf: {
          value: formData.cpf.value,
          error: true,
          msgError: "O campo possui 11 dígitos",
        },
      });
      return;
    }

    setState({ ...state, loadingSave: true });
    let driverData = { ...state.driverData };

    driverData.name = formData.name.value;
    driverData.email = formData.email.value;
    driverData.cpf = formData.cpf.value;
    driverData.cnh_type = formData.cnhType.value;
    driverData.cnh_number = formData.cnhNumber.value;
    driverData.description = formData.description.value;

    driverData.carrier_id = formData.carrier.value;

    fetch(`${URL}api/quick_store_carrier_driver/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(driverData),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          SuccessAlert("Motorista cadastrado com sucesso!");
          history("/pbo/dashboard/drivers");
        } catch (err) {
          console.log(err);
          setState({ ...state, loadingSave: false });
          ErrorAlert("Erro ao pegar resposta do servidor.");
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loadingSave: false });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div>
          <header>
            <h1 className="card-title text-body">
              <b>Adicionar Motorista</b>
            </h1>
            <span className="text-body">
              Adicione um motorista e o vincule com uma transportadora.
            </span>
          </header>
          <hr />
          <main className="mt-4">
            <div className="form-group-row mt-4">
              <TextField
                label="Nome do motorista"
                variant="outlined"
                fullWidth
                required
                error={formData.name.error}
                helperText={formData.name.msgError}
                value={formData.name.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="E-mail do motorista"
                variant="outlined"
                fullWidth
                required
                error={formData.email.error}
                helperText={formData.email.msgError}
                value={formData.email.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    email: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
            <div className="form-group mt-3 row">
              <div className=" col-sm-12">
                <div className="row">
                  <div className="col-6">
                    <TextField
                      label="Número da CNH"
                      variant="outlined"
                      fullWidth
                      required
                      disabled={formData.cnhNumber.disabled}
                      error={formData.cnhNumber.error}
                      helperText={formData.cnhNumber.msgError}
                      value={formData.cnhNumber.value}
                      inputProps={{ maxLength: 9 }}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          cnhNumber: {
                            value: e.target.value,
                            error: false,
                            msgError: "",
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      label="Categoria da CNH"
                      variant="outlined"
                      fullWidth
                      required
                      select
                      error={formData.cnhType.error}
                      helperText={formData.cnhType.msgError}
                      value={formData.cnhType.value}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          cnhType: { value: e.target.value, error: false },
                        });
                      }}
                    >
                      {state.categories.map((item, id) => (
                        <MenuItem key={id} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="CPF"
                variant="outlined"
                fullWidth
                required
                disabled={formData.cpf.disabled}
                error={formData.cpf.error}
                helperText={formData.cpf.msgError}
                value={formData.cpf.value}
                inputProps={{ maxLength: 11 }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    cpf: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
            <div className="form-group-row mt-4">
              <TextField
                label="Descrição"
                variant="outlined"
                multiline
                rows={2}
                fullWidth
                error={formData.description.error}
                helperText={formData.description.msgError}
                value={formData.description.value}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    description: {
                      value: e.target.value,
                      error: false,
                      msgError: "",
                    },
                  });
                }}
              />
            </div>
            <hr />
            <div id="addInfo" className="col-12">
              <h4>
                <strong>Informações Adicionais</strong>
              </h4>
              <div id="situation" className="mt-4">
                <div className="form-group-row mt-4">
                  <TextField
                    className="form-control"
                    variant="outlined"
                    fullWidth
                    select
                    required
                    label="Transportadora"
                    value={formData.carrier.value}
                    error={formData.carrier.error}
                    helperText={formData.carrier.msgError}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        carrier: { value: e.target.value, error: false },
                      });
                    }}
                  >
                    {state.carriers.map((item, id) => (
                      <MenuItem key={id} value={item.id ?? " "}>
                        {item.company_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex align-items-center justify-content-between my-5">
                <Link to={"/pbo/dashboard/drivers"}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    color="error"
                    disabled={state.loadingSave}
                    startIcon={<Reply />}
                  >
                    <span>Voltar</span>
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                  disabled={state.loadingSave}
                  onClick={() => handleSubmit()}
                >
                  {state.loadingSave && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!state.loadingSave && <span>Salvar</span>}
                </Button>
              </div>
              <hr />
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default AddDriver;
