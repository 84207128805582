//* React imports
import React from "react";

//* Style imports
import { MenuItem, TextField } from "@mui/material";

//* Utils imports
import { NumericFormat } from "react-number-format";

function DestinationData({ formData, setFormData }) {
  return (
    <div>
      <div className="form-group-row mt-5">
        <TextField
          label="Razão Social"
          variant="outlined"
          fullWidth
          required
          error={formData.companyName.error}
          helperText={formData.companyName.msgError}
          value={formData.companyName.value}
          onChange={(e) => {
            setFormData({
              ...formData,
              companyName: { value: e.target.value, error: false },
            });
          }}
        />
      </div>
      <div className="form-group-row mt-5">
        <TextField
          label="Nome Fantasia"
          variant="outlined"
          fullWidth
          required
          error={formData.commercialName.error}
          helperText={formData.commercialName.msgError}
          value={formData.commercialName.value}
          onChange={(e) => {
            setFormData({
              ...formData,
              commercialName: { value: e.target.value, error: false },
            });
          }}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div className="form-group-row col-6 mt-5">
          <NumericFormat
            customInput={TextField}
            valueIsNumericString
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            decimalScale={2}
            decimalSeparator=","
            label="Capacidade Máxima (peso)"
            variant="outlined"
            fullWidth
            required
            error={formData.maxCapacity_peso.error}
            helperText={formData.maxCapacity_peso.msgError}
            value={formData.maxCapacity_peso.value}
            onValueChange={(values, e) => {
              setFormData({
                ...formData,
                maxCapacity_peso: { value: values.value, error: false },
              });
            }}
          />
        </div>
        <div className="form-group-row col-5 mt-5">
          <TextField
            label="Unidade (peso)"
            variant="outlined"
            fullWidth
            required
            select
            error={formData.unitPeso.error}
            helperText={formData.unitPeso.msgError}
            value={formData.unitPeso.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                unitPeso: { value: e.target.value, error: false },
              });
            }}
          >
            {formData.unitPesoData.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name} - {item.abbreviation}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="form-group-row col-6 mt-5">
          <NumericFormat
            customInput={TextField}
            valueIsNumericString
            thousandsGroupStyle="thousand"
            thousandSeparator="."
            decimalScale={2}
            decimalSeparator=","
            label="Capacidade Máxima (volume)"
            variant="outlined"
            fullWidth
            required
            error={formData.maxCapacity_volume.error}
            helperText={formData.maxCapacity_volume.msgError}
            value={formData.maxCapacity_volume.value}
            onValueChange={(values, e) => {
              setFormData({
                ...formData,
                maxCapacity_volume: { value: values.value, error: false },
              });
            }}
          />
        </div>
        <div className="form-group-row col-5 mt-5">
          <TextField
            label="Unidade (volume)"
            variant="outlined"
            fullWidth
            required
            select
            error={formData.unitVolume.error}
            helperText={formData.unitVolume.msgError}
            value={formData.unitVolume.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                unitVolume: { value: e.target.value, error: false },
              });
            }}
          >
            {formData.unitVolData.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name} - {item.abbreviation}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    </div>
  );
}

export default DestinationData;
