//* React Imports
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//* Style Imports
import { Button, MenuItem, TextField } from "@mui/material";

//* Utils Imports
import { ErrorAlert, SuccessAlert } from "utils/utilities";
import validator from "validator";
import { URL } from "variables";

function DriverModal(props) {
  const user = useSelector((store) => store.AppReducer.user);
  const params = useParams();
  let collect_id = params.id;

  const filteredCarrierArray = props.carriers.filter((obj) =>
    obj.hasOwnProperty("created_at")
  );

  const [state, setState] = useState({
    loadingSave: false,
    categories: ["A", "B", "C", "D", "E"],
  });

  const [formData, setFormData] = useState({
    name: { value: "", error: false, msgError: "" },
    email: { value: "", error: false, msgError: "" },
    cpf: { value: "", error: false, msgError: "" },
    cnh: { value: "", error: false, msgError: "" },
    cnhType: { value: "", error: false, msgError: "" },
    description: { value: "", error: false, msgError: "" },
    carrierId: { value: props.carrierId, error: false, msgError: "" },
  });

  function handleSubmit() {
    let variables = ["name", "cnh", "carrierId"];
    let data = { ...formData };
    variables.forEach((item) => {
      if (data[item].value == "") {
        data[item].error = true;
        data[item].msgError = "Campo obrigatório";
      }
      setFormData(data);
      return;
    });

    if (
      formData.email.value !== "" &&
      !validator.isEmail(formData.email.value)
    ) {
      setFormData({
        ...formData,
        email: {
          value: formData.email.value,
          error: true,
          msgError: "Formato incorreto",
        },
      });
      return;
    }

    if (formData.cnh.value.length < 9 && formData.cnh.value !== "") {
      setFormData({
        ...formData,
        cnh: {
          value: formData.cnh.value,
          error: true,
          msgError: "Formato incorreto",
        },
      });
      return;
    }

    if (formData.cpf.value.length !== 11 && formData.cpf.value !== "") {
      setFormData({
        ...formData,
        cpf: {
          value: formData.cpf.value,
          error: true,
          msgError: "Formato incorreto",
        },
      });
      return;
    }

    let token = localStorage.getItem("token");
    setState({ ...state, loadingSave: true });
    fetch(`${URL}api/quick_store_carrier_driver`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formData.name.value,
        email: formData.email.value,
        cpf: formData.cpf.value,
        cnh_number: formData.cnh.value,
        cnh_type: formData.cnhType.value,
        description: formData.description.value,

        carrier_id: formData.carrierId.value,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let error = resp.errors.error;
            setState({ ...state, loadingSave: false });
            if (error) {
              ErrorAlert(error);
            }
          } else {
            SuccessAlert("Motorista cadastrado com sucesso!");
            props.handleCarrier(formData.carrierId.value);
            props.close();
          }
        } catch (err) {
          console.log(err);
          setState({ ...state, loadingSave: false });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loadingSave: false });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <>
      <header>
        <p className="text-center">Crie rapidamente um veículo</p>
      </header>
      <main>
        <div className="form-group-row mt-4">
          <TextField
            label="Nome do motorista"
            variant="outlined"
            fullWidth
            required
            error={formData.name.error}
            helperText={formData.name.msgError}
            value={formData.name.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                name: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          />
        </div>
        <div className="form-group-row mt-4">
          <TextField
            label="E-mail do motorista"
            variant="outlined"
            fullWidth
            error={formData.email.error}
            helperText={formData.email.msgError}
            value={formData.email.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                email: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          />
        </div>
        <div className="form-group mt-3 row">
          <div className=" col-sm-12">
            <div className="row">
              <div className="col-6">
                <TextField
                  label="CNH"
                  variant="outlined"
                  fullWidth
                  required
                  disabled={formData.cnh.disabled}
                  error={formData.cnh.error}
                  helperText={formData.cnh.msgError}
                  value={formData.cnh.value}
                  inputProps={{ maxLength: 9 }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      cnh: {
                        value: e.target.value,
                        error: false,
                        msgError: "",
                      },
                    });
                  }}
                />
              </div>
              <div className="col-6">
                <TextField
                  label="Categoria"
                  variant="outlined"
                  fullWidth
                  select
                  error={formData.cnhType.error}
                  helperText={formData.cnhType.msgError}
                  value={formData.cnhType.value}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      cnhType: { value: e.target.value, error: false },
                    });
                  }}
                >
                  {state.categories.map((item, id) => (
                    <MenuItem key={id} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group-row mt-4">
          <TextField
            label="CPF"
            variant="outlined"
            fullWidth
            disabled={formData.cpf.disabled}
            error={formData.cpf.error}
            helperText={formData.cpf.msgError}
            value={formData.cpf.value}
            inputProps={{ maxLength: 11 }}
            onChange={(e) => {
              setFormData({
                ...formData,
                cpf: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          />
        </div>
        <div className="form-group-row mt-4">
          <TextField
            label="Descrição"
            variant="outlined"
            multiline
            rows={2}
            fullWidth
            error={formData.description.error}
            helperText={formData.description.msgError}
            value={formData.description.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                description: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          />
        </div>
        <hr className="my-3" />
        <div className="form-group-row">
          <TextField
            label="Transportadora"
            required
            className="form-control"
            variant="outlined"
            fullWidth
            select
            value={formData.carrierId.value}
            error={formData.carrierId.error}
            helperText={formData.carrierId.msgError}
            onChange={(e) => {
              setFormData({
                ...formData,
                carrierId: { value: e.target.value, error: false },
              });
            }}
          >
            {filteredCarrierArray.map((item, id) => (
              <MenuItem key={id} value={item.id}>
                {item.company_name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </main>
      <footer>
        <div className="mt-4">
          <Button
            variant="contained"
            disableElevation
            size="large"
            color="info"
            className="col-12"
            disabled={state.loadingSave}
            onClick={() => handleSubmit()}
          >
            {state.loadingSave && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {!state.loadingSave && <span>Salvar</span>}
          </Button>
        </div>
      </footer>
    </>
  );
}

export default DriverModal;
