//* React imports
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//* Style imports
import { Alert, IconButton, Pagination, Tooltip } from "@mui/material";

//* Utils imports
import { ErrorAlert, handleStatus } from "utils/utilities";
import { URL } from "variables";
import InfoModal from "components/common/InformativeModal/InfoModal";
import {
  CalendarTodayOutlined,
  CalendarViewDayOutlined,
} from "@mui/icons-material";
import { BsFillCircleFill } from "react-icons/bs";

function PendentCollectSolicitations() {
  const [state, setState] = useState({
    loading: true,
    page: 1,
    totalPages: 1,

    collect: [],
  });

  //props.modalData PATTERN for InfoModal.jsx
  const modalData = {
    label: "O que é uma solicitação de coleta?.",
    title: "Solicitação de Coleta",
    introText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl",
    section1: {
      title: "Lorem",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl. \nLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl.",
    },
    section2: { title: "", text: "" },
    section3: { title: "", text: "" },
    section4: { title: "", text: "" },
    confirmationMessage: "Entendi",
  };

  const reducer = useSelector((store) => store.AppReducer);

  const token = localStorage.getItem("token");

  useEffect(() => {
    getOpenSolicitations(1);
  }, [reducer.notifications]);

  function getOpenSolicitations(page) {
    fetch(
      `${URL}api/get_open_solicitations_pendent?page=${page}&enterprise_id=${reducer.user.enterprise_id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setState({ ...state, loading: false });
          } else {
            setState({
              ...state,
              loading: false,
              collect: resp.collect,
              page: resp.pagination.current_page,
              totalPages: resp.pagination.total_pages,
            });
          }
        } catch (err) {
          setState({ ...state, loading: true });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setState({ ...state, loading: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  const handleChangePage = (event, page) => {
    if (page != state.page) {
      setState({ ...state, page: page });
      getOpenSolicitations(page);
    }
  };

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div>
          <header>
            <h1 className="card-title text-body">
              <b>Solicitações de Coleta</b>
            </h1>
            <p className="text-body">
              Lista com todas as solicitações de coleta pendentes feitas pela
              empresa vinculada com seu destino.{" "}
              <InfoModal modalData={modalData} />
            </p>
          </header>
          <main className="mt-4">
            {state.collect.length > 0 && (
              <div className="table-responive text-center">
                <table className="table table-hover">
                  <thead className="thead-blueen">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Criação da Coleta</th>
                      <th scope="col">Peso</th>
                      <th scope="col">Volume</th>
                      <th scope="col">Status</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-blueen">
                    {state.collect.map((item, id) => (
                      <tr key={id}>
                        <td className="align-middle">
                          {!item.visualized && (
                            <BsFillCircleFill
                              size={10}
                              className="mx-2"
                              color="#f8333c"
                            />
                          )}
                        </td>
                        <td className="align-middle">
                          {item.created_at.replace(
                            /(\d*)-(\d*)-(\d*).*/,
                            "$3/$2/$1"
                          )}
                        </td>
                        <td className="align-middle">{item.total_weight} kg</td>
                        <td className="align-middle">{item.total_volume} L</td>
                        <td className="align-middle">
                          {item.status} {handleStatus(item.status)}
                        </td>
                        <td className="align-middle">
                          <Link
                            to={
                              "/pbo/dashboard/schedule-collect/view/" + item.id
                            }
                          >
                            <Tooltip title="Agendar">
                              <IconButton>
                                <CalendarTodayOutlined />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="d-flex justify-content-end mb-5">
                  <Pagination
                    count={state.totalPages}
                    page={state.page}
                    onChange={handleChangePage}
                    defaultPage={state.page}
                    color="primary"
                  />
                </div>
              </div>
            )}
            {state.collect.length == 0 && (
              <Alert severity="info">Nenhum registro encontrado...</Alert>
            )}
          </main>
        </div>
      )}
    </div>
  );
}

export default PendentCollectSolicitations;
