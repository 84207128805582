//* React imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

//* Style imports
import { Alert, Button, TextField } from "@mui/material";

//* Utils imports
import { ErrorAlert, SuccessAlert, handleStatus } from "utils/utilities";
import { maskMoneyV2, maskNumberBr } from "utils/masks";
import { URL } from "variables";

//* Components Imports
import InfoModal from "components/common/InformativeModal/InfoModal";
import { Reply } from "@mui/icons-material";
import { useSelector } from "react-redux";

function ScheduleCollect() {
  const [state, setState] = useState({
    loading: true,
    loadingSave: false,
    workToday: true,

    dailyHours: [],
    collectData: [],
    expedientData: [],
    numericWeekdays: [],

    //Editable
    appointmentDate: "",
    appointmentHours: "",
    invoiceKey: "",
    invoiceNumber: "",
    invoiceSeries: "",
  });

  //Setting variables to fetches
  const token = localStorage.getItem("token");
  const params = useParams();
  let solicitation_id = params.id;

  let history = useNavigate();

  //props.modalData PATTERN for InfoModal.jsx
  const modalData = {
    label: "Veja como funciona essa troca de dados.",
    title: "Agendar Coleta",
    introText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl",
    section1: {
      title: "Lorem",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl. \nLorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl.",
    },
    section2: { title: "", text: "" },
    section3: { title: "", text: "" },
    section4: { title: "", text: "" },
    confirmationMessage: "Entendi",
  };

  useEffect(() => {
    fetch(`${URL}api/get_open_solicitation/${solicitation_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (responseLog) => {
        const collect = await responseLog.json();
        //In order for the request to work, the 'url_back' parameter needs to be passed.
        //We obtain this data AFTER the first request, which is why there is a nested fetch.
        fetch(`${URL}api/auth/get_org_expedient/`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            url_back: collect.collect_solicitation.organization.url_back,
          }),
        })
          .then(async (responseLog) => {
            let resp = await responseLog.json();

            const day = collect.collect_solicitation?.appointment_date
              ? new Date(
                  collect.collect_solicitation.appointment_date
                ).getUTCDay()
              : null;
            const numericDays = resp.expedient.map((item) =>
              Number(item.numeric_day)
            );

            setState({
              ...state,
              loading: false,
              //* Expedient data
              expedientData: resp.expedient,
              numericWeekdays: numericDays,
              appointmentDate: collect.collect_solicitation?.appointment_date,
              appointmentHours: collect?.collect_solicitation
                ?.appointment_start_hour
                ? {
                    start_time:
                      collect?.collect_solicitation?.appointment_start_hour,
                    end_time:
                      collect?.collect_solicitation?.appointment_end_hour,
                  }
                : "",
              workToday: collect?.collect_solicitation?.appointment_date
                ? numericDays.includes(
                    new Date(
                      collect.collect_solicitation.appointment_date
                    ).getUTCDay()
                  )
                : true,
              dailyHours:
                day && !collect?.collect_solicitation?.appointment_start_hour
                  ? resp?.expedient?.filter(
                      (item) => Number(item.numeric_day) === day
                    )[0]?.opening_hour
                  : [],

              //* Collect data
              collectData: collect.collect_solicitation,
            });
          })
          .catch((err) => {
            console.log(err);
            ErrorAlert("Erro ao pegar resposta do servidor.");
          });
      })
      .catch((err) => {
        console.log(err);
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }, []);

  //With the working hours of Blueen company in consideration, this function maps the available days for scheduling the collect.
  function handleDate(value) {
    let day = new Date(value).getUTCDay();
    if (!state.numericWeekdays.includes(day)) {
      setState({
        ...state,
        appointmentDate: value,
        appointmentHours: "",
        dailyHours: [],
        workToday: false,
      });
    } else {
      state.expedientData.map((item, id) => {
        if (item.numeric_day == day) {
          setState({
            ...state,
            appointmentDate: value,
            appointmentHours: "",
            dailyHours: item.opening_hour,
            workToday: true,
          });
        }
      });
    }
    if (value === "") {
      setState({
        ...state,
        appointmentDate: "",
        appointmentHours: "",
        dailyHours: [],
        workToday: false,
      });
      return;
    }
  }

  const handleSituacao = () => {
    let status = "Rascunho";
    if (state.appointmentDate) status = "Agendado";
    return status;
  };

  const reducer = useSelector((store) => store.AppReducer);

  //This function schedules the collect solicitation
  function handleSubmit() {
    if (!state.appointmentDate) {
      ErrorAlert("Preencha os campos corretamente.");
      return;
    }

    setState({ ...state, loadingSave: true });

    fetch(`${URL}api/schedule_collect`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: reducer.user.id,
        collect_id: state.collectData.id,
        appointment_date: state.appointmentDate,
        appointment_start_hour: state.appointmentHours.start_time,
        appointment_end_hour: state.appointmentHours.end_time,
        situacao: handleSituacao(),
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            ErrorAlert(erro);
            setState({ ...state, loadingSave: false });
          } else {
            history("/pbo/dashboard/schedule-collect");
            SuccessAlert("Agendamento efetuado com sucesso");
          }
        } catch (err) {
          setState({ ...state, loadingSave: true });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        setState({ ...state, loadingSave: true });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <div className="mx-3">
      {state.loading && (
        <div
          style={{
            minHeight: "75vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loading" role="status"></div>
        </div>
      )}
      {!state.loading && (
        <div>
          <header>
            <h1 className="card-title text-body">
              <b>Agendar Coleta</b>
            </h1>
            <p className="text-body">
              Selecione um dia e horário para efetuar uma coleta.
              <InfoModal modalData={modalData} />
            </p>
          </header>
          <hr />
          <main className="mt-4">
            <div id="waste_rejects">
              <h4>
                <strong>Dados dos Resíduos e Rejeitos</strong>
              </h4>
              <div className="table-responive text-center mt-4">
                <table className="table table-hover">
                  <thead className="thead-blueen">
                    <tr>
                      <th scope="col">Material</th>
                      <th scope="col">Peso</th>
                      <th scope="col">Volume</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-blueen">
                    {state.collectData.waste_rejects.map((item, id) => (
                      <tr key={id}>
                        <td className="align-middle">
                          {item.waste_reject.name}
                        </td>
                        <td className="align-middle">
                          {maskNumberBr(item.weight_kg)} kg
                        </td>
                        <td className="align-middle">
                          {maskNumberBr(item.volume_lt)} L
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <hr className="my-4" />
            <div className="row">
              <div id="despacho" className="col-12 col-md-6">
                <h4>
                  <strong>Dados do Despacho</strong>
                </h4>
                <div id="situation" className="mt-4">
                  <p>
                    <strong>Status: </strong>
                    {state.collectData.status}
                    {handleStatus(state.collectData.status)}
                  </p>
                  <p>
                    <strong>Data de Criação da Solicitação de Coleta: </strong>
                    {state.collectData.created_at.replace(
                      /(\d*)-(\d*)-(\d*).*/,
                      "$3/$2/$1"
                    )}
                  </p>
                  <p>
                    <strong>Peso Total: </strong>
                    {state.collectData.total_weight} kg
                  </p>
                  <p>
                    <strong>Volume Total: </strong>
                    {state.collectData.total_volume} L
                  </p>
                  <p>
                    <strong>Receita: </strong>
                    {maskMoneyV2(state.collectData.income)}
                  </p>
                  <p>
                    <strong>Despesas: </strong>
                    {maskMoneyV2(state.collectData.expend)}
                  </p>
                  <p>
                    <strong>Saldo: </strong>
                    {maskMoneyV2(state.collectData.balance)}
                  </p>
                  {/* NOTA FISCAL */}
                  {/* <p>
                    <strong>Chave da NF-e: </strong>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      value={state.invoiceKey}
                      onChange={(e) =>
                        setState({ ...state, invoiceKey: e.target.value })
                      }
                    ></TextField>
                  </p>
                  <div className="d-flex justify-content-between row mb-3">
                    <div className="form-group-row col-sm-5 col-12">
                      <p>
                        <strong>Número da NF-e: </strong>
                      </p>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        value={state.invoiceNumber}
                        onChange={(e) => {
                          setState({ ...state, invoiceNumber: e.target.value });
                        }}
                      />
                    </div>
                    <div className="form-group-row col-sm-6 col-12">
                      <p>
                        <strong>Série da NF-e: </strong>
                      </p>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        value={state.invoiceSeries}
                        onChange={(e) => {
                          setState({ ...state, invoiceSeries: e.target.value });
                        }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <div id="expedient" className="col-12 col-md-6">
                <h4>
                  <strong>Horários de Funcionamento</strong>
                </h4>
                <p>
                  Tabela com os horários disponíveis para a retirada de
                  materiais.
                </p>
                <div className="my-3 col-12">
                  <ul className="list-group text-capitalize">
                    <li className="list-group-item bg-primary text-light">
                      <b>Dias & horários de funcionamento</b>
                    </li>
                    {state.expedientData.map((item, id) => (
                      <li
                        key={id}
                        className="list-group-item d-flex justify-content-between"
                      >
                        <p className="pt-3">
                          <b>{item.weekday}: </b>
                          {item.opening_hour.map((item2, id2) => (
                            <React.Fragment key={id2}>
                              {item2.start_time} - {item2.end_time}
                              {id2 < item.opening_hour.length - 1 && " | "}
                            </React.Fragment>
                          ))}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <strong>
                    Data de Agendamento<b className="text-danger">*</b>
                  </strong>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={state.appointmentDate}
                    onChange={(e) => handleDate(e.target.value)}
                  ></TextField>
                </div>
                {state.dailyHours?.length > 0 && (
                  <div className="form-group row">
                    <strong>
                      Horários Disponíveis<b className="text-danger">*</b>
                    </strong>

                    {state.dailyHours.map((item, id) => (
                      <div key={id} className="col-12 col-sm-6 mt-3">
                        <div className="card text-center">
                          <div className="card-body">
                            <h5 className="card-title">
                              {item.start_time} - {item.end_time}
                            </h5>
                            {state.appointmentHours.id != item.id && (
                              <button
                                onClick={() => {
                                  setState({
                                    ...state,
                                    appointmentHours: item,
                                  });
                                }}
                                className="btn bg-primary text-light"
                              >
                                Selecionar
                              </button>
                            )}
                            {state.appointmentHours.id == item.id && (
                              <button
                                className="btn bg-primary text-light"
                                disabled
                              >
                                Selecionado
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {!state.workToday && (
                  <Alert severity="info">
                    A empresa não funciona nesse dia da semana!
                  </Alert>
                )}
                {state.workToday && state.appointmentHours && (
                  <Alert severity="info">
                    Entre {state.appointmentHours.start_time} e{" "}
                    {state.appointmentHours.end_time}
                  </Alert>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-between my-5">
                <Link to={"/pbo/dashboard/schedule-collect"}>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    color="error"
                    disabled={state.loadingSave}
                    startIcon={<Reply />}
                  >
                    <span>Voltar</span>
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  color="primary"
                  disabled={state.loadingSave}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {state.loadingSave && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {!state.loadingSave && <span>Agendar</span>}
                </Button>
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default ScheduleCollect;
