//* React Imports
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//* Style Imports
import { Button, MenuItem, TextField } from "@mui/material";

//* Utils Imports
import { NumericFormat } from "react-number-format";
import { ErrorAlert, SuccessAlert } from "utils/utilities";
import { URL } from "variables";

function VehicleModal(props) {
  const user = useSelector((store) => store.AppReducer.user);
  const params = useParams();
  let collect_id = params.id;

  const filteredCarrierArray = props.carriers.filter((obj) =>
    obj.hasOwnProperty("created_at")
  );

  const [state, setState] = useState({
    loadingSave: false,
    unitPesoData: [],
    unitVolData: [],
    vehicleTypeData: [],
    vehicleModelData: [],
    avaliableModels: [],
    conservation: [
      "Perfeito estado",
      "Bom",
      "Semi-novo",
      "Problemático",
      "Inutilizável",
    ],
  });

  const [formData, setFormData] = useState({
    licensePlate: { value: "", error: false, msgError: "" },
    conservation: { value: "", error: false, msgError: "" },
    model: { value: "", error: false, msgError: "" },
    type: { value: "", error: false, msgError: "Ex: Caminhão, carro..." },
    anttRegister: { value: "", error: false, msgError: "" },
    description: { value: "", error: false, msgError: "" },
    maxCapacity_peso: { value: "", error: false, msgError: "" },
    maxCapacity_volume: { value: "", error: false, msgError: "" },
    unitPesoName: { value: "", error: false, msgError: "" },
    unitVolumeName: { value: "", error: false, msgError: "" },
    carrierId: { value: props.carrierId, error: false, msgError: "" },
  });

  const token = useSelector((store) => store.AppReducer.token);

  useEffect(() => {
    setState({ ...state, loading: true });

    Promise.all([
      fetch(`${URL}api/get_vehicles`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
      fetch(`${URL}api/get_units`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    ])
      .then(([resVehicles, resUnits]) =>
        Promise.all([resVehicles.json(), resUnits.json()])
      )
      .then(([vehicles, units]) => {
        setState({
          ...state,
          vehicleModelData: vehicles.models,
          vehicleTypeData: vehicles.types,
          unitPesoData: units.units_peso,
          unitVolData: units.units_volume,
        });
      });
  }, []);

  function handleVehicleType({ value }) {
    let models = [];
    for (let i = 0; i < state.vehicleModelData.length; i++) {
      if (state.vehicleModelData[i].vehicle_type_id == value) {
        models.push(state.vehicleModelData[i]);
      }
    }
    setState({ ...state, avaliableModels: models });
    setFormData({
      ...formData,
      type: { value: value, error: false, msgError: "" },
    });
  }

  function handleSubmit() {
    let variables = ["licensePlate", "type", "model", "carrierId"];
    let data = { ...formData };
    variables.forEach((item) => {
      if (data[item].value == "") {
        data[item].error = true;
        data[item].msgError = "Campo obrigatório";
      }
      setFormData(data);
      return;
    });

    if (
      formData.licensePlate.value.length !== 7 &&
      formData.licensePlate.value !== ""
    ) {
      setFormData({
        ...formData,
        licensePlate: {
          value: formData.licensePlate.value,
          error: true,
          msgError: "Formato incorreto",
        },
      });
      return;
    }

    let token = localStorage.getItem("token");
    setState({ ...state, loadingSave: true });
    fetch(`${URL}api/quick_store_carrier_vehicle`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        license_plate: formData.licensePlate.value,
        vehicle_model_id: formData.model.value,

        antt_register: formData.anttRegister.value,
        conservation: formData.conservation.value,
        description: formData.description.value,

        max_capacity_peso: formData.maxCapacity_peso.value,
        unit_peso_name: formData.unitPesoName.value,
        max_capacity_volume: formData.maxCapacity_volume.value,
        unit_volume_name: formData.unitVolumeName.value,

        carrier_id: formData.carrierId.value,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let error = resp.errors.error;
            setState({ ...state, loadingSave: false });
            if (error) {
              ErrorAlert(error);
            }
          } else {
            SuccessAlert("Veículo cadastrado com sucesso!");
            console.log("resp", resp);
            props.handleCarrier(formData.carrierId.value);
            props.close();
          }
        } catch (err) {
          console.log(err);
          setState({ ...state, loadingSave: false });
          ErrorAlert("Erro ao pegar resposta do servidor");
        }
      })
      .catch((err) => {
        console.log(err);
        setState({ ...state, loadingSave: false });
        ErrorAlert(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
      });
  }

  return (
    <>
      <header>
        <p className="text-center">Crie rapidamente um veículo</p>
      </header>
      <main>
        <div className="form-group-row mt-4">
          <TextField
            label="Placa do Veículo"
            variant="outlined"
            fullWidth
            required
            error={formData.licensePlate.error}
            helperText={formData.licensePlate.msgError}
            value={formData.licensePlate.value}
            inputProps={{ maxLength: 7 }}
            onChange={(e) => {
              setFormData({
                ...formData,
                licensePlate: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          />
        </div>
        <div className="form-group-row mt-4">
          <TextField
            label="Tipo do Veículo"
            variant="outlined"
            fullWidth
            select
            required
            error={formData.type.error}
            helperText={formData.type.msgError}
            value={formData.type.value}
            onChange={(e) => {
              handleVehicleType(e.target);
            }}
          >
            {state.vehicleTypeData.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.type}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="form-group-row mt-4">
          <TextField
            label="Modelo do Veículo"
            variant="outlined"
            fullWidth
            select
            required
            disabled={formData.type.value == "" || formData.type.error == true}
            error={formData.model.error}
            helperText={formData.model.msgError}
            value={formData.model.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                model: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          >
            {state.avaliableModels.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.brand} - {item.model}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="form-group-row mt-4">
          <TextField
            label="Registro ANTT"
            variant="outlined"
            fullWidth
            error={formData.anttRegister.error}
            helperText={formData.anttRegister.msgError}
            value={formData.anttRegister.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                anttRegister: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          />
        </div>
        <div className="form-group-row mt-4">
          <TextField
            label="Conservação"
            variant="outlined"
            fullWidth
            select
            error={formData.conservation.error}
            helperText={formData.conservation.msgError}
            value={formData.conservation.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                conservation: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          >
            {state.conservation.map((item, id) => (
              <MenuItem key={id} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="form-group-row mt-4">
          <TextField
            label="Descrição"
            variant="outlined"
            multiline
            rows={2}
            fullWidth
            error={formData.description.error}
            helperText={formData.description.msgError}
            value={formData.description.value}
            onChange={(e) => {
              setFormData({
                ...formData,
                description: {
                  value: e.target.value,
                  error: false,
                  msgError: "",
                },
              });
            }}
          />
        </div>
        <hr />
        <div className="form-group mt-3 row">
          <div className=" col-sm-12">
            <div className="row">
              <div className="col-8">
                <NumericFormat
                  customInput={TextField}
                  valueIsNumericString
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                  decimalScale={2}
                  decimalSeparator=","
                  label="Capacidade Máxima (peso)"
                  variant="outlined"
                  fullWidth
                  error={formData.maxCapacity_peso.error}
                  helperText={formData.maxCapacity_peso.msgError}
                  value={formData.maxCapacity_peso.value}
                  onValueChange={(values, e) => {
                    setFormData({
                      ...formData,
                      maxCapacity_peso: {
                        value: values.value,
                        error: false,
                        msgError: "",
                      },
                    });
                  }}
                />
              </div>
              <div className="col-4">
                <TextField
                  label="Unidade (peso)"
                  variant="outlined"
                  fullWidth
                  select
                  error={formData.unitPesoName.error}
                  helperText={formData.unitPesoName.msgError}
                  value={formData.unitPesoName.value}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      unitPesoName: {
                        value: e.target.value,
                        error: false,
                        msgError: "",
                      },
                    });
                  }}
                >
                  {state.unitPesoData.map((item, id) => (
                    <MenuItem key={id} value={item.id}>
                      {item.name} - {item.abbreviation}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group mt-3 row">
          <div className=" col-sm-12">
            <div className="row">
              <div className="col-8">
                <NumericFormat
                  customInput={TextField}
                  valueIsNumericString
                  thousandsGroupStyle="thousand"
                  thousandSeparator="."
                  decimalScale={2}
                  decimalSeparator=","
                  label="Capacidade Máxima (volume)"
                  variant="outlined"
                  fullWidth
                  error={formData.maxCapacity_volume.error}
                  helperText={formData.maxCapacity_volume.msgError}
                  value={formData.maxCapacity_volume.value}
                  onValueChange={(values, e) => {
                    setFormData({
                      ...formData,
                      maxCapacity_volume: {
                        value: values.value,
                        error: false,
                        msgError: "",
                      },
                    });
                  }}
                />
              </div>
              <div className="col-4">
                <TextField
                  label="Unidade (volume)"
                  variant="outlined"
                  fullWidth
                  select
                  error={formData.unitVolumeName.error}
                  helperText={formData.unitVolumeName.msgError}
                  value={formData.unitVolumeName.value}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      unitVolumeName: {
                        value: e.target.value,
                        error: false,
                        msgError: "",
                      },
                    });
                  }}
                >
                  {state.unitVolData.map((item, id) => (
                    <MenuItem key={id} value={item.id}>
                      {item.name} - {item.abbreviation}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            <hr className="my-3" />
          </div>
          <div className="form-group-row">
            <TextField
              label="Transportadora"
              required
              className="form-control"
              variant="outlined"
              fullWidth
              select
              value={formData.carrierId.value}
              error={formData.carrierId.error}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  carrierId: { value: e.target.value, error: false },
                });
              }}
            >
              {filteredCarrierArray.map((item, id) => (
                <MenuItem key={id} value={item.id}>
                  {item.company_name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </main>
      <footer>
        <div className="mt-4">
          <Button
            variant="contained"
            disableElevation
            size="large"
            color="info"
            className="col-12"
            disabled={state.loadingSave}
            onClick={() => handleSubmit()}
          >
            {state.loadingSave && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {!state.loadingSave && <span>Salvar</span>}
          </Button>
        </div>
      </footer>
    </>
  );
}

export default VehicleModal;
