import { BsCircleFill } from "react-icons/bs";
import Swal from "sweetalert2";

export const SuccessAlert = (data) => {
  return Swal.fire({
    toast: true,
    position: "top",
    timerProgressBar: true,
    timer: 3000,
    showConfirmButton: false,
    icon: "success",
    iconColor: "#3C0096",
    title: data,
  });
};

export const ErrorAlert = (data) => {
  return Swal.fire({
    toast: true,
    position: "top",
    timerProgressBar: true,
    timer: 3000,
    showConfirmButton: false,
    icon: "error",
    iconColor: "#F8333C",
    title: data,
  });
};

export const WarningAlert = (data) => {
  return Swal.fire({
    toast: true,
    position: "top",
    timerProgressBar: true,
    timer: 3000,
    showConfirmButton: false,
    icon: "warning",
    iconColor: "#F9C8DE",
    title: data,
  });
};

export const handleStatus = (status) => {
  if (status == "Pendente") {
    return <BsCircleFill className="mx-1" color="#ffc107" size={8} />;
  }
  if (status == "Aceito") {
    return <BsCircleFill className="mx-1" color="#00C2B3" size={8} />;
  }
  if (status == "Recebido") {
    return <BsCircleFill className="mx-1" color="#3C0096" size={8} />;
  }
  if (status == "Finalizado") {
    return <BsCircleFill className="mx-1" color="#73B054" size={8} />;
  } else {
    return <BsCircleFill className="mx-1" color="#f7f7ff" size={8} />;
  }
};
