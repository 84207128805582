//* Libraries imports
import { combineReducers, configureStore } from "@reduxjs/toolkit";

//* Reducers imports
import user from "./user";
import ui from "./ui";

const rootReducer = combineReducers({
  user,
  ui,
});

export const store = configureStore({ reducer: rootReducer });
